
export const CURRENT_VERSION = '1.10';

export const TOAST_TYPES = {
    DANGER: 'danger',
    WARN: 'warning',
    SUCCESS: 'success',
    INFO: 'info'
};

export const LOCAL_STORAGE_KEYS = {
    auth_token: 'dq_seal_bo_auth_token',
    auth_user: 'dq_seal_bo_auth_user',
    refresh_token: 'dq_seal_bo_refresh_token'
}

export const USER_TYPE = {
    REGULAR: 10,
    SUPER_USER: 1,
    ADMIN_PORTAL: 2,
    ORG_PORTAL: 3
};


export const USER_ACCESS_LEVEL = {
    NOT_APPLICABLE: 0,
    NO_ACCESS: 1,
    PARTIAL_ACCESS_ADMIN_PORTAL: 2,
    FULL_ACCESS_ADMIN_PORTAL: 3,
    FULL_ACCESS_ANALYTICS_PORTAL: 4,
    PARTIAL_ACCESS_ANALYTICS_PORTAL: 5,
};

export const USER_TYPE_VERBOSE = {
    10: 'REGULAR',
    1: 'SUPER_USER',
    2: 'ADMIN_PORTAL',
    3: 'ORG_PORTAL'
};

export const USER_ACCESS_LEVEL_VERBOSE = {
    0: 'N/A',
    1: 'No Access',
    2: 'Partial Access (Admin)',
    3: 'Full Access (Admin)',
    4: 'Full Access (Analytics - Full Org)',
    5: 'Partial Access (Analytics)',
    6: 'Full Access (Analytics - Single Inst)'
};

export const ACL_ENABLE_ENDPOINTS = [

]
export const URL_PERMS_MAP = {

}

export const SEAL_TYPE = {
    ALIGN: 0,
    ENHANCE: 1,
    CERTIFY: 2
}

export const SEAL_TYPE_VERBOSE = {
    [SEAL_TYPE.ALIGN]: 'Aligned',
    [SEAL_TYPE.ENHANCE]: 'Enhanced',
    [SEAL_TYPE.CERTIFY]: 'Measured'
}

export const APP_STATUS = {
    DRAFT: 0,
    WITHDRAWN: 1,
    REJECTED: 2,
    PENDING_PAYMENT: 10,
    PENDING_APPROVAL: 20,
    IN_PROGRESS_COLLECT_DATA: 30,
    HALTED_COLLECT_DATA: 40,
    COLLECTED_DATA: 45,
    IN_PROGRESS_PROCESS_DATA: 50,
    HALTED_PROCESS_DATA: 60,
    PENDING_CLEANING: 65,
    IN_PROGRESS_LEVEL_MAPPING: 67,
    PENDING_REVIEW_MAPPING: 70,
    PENDING_ANALYSIS: 75,
    PENDING_REVIEW_REPORT: 80,
    HALTED_REPORT: 90,
    COMPLETED: 100,

    READY_TO_CERTIFY: 105,
    ADD_ASSESS_REQ_RECEIVED: 110,
    REQ_REVIEWED_ON_HOLD: 115,
    REQ_REVIEWED_REJECTED: 116,
    REQ_REVIEWED_PAYMENT_PENDING: 120,
    PAYMENT_RECEIVED_VER_PENDING: 130, // assessments & licenses cost.
    PAYMENT_DECLINE_ON_HOLD: 135,
    PAYMENT_DECLINE_REJECTED: 136,
    PAYMENT_CONFIRMED: 138,
    PENDING_AUTO_ASSESS_GENERATION: 140,
    AUTO_ASSESS_GENERATION_IN_PROGRESS: 145,
    AUTO_ASSESS_GENERATION_HALTED: 150,
    AUTO_ASSESS_GENERATION_COMPLETED: 160, // in-progress review assessments
    PENDING_ASSESS_GENERATED_APPROVE: 162,
    PENDING_APPROVE_MODULES: 165,
    PENDING_PUBLISH_MODULES: 167,
    ASSESSMENTS_READY: 200,
}

export const APP_STATUS_VERBOSE = {
    [APP_STATUS.DRAFT]: 'Application to be Reviewed',
    [APP_STATUS.WITHDRAWN]: 'Withdrawn',
    [APP_STATUS.REJECTED]: 'Rejected',
    [APP_STATUS.PENDING_PAYMENT]: 'Pending Payment',
    [APP_STATUS.PENDING_APPROVAL]: 'Payment to be Verified',
    [APP_STATUS.IN_PROGRESS_COLLECT_DATA]: 'Collecting Content',
    [APP_STATUS.HALTED_COLLECT_DATA]: 'Halted (Collecting data)',
    [APP_STATUS.COLLECTED_DATA]: 'Content to be Cleaned',
    [APP_STATUS.IN_PROGRESS_PROCESS_DATA]: 'Content to be Cleaned',
    [APP_STATUS.HALTED_PROCESS_DATA]: 'Halted (Processing data)',
    [APP_STATUS.PENDING_CLEANING]: 'Content to be Cleaned',
    [APP_STATUS.IN_PROGRESS_LEVEL_MAPPING]: 'Prediction in progress',
    [APP_STATUS.PENDING_REVIEW_MAPPING]: 'Content to be Coded',
    [APP_STATUS.PENDING_ANALYSIS]: 'Content to be Analyzed',
    [APP_STATUS.PENDING_REVIEW_REPORT]: 'Report to be Reviewed',
    [APP_STATUS.HALTED_REPORT]: 'Halted (Report)',
    [APP_STATUS.COMPLETED]: 'Alignment Completed',

    [APP_STATUS.READY_TO_CERTIFY]: 'Pending Certification Initiation',
    [APP_STATUS.ADD_ASSESS_REQ_RECEIVED]: 'Assessment to be Reviewed',
    [APP_STATUS.REQ_REVIEWED_ON_HOLD]: 'Assessment Req Halted',
    [APP_STATUS.REQ_REVIEWED_REJECTED]: 'Assessment Req Halted',
    [APP_STATUS.REQ_REVIEWED_PAYMENT_PENDING]: 'Assessment Req Payment Pending',
    [APP_STATUS.PAYMENT_RECEIVED_VER_PENDING]: 'Impact Measure:, Payment Verification Pending',
    [APP_STATUS.PAYMENT_DECLINE_ON_HOLD]: 'Impact Measure:, Payment halted',
    [APP_STATUS.PAYMENT_DECLINE_REJECTED]: 'Impact Measure:, Payment halted',
    [APP_STATUS.PAYMENT_CONFIRMED]: 'Impact Measure:, Payment Confirmed',
    [APP_STATUS.PENDING_AUTO_ASSESS_GENERATION]: 'Assessment to be Reviewed',
    [APP_STATUS.AUTO_ASSESS_GENERATION_IN_PROGRESS]: 'Assessment to be Reviewed',
    [APP_STATUS.AUTO_ASSESS_GENERATION_HALTED]: 'Auto Assess Generate, Halted',
    [APP_STATUS.AUTO_ASSESS_GENERATION_COMPLETED]: 'Assessment to be Reviewed',
    [APP_STATUS.PENDING_ASSESS_GENERATED_APPROVE]: 'Assessment to be Reviewed',
    [APP_STATUS.PENDING_APPROVE_MODULES]: 'Assessment to be Reviewed',
    [APP_STATUS.PENDING_PUBLISH_MODULES]: 'Assessment to be Reviewed',
    [APP_STATUS.ASSESSMENTS_READY]: 'Assessments Ready',
}

export const LM_TYPE = {
    LESSON: 1,
    INSTRUMENT: 2,
    OBJECTIVE: 3,
    HEADING: 7,
    TITLE: 8,
    SUBTITLE: 9
}

export const ANALYSIS_TYPES = {
    LEVEL_9_COVERAGE: 'Level_9_Coverage',
    LEVEL_8_COVERAGE: 'Level_8_Coverage',
    LEVEL_7_COVERAGE: 'Level_7_Coverage',
    LEVEL_7_ALIGNMENT: 'Level_7_Alignment',
    LEVEL_6_DEPTH: 'Level_6_Depth',
    LEVEL_6_BREADTH: 'Level_6_Breadth',
    LEVEL_6_ENOUGH: 'Level_6_Enough',
    LEVEL_5_ANALYSIS: 'Level_5_Analysis',
}

export const ANALYSIS_TYPES_VERBOSE = {
    LEVEL_9_COVERAGE: 'Level 9 Coverage',
    LEVEL_8_COVERAGE: 'All Level 9 Coverage',
    LEVEL_7_COVERAGE: 'Level 8 Coverage',
    LEVEL_7_ALIGNMENT: 'Level 8 Alignment',
    LEVEL_6_DEPTH: 'Level 6 Depth',
    LEVEL_6_BREADTH: 'Level 6 Breadth',
    LEVEL_6_ENOUGH: 'Level 6 Enough',
    LEVEL_5_ANALYSIS: 'Overall Analysis',
}

// export const DQ_DEFINITIONS = {
//     "DQ1": {
//         title: "Digital Citizen Identity",
//         definition: "Building and managing a healthy identity as a digital citizen with integrity.",
//         color: "#5ABAE4"
//     },
//     "DQ2": {
//         title: "Balanced Use of Technology",
//         definition: "Managing one’s life both online and offline in a balanced way by exercising self-control to regulate screen time, multitasking, and one’s engagement with digital media and devices.",
//         color: "#809BD0"
//     },
//     "DQ3": {
//         title: "Conduct Cyber-Risk Management",
//         definition: "Identifying, mitigating, and managing cyber-risks (e.g., cyberbullying, harassment, and stalking) that relate to personal online behaviors.",
//         color: "#DF67A0"
//     },
//     "DQ4": {
//         title: "Personal Cyber Security Management",
//         definition: "Detecting cyber threats (e.g., hacking, scams, and malware) against personal data and device, and using suitable security strategies and protection tools.",
//         color: "#D70F76"
//     },
//     "DQ5": {
//         title: "Digital Empathy",
//         definition: "Being aware of, being sensitive to, and being supportive of one’s own and other’s feelings, needs and concerns online.",
//         color: "#E58744"
//     },
//     "DQ6": {
//         title: "Digital Footprint Management",
//         definition: "Understanding the nature of digital footprints and their real-life consequences, managing them responsibly, and actively building a positive digital reputation.",
//         color: "#F3C64C"
//     },
//     "DQ7": {
//         title: "Media and Information Literacy",
//         definition: "Finding, organizing, analyzing, and evaluating media and information with critical reasoning.",
//         color: "#D2DC1B"
//     },
//     "DQ8": {
//         title: "Privacy Management",
//         definition: "Handling with discretion all personal information shared online to protect one’s and others’ privacy.",
//         color: "#E5DC00"
//     },
//     "DQ9": {
//         title: "Digital Co-Creator Identity",
//         definition: "Identifying and developing oneself as a co-creator of the digital ecosystem.",
//         color: "#9ED3E9"
//     },
//     "DQ10": {
//         title: "Healthy Use of Technology",
//         definition: "Understanding the benefits and harms of technology on one’s mental and physical health and managing technology use while prioritizing health and wellbeing.",
//         color: "#8EA4D5"
//     },
//     "DQ11": {
//         title: "Contact Cyber-Risk Management",
//         definition: "Identifying, mitigating, and managing risky contact online (e.g., unwanted sexual contact, offline meetings, sexual exploitation).",
//         color: "#E491BA"
//     },
//     "DQ12": {
//         title: "Network Security Management",
//         definition: "Detecting, avoiding, and managing cyber threats to cloud-based collaborative digital environments.",
//         color: "#DE2A92"
//     },
//     "DQ13": {
//         title: "Emotional Regulation and Relationship Management",
//         definition: "Recognizing and managing how one’s value system and digital competencies fits with one’s digital life. Skillfully managing one’s online relationships through cooperation, conflict management, and persuasion",
//         color: "#EA9E65"
//     },
//     "DQ14": {
//         title: "Online Collaboration",
//         definition: "The ability to establish clear and effective modes of communication that would allow expression through technologies to collaborate collectively and to achieve intended goals.",
//         color: "#F5CF7A"
//     },
//     "DQ15": {
//         title: "Computational, Data, and AI Literacy",
//         definition: "Synthesizing, creating, and producing information, media, and technology in an innovative and creative manner. Generating, processing, analyzing, presenting meaningful information from data and developing, using, and applying artificial intelligence (AI) and related algorithmic tools and strategies in order to guide informed, optimized, and contextually relevant decision-making processes.",
//         color: "#DEE45D"
//     },
//     "DQ16": {
//         title: "Intellectual Property Rights Management",
//         definition: "Understanding and managing intellectual property rights (e.g., copyrights, trademarks, and patents) when using and creating content and technology.",
//         color: "#EDE300"
//     },
//     "DQ17": {
//         title: "Digital Changemaker Identity",
//         definition: "Identifying and developing oneself as a competent and influential changemaker in the digital economy.",
//         color: "#B8E7F6"
//     },
//     "DQ18": {
//         title: "Commercial and Community Use of Technology",
//         definition: "Engaging in commercial, civic and/or political participation for the wellbeing and growth of local, national, and global communities using technology.",
//         color: "#ACBFE5"
//     },
//     "DQ19": {
//         title: "Contract Cyber-Risk Management",
//         definition: "Identifying, mitigating, and managing contractual, commercial or community cyber-risks online, such as organizational attempts to exploit individuals financially or through ideological persuasion (e.g., embedded marketing, online propaganda, and gambling).",
//         color: "#F8B0D8"
//     },
//     "DQ20": {
//         title: "Organisational Cyber Security Management",
//         definition: "Recognizing, planning, and implementing organizational cyber security defenses.",
//         color: "#E83EA2"
//     },
//     "DQ21": {
//         title: "Digital Leadership",
//         definition: "Identifying and realizing opportunities for growth and value through effective, efficient, and acceptable use of digital technologies.",
//         color: "#F5BB8E"
//     },
//     "DQ22": {
//         title: "Public and Mass Communication",
//         definition: "Communicating with an online audience effectively to exchange messages, ideas, and opinions reflecting wider business or societal discourses.",
//         color: "#FADBA2"
//     },
//     "DQ23": {
//         title: "Emerging Technology and Innovation Literacy",
//         definition: "The ability to identify, use, and create emerging technology and opportunities to acquire innovative competencies to improve professional life and contribute to the global economy.",
//         color: "#E0DE84"
//     },
//     "DQ24": {
//         title: "Participatory Rights Management",
//         definition: "Understanding and exercising one’s powers and right to online participation (e.g., their rights to personal data protection, freedom of expression, or to be forgotten), while respecting the rights of other online users.",
//         color: "#F5E34B"
//     },
//     "DQ25": {
//         title: "Digital User Identity",
//         definition: "The ability to have their identity as a digital users - having basic understanding on internet and digital media.",
//         color: "#5ABAE4"
//     },
//     "DQ26": {
//         title: "Active Use of Technology",
//         definition: "The ability to actively use various types of digital media and technology.",
//         color: "#718CC8"
//     },
//     "DQ27": {
//         title: "Content Cyber-Risk Management",
//         definition: "Identifying, mitigating, and managing risky content (e.g., harmful user-generated content, racist/hateful content, image-based abuse).",
//         color: "#D9448E"
//     },
//     "DQ28": {
//         title: "Personal Device Security Management",
//         definition: "Ability to use strategies efficiently to protect personal digital devices.",
//         color: "#CB0F64"
//     },
//     "DQ29": {
//         title: "Emotional Awareness",
//         definition: "The ability to critically recognise, evaluate, and express one’s emotions in digital environment.",
//         color: "#DB6025"
//     },
//     "DQ30": {
//         title: "Online Communication",
//         definition: "The ability to use technology effectively to communicate with others online.",
//         color: "#EFB33C"
//     },
//     "DQ31": {
//         title: "ICT Literacy",
//         definition: "The ability to use efficiently the elementary functions of information and communication technologies (essentially word/image/data processing, Internet and e-mail).",
//         color: "#C0D500"
//     },
//     "DQ32": {
//         title: "Digital Inclusion Rights",
//         definition: "The ability to understand their rights to have quality digital access across sectors, SES, and genders.",
//         color: "#CEC300"
//     },
// };

export const ASSESSMENT_REQ_TYPES = {
    PRE_SELECT_PRE_POST: 1,
    PRE_SELECT_POST_ONLY: 2,
    STANDARD_DCT: 3,
    STANDARD_DPT: 4,
    CUSTOM_PRE_POST: 5,
    CUSTOM_POST_ONLY: 6,
}

export const ASSESSMENT_REQ_TYPES_VERBOSE = {
    [ASSESSMENT_REQ_TYPES.PRE_SELECT_PRE_POST]: 'DQ-Selected Pre and Post Assessment',
    [ASSESSMENT_REQ_TYPES.PRE_SELECT_POST_ONLY]: 'DQ-Selected Post Assessment Only',
    [ASSESSMENT_REQ_TYPES.STANDARD_DCT]: 'Digital Citizenship Test',
    [ASSESSMENT_REQ_TYPES.STANDARD_DPT]: 'Digital Parenting Test',
    [ASSESSMENT_REQ_TYPES.CUSTOM_PRE_POST]: 'Custom Pre and Post Assessment',
    [ASSESSMENT_REQ_TYPES.PRE_SELECT_POST_ONLY]: 'Custom Post Assessment Only'
}