import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {SEAL_TYPE_VERBOSE, APP_STATUS, APP_STATUS_VERBOSE} from "../../../utils/constants";
import performAnalysis from '../utils/performAnalysis.util';



const AnalysisTableRow = ({ row, selectedRows, onSelect, viewAnalysis }) => {
    const [overallAccuracy, setOverallAccuracy] = useState(null);
    const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const isSelected = selectedRows.some((selectedRow) => selectedRow.application_id === row.application_id);

    useEffect(() => {
      const fetchData = async () => {
        const {
          all_msgs_data,
          overall_accuracy,
          codes_modified,
          correct_codes,
          message_removed,
          total_messages,
          new_messages,
        } = await performAnalysis(row.application_id);
  
        setOverallAccuracy(overall_accuracy);
      };
  
      fetchData();
    }, [row.application_id]);
    
    const handleCheckboxChange = (event) => {
      event.stopPropagation();
      onSelect(row);
    };


    return (
      <TableRow selected={isSelected}>
        <TableCell>
          <Checkbox
            checked={isSelected}
            onChange={handleCheckboxChange} 
            color='primary'
          />
        </TableCell>
        <TableCell>
          <Typography>{'O: '}{row.owner_id.slice(-16)}</Typography>
          <Typography>{'A: '}{row.application_id.slice(-16)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{row.program_info ? row.program_info.program_name : "-"}</Typography>
          <Typography>{`(${row.company_info ? row.company_info.name_org : "-"})`}</Typography>
        </TableCell>
        <TableCell>{(new Date(row.submission_time)).toLocaleDateString(undefined, date_options)}</TableCell>
        <TableCell>{SEAL_TYPE_VERBOSE[row.dqseal_type]}</TableCell>
        <TableCell>{APP_STATUS_VERBOSE[row.app_status]}</TableCell>
        <TableCell>{overallAccuracy ? ''+(overallAccuracy * 100).toFixed(2) + "%": '-'}</TableCell>
        <TableCell>
          <Button variant="contained" onClick={(e) => viewAnalysis(e, row)}>View</Button>
        </TableCell>
      </TableRow>
    );
  };

  export default AnalysisTableRow;