import { createTheme }  from '@material-ui/core/styles'
const theme = createTheme({
    palette: {
        primary: {
            main: '#233043',
        },
        secondary: {
            main: '#A3A4A5',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#ff9800',
        },
        info: {
            main: '#2196f3',
        },
        success: {
            main: '#4caf50',
        },
        // Custom additional colors
        // You can adjust these to your preference
        text: {
            primary: '#233043',
            secondary: '#616161',
        },
        background: {
            default: '#ffffff',
            paper: '#f5f5f5',
        },
    },
})
export default theme