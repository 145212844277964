import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";

import {Copyright} from '../../../components';

import { useFormik } from 'formik';

import {loginUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '82vh',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    footer: {
        marginTop: 'auto',
        textAlign: 'center',
        width:'full-width',
    },
}));

const Login = (props) => {
    // const [isLoggedIn, setLoggedIn] = useState(false);
    const classes = useStyles();

    const { setAuthToken, setAuthUser } = useAuth();
    const referer = props.location.referer || '/home';
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember_me: false
        },
        onSubmit: async (values) => {
            console.log('Form data => ', values);
            try {
                setLoading(true);
                const result = await loginUser(values.email, values.password, values.remember_me);
                console.log('result.data -> ', result.data);
                if (result.data && result.data.user) {
                    await setAuthToken(result.data.auth_token);
                    await setAuthUser(result.data.user);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_token, JSON.stringify(result.data.refresh_token));
                    return <Redirect to={referer} />;
                    // setLoggedIn(true);
                }
                setLoading(false);
            } catch (e) {
                // already toasted the error
                setLoading(false);
            }
        }
    });

    // if (isLoggedIn) {
    //     return <Redirect to={referer} />;
    // }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in (Back-Office)
                </Typography>
                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            name="remember_me"
                            id="remember_me"
                            value={formik.values.remember_me}
                            onChange={formik.handleChange}
                            color="primary"
                        />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/account/recover" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/register" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <footer className={classes.footer}>
                <Box pt={4}>
                    <Copyright/>
                </Box>
            </footer>
            <LoaderWithBackDrop loading={loading}/>
        </Container>
    );
}

export default withRouter(Login);