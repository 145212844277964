import {store} from "react-notifications-component";
import {LOCAL_STORAGE_KEYS, TOAST_TYPES} from "./constants";

export const toast = (type, title, message, duration = 10000) => {
    store.addNotification({
        title,
        message,
        type,
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration,
            showIcon: true
        }
    });
}

export const toastWarning = (title, message, duration = 10000) => {
    toast(TOAST_TYPES.WARN, title, message, duration);
}

export const toastInfo = (title, message, duration = 10000) => {
    toast(TOAST_TYPES.INFO, title, message, duration);
}

export const toastError = (title, message, duration = 10000) => {
    toast(TOAST_TYPES.DANGER, title, message, duration);
}

export const toastSuccess = (title, message, duration = 10000) => {
    toast(TOAST_TYPES.SUCCESS, title, message, duration);
}

export const getMapFromObjectListByKey = (objectList, mapKey) => {
    let dict = {};
    for (let item of objectList) {
        dict[item[mapKey]] = item;
    }
    return dict;
}

export const getArrayFromMap = (map) => {
    return Object.keys(map).map((k) => map[k])

}

export const getPresenceMap = (simple_list) => {
    let dict = {};
    for (let item of simple_list) {
        dict[item] = true;
    }
    return dict;
}

export const asciiSortCompareFunWithKey = (key) => {
    return (a, b) => a[key].localeCompare(b[key]);
}

export function* chunks(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
    }
}

export function* varChunks(arr, n) { //variable length chunks
    const n_array = [].concat(n);

    function getNextSize(array, i) {
        return array[i] ? array[i] : array[array.length - 1];
    }

    for (let i = 0, j = 0; i < arr.length; i += getNextSize(n_array, j), j += 1)
    {
        yield arr.slice(i, i + getNextSize(n_array, j));
    }
}

export const splitEveryNthPos = function (string, separator, position) {
    const split_array = string.split(separator)
    const split_array_len = split_array.length;
    const final_array = [];
    let buffer = "";
    for(let word_index in split_array){
        const word = split_array[word_index]
        buffer += (word);
        if(buffer.length >= position || parseInt(word_index) === split_array_len-1){ //if buffer has max length possible, or we have reached last index
            final_array.push(buffer)
            buffer = ""
        }
        else {
            buffer += separator
        }
    }
    return final_array;
}

function _localeCompareWithNum(a, b, numeric) {
    return (a).toString().localeCompare((b).toString(), undefined, {
        numeric: numeric,
        sensitivity: 'base'
    });
}

export function alphaNumericSort(key = undefined, numeric = false, reverse = false) //numeric makes numbers sort first than alphabets
{
    if(key)
    {
        return (a, b) => {
            const firstComparator = (reverse ? b[key] : a[key]) || "";
            const secondComparator = (reverse ? a[key] : b[key]) || "";
            return _localeCompareWithNum(firstComparator, secondComparator, numeric);
        }
    }
    else
    {
        return (a, b) => {
            const firstComparator = (reverse ? b : a) || "";
            const secondComparator = (reverse ? a : b) || "";
            return _localeCompareWithNum(firstComparator, secondComparator, numeric);
        }
    }
}

export function removeSaltAtPos(saltedBase64String, interval = 5) {
    let cleanedString = '';
    let charsBeforeSalt = interval - 1; // Match the adjustment in addSalt

    for (let i = 0, count = 0, n = saltedBase64String.length; i < n; i++) {
        count++;

        // Add every character to the cleaned string except those at the salt positions
        if (count !== interval) {
            cleanedString += saltedBase64String[i];
        } else {
            count = 0; // Reset count after skipping a salt character
        }
    }

    return cleanedString;
}

export function save_e2ee_keys(result) {
    const e2ee_keypair_object = {pub: result.data.e2ee_pub_key, pvt: result.data.e2ee_pvt_key};
    const e2ee_obj_str = JSON.stringify(e2ee_keypair_object);
    localStorage.setItem(LOCAL_STORAGE_KEYS.e2ee_krypto_pair_obj, btoa(e2ee_obj_str));
}

export function use_e2ee() {
    return process.env.REACT_APP_E2EE_ENABLE === '1';
}