import React, {useState} from "react";
import {Chip} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

function EditableChips(props) {
    const {chips, addChipTitle, addChipHandler, editChipHandler, removeChipHandler} = props;

    const [editChipIndex, setEditChipIndex] = useState(undefined);
    const [editChipText, setEditChipText] = useState("");

    const [newChipFocus, setNewChipFocus] = useState(false);
    const [newChipText, setNewChipText] = useState("");

    function triggerChipEdit(index) {
        setEditChipIndex(index);
        setEditChipText(chips[index]);
    }

    function disableChipEdit() {
        setEditChipIndex(undefined);
        setEditChipText("");
    }

    function removeChip(index) {
        if(removeChipHandler) {
            removeChipHandler(index);
        }
    }

    function addChip() {
        if(addChipHandler) {
            addChipHandler(newChipText);
        }
        setNewChipFocus(false);
        setNewChipText("");
    }
    
    function editChip() {
        if(editChipHandler) {
            editChipHandler(editChipIndex, editChipText)
        }
        disableChipEdit();
    }

    return <>
        {
            chips.map((chip, chipIndex) =>
                chipIndex === editChipIndex ?
                    <input
                        value={editChipText}
                        type="text"
                        autoCapitalize="false"
                        autoComplete="false"
                        autoCorrect="false"
                        autoFocus={true}
                        onChange={(e) => setEditChipText(e.target.value)}
                        onKeyUp={(e) => e.key === "Enter" ? editChip() : null}
                        onBlur={() => disableChipEdit()}
                    />
                :
                    <Chip
                        style={{marginRight: 5, marginTop: 5}}
                        size="small"
                        label={chip}
                        onClick={() => triggerChipEdit(chipIndex)}
                        onDelete={() => removeChip(chipIndex)}
                        clickable={true}
                    />
            )
        }
        {
            newChipFocus ?
                <input
                    value={newChipText}
                    type="text"
                    autoCapitalize="false"
                    autoComplete="false"
                    autoCorrect="false"
                    autoFocus={true}
                    onChange={(e) => setNewChipText(e.target.value)}
                    onKeyUp={(e) => e.key === "Enter" ? addChip() : null}
                    onBlur={() => setNewChipFocus(false)}
                />
            :
                <Chip
                    style={{marginRight: 5, marginTop: 5}}
                    size="small"
                    icon={<AddIcon/>}
                    label={addChipTitle}
                    clickable={true}
                    onClick={() => setNewChipFocus(true)}
                />
        }
    </>
}

export default EditableChips;