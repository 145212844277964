import React, {useEffect, useState, useRef} from 'react';
import { nanoid } from 'nanoid'
import {
    addModuleData, deleteContent, deleteModuleData,
    listModsService,
    submitModuleData,
    updateModuleData,
    updateProgramContent,
    uploadMedia
} from "../../../services/app.service";
import {
    Container,
    Grid,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel, FormControl, InputLabel, Select, MenuItem,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {APP_STATUS, LOCAL_STORAGE_KEYS, TOAST_TYPES} from "../../../utils/constants";
import LoaderBackDropWithPercentage from "../../../components/LoaderWithBackDrop/LoaderBackDropWithPercentage.comp";
import {toast, toastWarning} from "../../../utils/utils";
import axios from "axios";
import {DropzoneDialog} from 'material-ui-dropzone';
import Box from "@material-ui/core/Box";
import {PROGRAM_TARGETS, PROGRAM_TARGETS_MAP} from "../../../utils/META/cat_108";
import {PROGRAM_DURATION, PROGRAM_DURATION_MAP} from "../../../utils/META/cat_112";
import {Field, Form, Formik, useFormikContext} from "formik";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    mt: {
        marginTop: theme.spacing(2),
    },
    mb: {
        marginBottom: theme.spacing(2),
    }
}));

function validateWordLength(value, max_length) {
    const length = value ? value.match(/\w+/g).length : 0;
    const error = length > max_length;
    const error_message = error ? 'Word limit exceeded' : undefined;
    return { length, max_length, error, error_message };
}

function MaxLengthHelperText({ length, max_length, error, error_message }) {
    return <Box display={'flex'} flexDirection={'row'}>
        {error && <Box mr={'auto'}>{error_message}</Box>}
        <Box ml={'auto'}>{length}/{max_length}</Box>
    </Box>
}

const FormTextField = ({ label, name, ...rest }) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required
        margin="normal"
        variant="outlined"
        {...rest}
    />
);

const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);

const FormComponent = ({disableSubmit, edit, setFieldValue}) => {

    const classes = useStyles();
    const {values} = useFormikContext(); // get current form values
    const objectiveValidProps = validateWordLength(values.LearningObjective, 100);
    const targetAudienceOptions = Object.values(PROGRAM_TARGETS);
    const [durationError, setDurationError] = useState(false);

    const handleValueCheck = (value) => {
        if (values.TimeMinutes === 'morethan20hours' && parseInt(value) <= 20) {
            setDurationError(true);
        } else {
            setDurationError(false);
        }
    };
    function parseInitialValues(initialValue, options) {
        return initialValue
            ? initialValue.split(', ').map(value => options.find(option => option.value === value)).filter(Boolean)
            : [];
    }
    const targetAudienceInitialValues = parseInitialValues(values.TargetAudience, targetAudienceOptions);

    const handleAutocompleteChange = (fieldName) => (event, value) => {
        const stringValue = value.map(item => item.value).join(', ');
        setFieldValue(fieldName, stringValue);
    };
    return (
        <Form>
            <FormTextField label="Module ID" name="ModuleID" disabled={true} />
            <FormTextField label="Module name" name="ModuleName" />
            <FormTextField
                label="Learning objectives"
                name="LearningObjective"
                multiline={true}
                helperText={<MaxLengthHelperText {...objectiveValidProps}/>}
                error={objectiveValidProps.error}
                validate={() => objectiveValidProps.error_message}
            />
            <Autocomplete
                sx={{ marginTop: 0, marginBottom: 2 }}
                multiple
                fullWidth={true}
                name="TargetAudience"
                options={Object.values(PROGRAM_TARGETS)}
                value={targetAudienceInitialValues}
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChange("TargetAudience")}
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth={true}
                        variant="outlined"
                        label="Target audience of module (Select all that apply)"
                        InputLabelProps={ { required: true }}
                    />
                )}
            />
            {
                (values.TargetAudience && (values.TargetAudience.includes('Teens') || values.TargetAudience.includes('Youth') || values.TargetAudience.includes('EarlyYears') || values.TargetAudience.includes('Children'))) ? (
                    <>
                        <FormTextField
                            label="Minimum Age"
                            type="number"
                            name="MinAge"
                        />
                        <FormTextField
                            label="Maximum Age"
                            type="number"
                            name="MaxAge"
                        />
                    </>
                ) : ''}
            {values.TargetAudience && (values.TargetAudience.includes('Others') || (values.TargetAudience.length > 1 && values.TargetAudience.includes('Others'))) ?  (
                <FormTextField
                    label="Please specify target audience."
                    name="ProgramTargetOther"
                />
            ) : ''}
            <FormSelect
                label="Duration to complete the module"
                name="TimeMinutes"
                options={PROGRAM_DURATION}
            />
            {values.TimeMinutes === 'morethan20hours' && (
                <FormTextField
                    label="Please specify module duration (in hours)."
                    type="number"
                    name="OtherDurationHours"
                    onBlur={(e) => handleValueCheck(e.target.value)}
                    error={durationError}
                    helperText={durationError && `Number must be greater than 20`}
                />
            )}
            <Box mt={3} mb={2}>
                <Button type="submit" variant="contained" color="primary" disabled={disableSubmit} style={{backgroundColor:'#052F44', color:'white', borderRadius:'15px'}}>
                    {edit ? `Submit Details` : `Add Module`}
                </Button>
            </Box>
        </Form>
    )
}

const initialValues = {
    "ModuleID": "LEG0-M1",
    "ModuleName": "Module 1",
    "LearningObjective": "Objective-2",
    // "TargetAge": "19-24YO",
    "TargetAudience": 'Workforce',
    "ProgramTargetOther": 'Sample Audience',
    "MinAge": 20,
    "MaxAge": 50,
    // "Difficulty": "Advance",
    "TimeMinutes": "lessthan15min",
    "OtherDurationHours": 30,
};

const emptyInitialValues = {
    "ModuleID": "",
    "ModuleName": "",
    "LearningObjective": "",
    // "TargetAge": "",
    "TargetAudience": "",
    "ProgramTargetOther": "",
    "MinAge": '',
    "MaxAge":'',
    // "Difficulty": "",
    "TimeMinutes": "",
    "OtherDurationHours":"",
};

const convertToFormValues = (db_values) => {
    return {
        "ModuleID": db_values.module_id,
        "ModuleName": db_values.module_name,
        "LearningObjective": db_values.learning_objective,
        "TargetAudience":db_values.target_audience,
        "ProgramTargetOther": db_values.target_audience.includes('Others') ? db_values.target_audience_other : '',
        "MinAge": (db_values.target_audience.includes('Teens') ||
            db_values.target_audience.includes('Youth') ||
            db_values.target_audience.includes('EarlyYears') ||
            db_values.target_audience.includes('Children')) ?
            db_values.min_age : '',
        "MaxAge": (db_values.target_audience.includes('Teens') ||
            db_values.target_audience.includes('Youth') ||
            db_values.target_audience.includes('EarlyYears') ||
            db_values.target_audience.includes('Children')) ?
            db_values.max_age : '',
        "Difficulty": db_values.difficulty,
        "TimeMinutes": db_values.time_minutes,
        "OtherDurationHours": db_values.time_minutes === 'morethan20hours' ? db_values.module_duration_other : '',
    }
}

const getInitialFormValues = () => {
    let formValues = process.env.REACT_APP_NAME==='LOCAL' ?  initialValues : emptyInitialValues;
    formValues["ModuleID"] = nanoid();
    return formValues;
}

function UploadFileDialogue(props) {

    const { openDialogue, dialogueCloseHandler, successCB } = props;

    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    async function FileDialogueHandleSave(files) {
        setUploading(true);
        const formData = new FormData();
        formData.append('media', files[0]);
        try {
            const result = await uploadMedia(formData, progressEvent => {
                const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 99);
                setUploadProgress(uploadPercentage);
            })
            if(result.data) {
                const {media_path} = result.data;
                setUploadProgress(100);
                setTimeout(()=> {
                    setUploading(false);
                    setUploadProgress(0);
                    successCB(media_path);
                }, 200);
            }
        }
        catch (e) {
            console.error("Error uploading file", e);
            dialogueCloseHandler();
        }
    }

    const getFileRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) => {
        const typeRegex = new RegExp(`(${acceptedFiles.join("|")})$`, "i")
        if(!typeRegex.test(rejectedFile.name))
            return "File {file_name} does not have allowed file type, i.e. {file_types}.".format({file_name: rejectedFile.name, file_types: acceptedFiles.join(', ')})
        if(rejectedFile.size === 0)
            return "File size cannot be 0";
        if(rejectedFile.size > maxFileSize)
            return `File size cannot be greater than {max_size} kb`.format({max_size: Math.ceil(maxFileSize/1000)});
        return "Invalid file";
    }

    return (
        <>
            <DropzoneDialog
                open={openDialogue}
                onSave={FileDialogueHandleSave}
                acceptedFiles={['.doc', '.docx']}
                showPreviews={true}
                maxFileSize={5000000}
                filesLimit={1}
                onClose={dialogueCloseHandler}
                dropzoneProps={{minSize: 1}}
                getDropRejectMessage={getFileRejectMessage}
            />
            {
                <LoaderBackDropWithPercentage
                    loading={uploading}
                    value={uploadProgress}
                />
            }
        </>
    );
}

const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
    const [puzzleInput, setPuzzleInput] = useState('');
    const [isPuzzleSolved, setIsPuzzleSolved] = useState(false);
    const [puzzle, setPuzzle] = useState({ num1: 0, num2: 0, result: 0 });

    useEffect(() => {
        const num1 = Math.floor(Math.random() * 10) + 1;
        const num2 = Math.floor(Math.random() * 10) + 1;
        setPuzzle({ num1, num2, result: num1 + num2 });
        setPuzzleInput('');
        setIsPuzzleSolved(false);
    }, [open]);

    const handlePuzzleChange = (event) => {
        setPuzzleInput(event.target.value);
        if (parseInt(event.target.value, 10) === puzzle.result) {
            setIsPuzzleSolved(true);
        } else {
            setIsPuzzleSolved(false);
        }
    };

    const handleConfirmClick = () => {
        if (isPuzzleSolved) {
            onConfirm();
        } else {
            onClose();
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    textAlign: 'center',
                    padding: '20px',
                    margin: '0 auto'
                },
            }}
        >
            <DialogTitle style={{ padding: '20px 0 10px' }}>
                Warning
            </DialogTitle>
            <DialogContent>
                This action will reset the Application data and this cannot be undone. Are you sure you want to proceed?
                <br /><br />
                Please solve the puzzle to proceed: What is {puzzle.num1} + {puzzle.num2}?
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={puzzleInput}
                    onChange={handlePuzzleChange}
                />
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmClick}
                            disabled={!isPuzzleSolved}
                        >
                            Accept
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

function CollectionModule(props) {

    const {application_id, app_status, setLoading, updateStatus, forwardStep} = props;
    const classes = useStyles();
    const [moduleData, setModuleData] = useState([]);
    const [openDialogue, setOpenDialogue] = useState(false);
    let module_db_id = useRef(null);
    const isStatusGreaterThanModule = app_status > APP_STATUS.COLLECTED_DATA;
    const isStatusLessThanModule = app_status > APP_STATUS.IN_PROGRESS_COLLECT_DATA;
    const isStatusLessThanCompleted = app_status < APP_STATUS.COMPLETED;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [open, setOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [selectedOption, setSelectedOption] = useState({});
    const [checkboxStatus, setCheckboxStatus] = useState({});
    const [openDialogModuleId, setOpenDialogModuleId] = useState(null);

    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationAction, setConfirmationAction] = useState(null);

    const handleClose = () => {
        module_db_id.current = null;
        setOpen(false);
    };

    const handleConfirmAction = () => {
        if (confirmationAction) {
            confirmationAction();
        }
        setConfirmationDialogOpen(false);
    };

    const handleDeleteModuleAction = (module) => {
        if (isStatusGreaterThanModule) {
            setConfirmationAction(() => () => deleteModuleAction(module));
            setConfirmationDialogOpen(true);
        } else {
            deleteModuleAction(module);
        }
    };

    const handleUploadContentAction = (e, row) => {
        if (isStatusGreaterThanModule) {
            setConfirmationAction(() => () => uploadContentAction(e, row));
            setConfirmationDialogOpen(true);
        } else {
            uploadContentAction(e, row);
        }
    };

    const handleCreateModuleAction = () => {
        if (isStatusGreaterThanModule) {
            setConfirmationAction(() => createModuleAction);
            setConfirmationDialogOpen(true);
        } else {
            createModuleAction();
        }
    };

    useEffect(() => {
        moduleData.forEach(row => {
            // Check if the state for this module doesn't exist yet
            if (!selectedOption[row.module_id]) {
                setSelectedOption(prev => ({
                    ...prev,
                    [row.module_id]: {
                        BULLET: 'message',
                        DIGIT: 'message',
                        ALPHA: 'message',
                        ROMAN: 'message',
                    }
                }));
            }

            if (!checkboxStatus[row.module_id]) {
                setCheckboxStatus(prev => ({
                    ...prev,
                    [row.module_id]: {
                        BULLET: true,
                        DIGIT: true,
                        ALPHA: true,
                        ROMAN: true,
                    }
                }));
            }
        });
    }, [moduleData]); // useEffect dependency on moduleData ensures it runs whenever moduleData changes


    const handleOpenConfigDialogue = (moduleId) => {
        setOpenDialogModuleId(moduleId);
    };

    const handleCloseConfigDialogue = () => {
        setOpenDialogModuleId(null);
    };

    const handleCheckboxChangeConfigs = (event, option, moduleId) => {
        setCheckboxStatus((prevCheckboxStatus) => ({
            ...prevCheckboxStatus,
            [moduleId]: {
                ...prevCheckboxStatus[moduleId],
                [option]: event.target.checked,
            }
        }));
    };

    const handleRadioChangeConfigs = (event, option, moduleId) => {
        setSelectedOption((prevSelectedOption) => ({
            ...prevSelectedOption,
            [moduleId]: {
                ...prevSelectedOption[moduleId],
                [option]: event.target.value,
            }
        }));
    };


    const loadModuleData = async () => {
        setLoading(true);
        try {
            const result = await listModsService(application_id);
            if (!result.error) {
                if (result.data.all_mods) {
                    setModuleData(result.data.all_mods);
                }
            }
        } catch (e) {
            // already toasted the error
        }
        setLoading(false);
    }

    const submitModules = async () => {
        const configsToSend = {};

        Object.keys(checkboxStatus).forEach(moduleId => {
            configsToSend[moduleId] = {
                selectedOption: selectedOption[moduleId],
                checkboxStatus: checkboxStatus[moduleId],
            };
        });

        console.log(configsToSend);
        try {
            setLoading(true);
            const result = await submitModuleData(application_id,configsToSend);
            if(!result.error) {
                setIsSubmitted(true); // Disable the button by setting this to true.
                toast(TOAST_TYPES.SUCCESS, "Modules Submitted", "Successfully submitted modules, they will be analyzed and report will be available shortly.")
                // if (result.data.success) {
                //     // updateStatus(result.data.status);
                // }
            }
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            //already toasted
            closeUploadDialog();
        }
    }

    const handleSubmit = async (values) => {
        setDisableSubmit(true);
        try {
            setLoading(true);
            let result;
            const modifiedValues = { ...values, Difficulty: 'N/A', TargetAge: 'N/A' };

            if (module_db_id.current) {
                result = await updateModuleData(module_db_id.current._id, modifiedValues, );
            }
            else {
                result = await addModuleData(application_id, modifiedValues);
            }
            if (!result.error) {
                if (result.data.all_mods) {
                    setModuleData(result.data.all_mods);
                }
                if (!module_db_id.current) {
                    updateStatus(APP_STATUS.COLLECTED_DATA);
                }
            }
            setLoading(false);
        } catch (e) {
            // already toasted the error
            setLoading(false);
        }
        setOpen(false);
        setDisableSubmit(false);
    }

    const deleteModuleAction = async (module) => {
        module_db_id.current = module;

        try {
            if (module_db_id.current.content_file_uploaded) {
                const {content_file_path_original: file_path_original, content_file_path_updated: file_path_updated} = module_db_id.current;
                if(file_path_original) {
                    deleteContent(file_path_original).then((r) => console.log(`File removed ${file_path_original}`));
                }
                if (file_path_updated) {
                    deleteContent(file_path_updated).then((r) => console.log(`File removed ${file_path_updated}`));
                }
            }
            //delete content
            setLoading(true);
            const result = await deleteModuleData(application_id, module_db_id.current._id);
            if (!result.error) {
                toast(TOAST_TYPES.SUCCESS, "Module Deleted", "Successfully deleted module")
                if (result.data.all_mods) {
                    setModuleData(result.data.all_mods);
                }
                updateStatus(APP_STATUS.COLLECTED_DATA);
            }
            module_db_id.current = null;
            setLoading(false);
        } catch (e) {
            //already toasted
            setLoading(false);
        }
    }

    const addFilePathToApplication = async (file_path) => {
        try {
            //remove existing if available (asynchronously)
            if(module_db_id.current.content_file_uploaded) {
                const {content_file_path_updated: file_path} = module_db_id.current;
                deleteContent(file_path).then((r) => console.log(`File removed ${file_path}`));
            }
            //update content with new file path
            const result = await updateProgramContent(application_id, module_db_id.current._id, file_path);
            if(!result.error) {
                toast(TOAST_TYPES.SUCCESS, "Content Uploaded", "Successfully uploaded content")
                if (result.data.all_mods) {
                    setModuleData(result.data.all_mods);
                }
            }
            closeUploadDialog();
        }
        catch (e) {
            //already toasted
            closeUploadDialog();
        }
    }

    const createModuleAction = () => {
        setOpen(true);
    }

    const editModuleAction = (module) => {
        module_db_id.current = module;
        setOpen(true);
    }

    const uploadContentAction = (e, row) => {
        module_db_id.current = row;
        setOpenDialogue(true);
    }

    const closeUploadDialog = () => {
        module_db_id.current = null;
        setOpenDialogue(false);
    }

    useEffect( ()=>{
        loadModuleData().then((res) => `fetch initiated, ${res}`);
    }, [] )

    let isAnyContentFileUploaded = false;
    for(const module of moduleData) {
        if(module.content_file_uploaded) isAnyContentFileUploaded = true;
    }
    const options = ["BULLET", "DIGIT", "ALPHA", "ROMAN"];
    return (
        <Container className={classes.container}>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Module Details</DialogTitle>
                <DialogContent>
                    <Formik initialValues={
                        module_db_id.current ? convertToFormValues(module_db_id.current) :
                            getInitialFormValues()
                    } onSubmit={handleSubmit}>
                        {({ setFieldValue }) => (
                            <Form>
                                <FormComponent
                                    disableSubmit={disableSubmit}
                                    edit={Boolean(module_db_id.current)}
                                    setFieldValue={setFieldValue}
                                    enableReinitialize={true}
                                />
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            <Grid container justify="center">
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!isStatusLessThanCompleted}
                    className={classes.button}
                    style={{backgroundColor:'#052F44', color:'white', borderRadius:'15px', marginTop:'15px'}}
                    onClick={handleCreateModuleAction}
                >
                    Add A Module
                </Button>
            </Grid>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Module ID</TableCell>
                            <TableCell>Module name</TableCell>
                            <TableCell>Learning objectives</TableCell>
                            <TableCell>Target audience</TableCell>
                            <TableCell>Other Target audience</TableCell>
                            <TableCell>Minimum Age</TableCell>
                            <TableCell>Maximum Age</TableCell>
                            <TableCell>Module duration</TableCell>
                            <TableCell>Other Module duration </TableCell>
                            {/*<TableCell>Difficulty</TableCell>*/}
                            <TableCell>Content File</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            [...moduleData]
                                .sort((a, b) => a.order_no - b.order_no)
                                .map((row) =>
                                <TableRow>
                                    <TableCell>{row._id}</TableCell>
                                    <TableCell>{row.module_name}</TableCell>
                                    <TableCell>{row.learning_objective}</TableCell>
                                    <TableCell>{row.target_audience ? row.target_audience.split(', ').map(val => PROGRAM_TARGETS_MAP[val]?.label || val).join(', ') : ''}</TableCell>
                                    <TableCell>{row.target_audience_other ? row.target_audience_other : '-'}</TableCell>
                                    <TableCell>{row.min_age ? row.min_age : '-'}</TableCell>
                                    <TableCell>{row.max_age ? row.max_age : '-'}</TableCell>
                                    {/*<TableCell>{row.difficulty}</TableCell>*/}
                                    <TableCell>{row.time_minutes ?
                                        row.time_minutes
                                            .split(', ')
                                            .map(val => PROGRAM_DURATION_MAP[val]?.label || val) // Map each value to its label
                                            .join(', ') // Join the labels with commas
                                        : ''}</TableCell>
                                    <TableCell>{row.module_duration_other ? row.module_duration_other : '-'}</TableCell>
                                    <TableCell>
                                        {
                                            row.content_file_uploaded ?
                                                <>
                                                    <a href={row.content_file_path_updated}
                                                       target="_blank" rel="noopener noreferrer">
                                                        Download File
                                                    </a>
                                                    {
                                                        isStatusLessThanCompleted &&
                                                        <>
                                                            {` | `}
                                                            <a href={"#"} rel="noopener noreferrer" onClick={(e) => handleUploadContentAction(e, row)}>
                                                                Reupload
                                                            </a>
                                                            {` | `}
                                                            <a href={"#"} onClick={() => handleOpenConfigDialogue(row.module_id)}>Config</a>
                                                            <Dialog open={openDialogModuleId === row.module_id} onClose={handleCloseConfigDialogue} fullWidth maxWidth="md">
                                                                <DialogTitle style={{padding:'50px 0 30px 60px'}} >Configure Merge Strategy</DialogTitle>
                                                                <DialogContent style={{textAlign: 'center',padding:' 0 60px 20px 60px'}}>
                                                                    <TableContainer>
                                                                        <Table aria-label="simple table" size="small">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Parameters</TableCell>
                                                                                    <TableCell>Question</TableCell>
                                                                                    <TableCell>Message</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {options.map((option) => (
                                                                                    <TableRow key={option}>
                                                                                        <TableCell align={"justify"}>
                                                                                            <Checkbox
                                                                                                color="primary"
                                                                                                checked={checkboxStatus[row.module_id]?.[option] || false}
                                                                                                onChange={(e) => handleCheckboxChangeConfigs(e, option, row.module_id)}
                                                                                            />
                                                                                            {option}
                                                                                        </TableCell>
                                                                                        <TableCell align={"justify"}>
                                                                                            <Radio
                                                                                                color="primary"
                                                                                                value="question"
                                                                                                checked={
                                                                                                    selectedOption[row.module_id]?.[option] === 'question' &&
                                                                                                    checkboxStatus[row.module_id]?.[option]
                                                                                                }
                                                                                                onChange={(e) => handleRadioChangeConfigs(e, option, row.module_id)}
                                                                                                disabled={!checkboxStatus[row.module_id]?.[option]}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell align={"justify"}>
                                                                                            <Radio
                                                                                                color="primary"
                                                                                                value="message"
                                                                                                checked={
                                                                                                    selectedOption[row.module_id]?.[option] === 'message' &&
                                                                                                    checkboxStatus[row.module_id]?.[option]
                                                                                                }
                                                                                                onChange={(e) => handleRadioChangeConfigs(e, option, row.module_id)}
                                                                                                disabled={!checkboxStatus[row.module_id]?.[option]}
                                                                                            />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Grid container justifyContent="center" alignItems="center">
                                                                        <Button size="large" variant="contained" color="primary" onClick={handleCloseConfigDialogue}  style={{marginBottom:'30px', marginLeft:'-50px'}}>
                                                                            Save
                                                                        </Button>
                                                                    </Grid>
                                                                </DialogActions>
                                                            </Dialog>

                                                        </>
                                                    }
                                                </>
                                                :
                                                <Button variant="contained" style={{backgroundColor:'#11888B', color:'white'}} onClick={(e) => uploadContentAction(e, row)}>upload content (docx file)</Button>
                                        }
                                    </TableCell>
                                    <TableCell style={{ color: "#11888B",}}>
                                        <Grid style={{display:'flex'}}>
                                            <Button
                                                variant="contained"
                                                disabled={!isStatusLessThanCompleted}
                                                style={{width: '50px', margin: '5px', backgroundColor:'#11888B', color: 'white'}}
                                                onClick={(e) => editModuleAction(row)}
                                            >
                                                Edit
                                            </Button>
                                            { isStatusLessThanCompleted &&
                                                <Button
                                                    variant="contained"
                                                    disabled={!isStatusLessThanCompleted}
                                                    style={{width: '50px', margin: '5px', color: 'white', backgroundColor: 'red'}}
                                                    onClick={(e) => handleDeleteModuleAction(row)}
                                                >
                                                    Delete
                                                </Button>
                                            }
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            )
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                <Button className={classes.mt} variant="contained" color="primary" onClick={() => submitModules()} disabled={moduleData.length <= 0 || !isAnyContentFileUploaded || isStatusGreaterThanModule || !isStatusLessThanModule || isSubmitted}>
                    Submit
                </Button>
                <Button
                    className={classes.mt}
                    variant="contained"
                    color="secondary"
                    onClick={() => forwardStep()}
                    disabled={!isStatusGreaterThanModule}
                >
                    Next: Cleaning
                </Button>
            </Box>
            <UploadFileDialogue
                openDialogue={openDialogue}
                dialogueCloseHandler={()=>{closeUploadDialog()}}
                successCB={addFilePathToApplication}
            />
            <ConfirmationDialog
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
                onConfirm={handleConfirmAction}
            />
        </Container>
    );
}

export default CollectionModule;