import React from 'react';
import ImpactTracking from "../../../../assets/images/report/impacttracking.png";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";

export default function Page16(props) {

    const {pageNumber, onlyTable = false} = props;

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            {
                !onlyTable &&
                <Box id="3.2-impact-tracking-with-a-curated-dq-assessment" display={'flex'} flexDirection={'column'}>

                    <Typography
                        variant={'h3'}
                        style={{
                            color: "orangered",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '70px',
                            fontSize: '22px'
                        }}
                    >
                        3.2	Impact Tracking with Curated DQ Assessment
                    </Typography>
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '30px',
                            fontSize: '18px',
                            textAlign: 'justify'
                        }}
                    >
                        Another way to enhance your program is to implement your very own assessment tailored to your program.
                        <br /> <br />
                        These assessments would enable you to visualize your program’s impact which includes:
                        <ul style={{ paddingLeft: '50px', listStyleType: "disc" }}>
                            <li>In-depth analysis to the end users (e.g., individuals and organizations) to identify their
                                strengths and weaknesses through global benchmark.
                            </li>
                            <li>Comparisons to national averages and scores based on schools, groups, or regions.</li>
                            <li>Program reach data broken down into various demographics of age, gender, and more.</li>
                            <li>Potential pre/post assessments to measure efficacy of your program.</li>
                        </ul>
                        By incorporating individual assessment and certification functions, your programs have the potential
                        to provide students with personalized feedback and recognition of their digital competency achievements.
                    </Typography>
                </Box>
            }
            <Box display={'flex'} alignItems={'center'}>
                <Box component="img" src={ImpactTracking} alt="Impact Tracking" style={{ width: '685px', height: '500px', marginRight: '30px', marginLeft: '0px', paddingTop: '30px' }} />
            </Box>
        </Box>
    </ReportPage>
}