import React, {useEffect, useState} from 'react';
import {listAssessModules, updateAppStatusService, updateAssessModules,} from "../../../services/app.service";
import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {APP_STATUS} from "../../../utils/constants";
import {toastSuccess} from "../../../utils/utils";
import {SketchPicker} from 'react-color'
import UploadImageDialogue from "../components/uploadImageDialogue.comp";
import {useLevel3} from "../../Applications/hooks/useLevel3.hook";

function ConfigModule(props) {
    const {application_id, app_status, updateStatus, forwardStep, setLoading} = props;
    const [appModuleData, setAPPModuleData] = useState([]);
    const [orgModuleData, setORGModuleData] = useState([]);
    const [moduleDefinitionData, setModuleDefinitionData] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [editingRows, setEditingRows] = useState({});
    const [localEdits, setLocalEdits] = useState({});
    // Local component state for the input
    const [tempEdits, setTempEdits] = useState({});
    const [useDefinitionAsDescription, setUseDefinitionAsDescription] = useState({});
    const [selectedOption, setSelectedOption] = useState({});
    const [updateIconOfItem, setUpdateIconOfItem] = useState("");
    const [LEVEL3, setLEVEL3] = useState({});
    useLevel3(onSetLevel3)

    function onSetLevel3(object) {
        setLEVEL3(object);
    }

    useEffect(() => {
        retrieveAssessModules().then();
    }, [])

    async function retrieveAssessModules() {
        setLoading(true);
        try {
            const result = await listAssessModules(application_id);
            if(!result.error && result.data) {
                if(result.data.org_modules) {
                    setAPPModuleData(result.data.app_modules)
                    setORGModuleData(result.data.org_modules)
                    const module_definitions = Object.values(LEVEL3)
                        .map(item => item.definition)
                        .filter(definition => definition.trim() !== '');
                    setModuleDefinitionData(module_definitions);
                }
            }
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false);
    }


    // Toggle editing for a specific row
    const toggleEditing = (id) => {
        setEditingRows(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    function updateModuleData(module_code, key, value) {
        setLocalEdits(prev => ({
            ...prev,
            [module_code]: {
                ...prev[module_code],
                [key]: value
            }
        }));
    }

    // Update local state on every keystroke
    const handleInputChange = (module_code, key, value) => {
        setTempEdits(prev => ({
            ...prev,
            [module_code]: {
                ...prev[module_code],
                [key]: value
            }
        }));
    };


    // Update the global state when the user finishes editing
    const handleInputBlur = (module_code, key) => {
        updateModuleData(module_code, key, tempEdits[module_code][key]);
    };

    // // This handles changes in the select dropdown
    // const handleSelectChange = (module_code, key, value) => {
    //     setTempEdits(prev => ({
    //         ...prev,
    //         [module_code]: {
    //             ...prev[module_code],
    //             [key]: value
    //         }
    //     }));
    // };
    //
    // // This updates the main state when the dropdown is blurred
    // const handleSelectBlur = (module_code, key) => {
    //     updateModuleData(module_code, key, tempEdits[module_code][key]);
    // };

    const handleDropdownChange = (module_code, selectedDefinition) => {
        // Update the tempEdits state for dropdown and textfield
        setTempEdits(prev => ({
            ...prev,
            [module_code]: {
                ...prev[module_code],
                module_description: selectedDefinition
            }
        }));

        // Update the localEdits state as well
        updateModuleData(module_code, 'module_description', selectedDefinition);

        // Update the useDefinitionAsDescription to custom so that the TextField is active
        setUseDefinitionAsDescription(prev => ({
            ...prev,
            [module.module_code]: "custom"
        }));
    };

    const saveUpdateIcon = (iconURL) => {
        toastSuccess('Success', 'File Uploaded');
        // Get the module being updated
        const moduleToUpdate = orgModuleData.find(mod => mod.module_code === updateIconOfItem);

        // Update the tempEdits state with the new iconURL
        setTempEdits(prev => ({
            ...prev,
            [moduleToUpdate.module_code]: {
                ...(prev[moduleToUpdate.module_code] || {}), // Get existing edits for this module, if any
                icon_url: iconURL
            }
        }));

        // Update the localEdits with the new iconURL
        updateModuleData(moduleToUpdate.module_code, 'icon_url', iconURL);

        // Close the icon upload dialog
        setUpdateIconOfItem("");
    }




    async function saveModuleChanges(module_code, module_id) {
        if (localEdits[module_code]) {
            setLoading(true);
            try {
                let module = {module_code, ...localEdits[module_code]};
                const result = await updateAssessModules(application_id, module);

                if(!result.error && result.data.success) {

                    // Update the orgModuleData state with the new changes
                    setORGModuleData(prev => prev.map(mod => mod.module_code === module_code ? {...mod, ...localEdits[module_code]} : mod));

                    // Clear the local edits for this module after successful save.
                    setLocalEdits(prev => {
                        const newState = { ...prev };
                        delete newState[module_code];
                        return newState;
                    });
                    setTempEdits(prev => {
                        const newState = { ...prev };
                        delete newState[module_code];
                        return newState;
                    });
                    toastSuccess('Success', 'Module Updated');
                    await retrieveAssessModules();

                }
            } catch (e) {
                // handle error
            }

            // Switch off editing mode for this row
            toggleEditing(module_id);
            setLoading(false);
        }
    }


    async function submitConfig() {
        setLoading(true);
        setDisableSubmit(true);
        try {
            const result = await updateAppStatusService(application_id, APP_STATUS.PENDING_PUBLISH_MODULES, true);
            if(!result.error && result.data.success) {
                updateStatus(APP_STATUS.PENDING_PUBLISH_MODULES);
                toastSuccess('Success', 'Assessment Ready');
            }
        }
        catch (e) {
            //already toasted
            setDisableSubmit(false);
        }
        setLoading(false);
    }

    return <>
        <Box mt={2} align={'center'}>
            <Paper elevation={3} style={{ overflowX: 'auto' }}>
                <TableContainer style={{maxWidth: "100%"}}>
                <Table>
                    <TableHead>
                            <TableRow>
                                <TableCell>Module #</TableCell>
                                <TableCell>Program Module Name</TableCell>
                                <TableCell>Assessment Module Name</TableCell>
                                <TableCell>Module Description</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Pre-Test</TableCell>
                                <TableCell>Module Icon</TableCell>
                                <TableCell>Theme Color</TableCell>
                                <TableCell>Badge Background</TableCell>
                                <TableCell>Primary Color</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...orgModuleData]
                            .sort((a, b) => a.order_no - b.order_no)
                            .map((module, index) => (

                            <TableRow key={module.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    {
                                        (() => {
                                            const appModule = appModuleData.find(mod => mod.module_id === module.app_module_id_ref);
                                            return appModule ? appModule.module_name : module.module_name;
                                        })()
                                    }
                                </TableCell>
                                <TableCell>
                                    {editingRows[module.id]
                                        ? <TextField
                                            style={{ width: '200px' }}
                                            value={tempEdits[module.module_code]?.module_name !== undefined ? tempEdits[module.module_code]?.module_name : (localEdits[module.module_code]?.module_name || module.module_name)}
                                            onChange={(e) => handleInputChange(module.module_code, 'module_name', e.target.value)}
                                            onBlur={() => handleInputBlur(module.module_code, 'module_name')}
                                        />
                                        : module.module_name
                                    }
                                </TableCell>
                                <TableCell>
                                    {editingRows[module.id]
                                        ? <>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    name={`useDefinition-${module.module_code}`}
                                                    value={useDefinitionAsDescription[module.module_code]}
                                                    onChange={(e) => setUseDefinitionAsDescription(prev => ({
                                                        ...prev,
                                                        [module.module_code]: e.target.value
                                                    }))}
                                                >
                                                    <FormControlLabel
                                                        value="definition"
                                                        control={<Radio color="primary" />}
                                                        label={
                                                            <FormControl variant="outlined" style={{ width: '200px' }}>
                                                                <InputLabel htmlFor={`module-definition-${module.module_code}`}>Select Description</InputLabel>
                                                                <Select
                                                                    value={tempEdits[module.module_code]?.module_description || ''}
                                                                    onChange={(e) => handleDropdownChange(module.module_code, e.target.value)}
                                                                    label="Select Description"
                                                                    id={`module-definition-${module.module_code}`}
                                                                    disabled={useDefinitionAsDescription[module.module_code] !== "definition"}

                                                                >
                                                                    {Array.isArray(moduleDefinitionData) && moduleDefinitionData.map((definition, index) => (
                                                                        <MenuItem key={index} value={definition}>
                                                                            {definition}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>


                                                            </FormControl>
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value="custom"
                                                        control={<Radio color="primary" />}
                                                        label={
                                                            <TextField
                                                                style={{ width: '200px' }}
                                                                value={
                                                                    tempEdits[module.module_code]?.module_description
                                                                    || localEdits[module.module_code]?.module_description
                                                                    || module.module_description
                                                                }
                                                                onChange={(e) => handleInputChange(module.module_code, 'module_description', e.target.value)}
                                                                onBlur={() => handleInputBlur(module.module_code, 'module_description')}
                                                                disabled={useDefinitionAsDescription[module.module_code] !== "custom"}

                                                            />

                                                        }
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </>
                                        : module.module_description
                                    }
                                </TableCell>
                                <TableCell>
                                    {editingRows[module.id]
                                        ? <TextField
                                            style={{ width: '200px' }}
                                            value={tempEdits[module.module_code]?.duration !== undefined ? tempEdits[module.module_code]?.duration : (localEdits[module.module_code]?.duration || module.duration)}
                                            onChange={(e) => handleInputChange(module.module_code, 'duration', e.target.value)}
                                            onBlur={() => handleInputBlur(module.module_code, 'duration')}
                                        />
                                        : module.duration
                                    }
                                </TableCell>
                                <TableCell>
                                    {/*{editingRows[module.id]*/}
                                    {/*    ? (*/}
                                    {/*        <FormControl variant="outlined" style={{ width: '200px' }}>*/}
                                    {/*            <InputLabel id={`${module.id}-label`}>Is Post Only</InputLabel>*/}
                                    {/*            <Select*/}
                                    {/*                labelId={`${module.id}-label`}*/}
                                    {/*                value={*/}
                                    {/*                    (tempEdits[module.module_code]?.is_post_only !== undefined)*/}
                                    {/*                        ? (tempEdits[module.module_code]?.is_post_only ? "Yes" : "No")*/}
                                    {/*                        : (localEdits[module.module_code]?.is_post_only !== undefined)*/}
                                    {/*                            ? (localEdits[module.module_code]?.is_post_only ? "Yes" : "No")*/}
                                    {/*                            : (module.is_post_only ? "Yes" : "No")*/}
                                    {/*                }*/}
                                    {/*                onChange={(e) => handleSelectChange(module.module_code, 'is_post_only', e.target.value === "Yes")}*/}
                                    {/*                onBlur={() => handleSelectBlur(module.module_code, 'is_post_only')}*/}
                                    {/*                label="Is Post Only"*/}
                                    {/*            >*/}
                                    {/*                <MenuItem value="Yes">Yes</MenuItem>*/}
                                    {/*                <MenuItem value="No">No</MenuItem>*/}
                                    {/*            </Select>*/}
                                    {/*        </FormControl>*/}
                                    {/*    )*/}
                                    {/*    : */}
                                    {module.pre_test_module_id ? "No" : "Yes"}
                                    {/*}*/}
                                </TableCell>
                                <TableCell>
                                    <img
                                        src={`${tempEdits[module.module_code]?.icon_url || localEdits[module.module_code]?.icon_url || module.icon_url}?t=${Date.now()}`}
                                        onClick={editingRows[module.id] ? () => { setUpdateIconOfItem(module.module_code) } : null}
                                        alt="Module Icon"
                                        style={{ width: '60px', height: '60px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    {editingRows[module.id]
                                        ? <SketchPicker
                                            color={localEdits[module.module_code]?.theme_color || module.theme_color}
                                            onChangeComplete={(color) => updateModuleData(module.module_code, 'theme_color', color.hex)}
                                        />
                                        : <div style={{ width: '60px', height: '40px', backgroundColor: module.theme_color, border: '1px solid' }}></div>
                                    }
                                </TableCell>
                                <TableCell>
                                    {editingRows[module.id]
                                        ? <SketchPicker
                                            color={localEdits[module.module_code]?.badge_background || module.badge_background}
                                            onChangeComplete={(color) => updateModuleData(module.module_code, 'badge_background', color.hex)}
                                        />
                                        : <div style={{ width: '60px', height: '40px', backgroundColor: module.badge_background, border: '1px solid' }}></div>
                                    }
                                </TableCell>
                                <TableCell>
                                    {editingRows[module.id]
                                        ? <SketchPicker
                                            color={localEdits[module.module_code]?.primary_color || module.primary_color}
                                            onChangeComplete={(color) => updateModuleData(module.module_code, 'primary_color', color.hex)}
                                        />
                                        : <div style={{ width: '60px', height: '40px', backgroundColor: module.primary_color, border: '1px solid' }}></div>
                                    }
                                </TableCell>
                                <TableCell>
                                    {editingRows[module.id]
                                        ? (
                                            <>
                                                <Button
                                                    variant='contained'
                                                    color="secondary"
                                                    onClick={() => saveModuleChanges(module.module_code, module.id)}
                                                    style={{ width: '80px' }}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    color="secondary"
                                                    onClick={() => toggleEditing(module.id)}
                                                    style={{ width: '80px', marginTop: '10px' }}
                                                >
                                                    Cancel
                                                </Button>
                                            </>
                                        )
                                        : <Button
                                            variant='contained'
                                            color="secondary"
                                            onClick={() => toggleEditing(module.id)}
                                            // disabled={disableSubmit || app_status > APP_STATUS.AUTO_ASSESS_GENERATION_COMPLETED}
                                        >
                                            Edit
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
                <UploadImageDialogue
                    openDialogue={updateIconOfItem !== ""}
                    dialogueCloseHandler={()=>{setUpdateIconOfItem("")}}
                    successCB={saveUpdateIcon}
                />
            </Paper>
        </Box>
        <Box mt={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
            <Button
                variant='contained'
                color="primary"
                onClick={() => submitConfig()}
                disabled={disableSubmit || app_status > APP_STATUS.PENDING_APPROVE_MODULES}
            >Submit</Button>
            <Button
                variant="contained"
                color="secondary"
                disabled={!disableSubmit && !(app_status > APP_STATUS.PENDING_APPROVE_MODULES)}
                onClick={() => forwardStep()}
            >
                Next
            </Button>
        </Box>
    </>
}

export default ConfigModule;