import React, { useState } from 'react';
import { utils, writeFile } from "xlsx";
import createSheet from "./createSheet.util";

import {
    getLmsListWithHistory
} from "../../../services/app.service";


import {makeStyles} from "@material-ui/core/styles";
import {Button, darken} from "@material-ui/core";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },

    excel_button: {
        backgroundColor: "#006400",
        color: "white",
        '&:hover': {
            backgroundColor: darken("#DD6400", 0.2),
        }
    },

    overlay: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#333333',
    },

    messageLoading: {
        marginTop: theme.spacing(25),  // Adding margin-top for the desired gap
        color: '#AAAAAA', // Setting the text color to red
        marginLeft: theme.spacing(-10),
        fontFamily: 'monospace', // Setting the desired font family
        fontSize: '30px', // Setting the desired font size
    },
}));


const AppLMSLogsDownload = (props) => {
    const classes = useStyles();
    const {application_id, disable_flg} = props;

    const [loading, setLoading] = useState(false);

    const createMappingsSheetUpdated = (lmsExportSheet) => {
        const headers = ["Application ID", "Application Name", "Module ID", "Module Name", "LM ID ", "Learning Message", "Type", "Responses", "Level 9 Initial", "Level 9 Final", "Comments", "History"]//, "Level 5", "Level 6", "Level 8", "Level 9", "MB#", "DQC#", "DQ#","comments"]
        const fields = ["application_id", "application_name", "_id", "module_name", "message_id", "message", "type", "responses", "level_9_init", "level_9", "comments", 'msg_history']//, "level_5", "level_6", "level_8", "level_9", "mb_no", "dqc_no", "dq_no","comments"]
        console.log("creating XLS sheet of LMS with udpated history ....")


        return createSheet(headers, fields, lmsExportSheet);
    }
    const sortDataByOrderNoAndMessageId = (data) => {
        return data.sort((a, b) => {
            if (a.order_no < b.order_no) return -1;
            if (a.order_no > b.order_no) return 1;

            const aId = a.message_id.split('-').map(Number);
            const bId = b.message_id.split('-').map(Number);

            for (let i = 0; i < aId.length; i++) {
                if (aId[i] < bId[i]) return -1;
                if (aId[i] > bId[i]) return 1;
            }
            return 0;
        });
    };
    const downloadLearningMessagesExcelUpdated = async (app_id) => {

        setLoading(true);

        try {
            const result = await getLmsListWithHistory(app_id)
            const lmsExportSheet = result.data.appAlteredLMs;
            const sortedLmsExportSheet = sortDataByOrderNoAndMessageId(lmsExportSheet);
            const workbook = utils.book_new();
            const mappings_sheet = createMappingsSheetUpdated(sortedLmsExportSheet);
            utils.book_append_sheet(workbook, mappings_sheet, "Mappings");
            let excel_file_name = 'DQ Seal - '
            if (result.data.app_name) {
                excel_file_name += result.data.app_name;
            }

            excel_file_name = excel_file_name.concat(' ',String(Date.now()), '.xlsx');

            console.log("creating ", excel_file_name, 'for', app_id)

            writeFile(workbook, excel_file_name);
        } catch (e) {
            console.log('failed fetching data for excel: ', e);
        }

        setLoading(false);
    }

    return (
        <>
            <Button
                variant='contained'
                className={classes.excel_button}
                disabled={!disable_flg}
                onClick={() => {
                    const userConfirmed = window.confirm("Downloading LMs with History is time-taking. Do you want to download?");
                    if (userConfirmed) {
                        downloadLearningMessagesExcelUpdated(application_id);
                    } else {
                        // User chose not to download updates, you can add additional logic here if needed
                        console.log("User canceled download.");
                    }
                }}
            >
                Download Mapping
            </Button>

            <LoaderWithBackDrop loading={loading}/>
        </>
    )
}

export default AppLMSLogsDownload;


