import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

const AnalysisPieChart = ({ data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const label = data.labels[context.dataIndex];
            const value = context.formattedValue;
            const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
            const percentage = ((context.raw / total) * 100).toFixed(2);
            return `${label}: ${percentage}% (${value})`;
          },
        },
      },
      datalabels: {
        formatter: (value) => {
          return value; 
        },
        color: "white",
        font: {
          size: 14,
          weight: 'bold'
        },
        textAlign: 'center',
        textStrokeColor: '#000000',
        textStrokeWidth: 2,
        anchor: 'end',
        align: 'start',
        padding: 6,
      }
    }
  };

  const backgroundColor = [
    'rgba(255, 193, 7, 0.8)',
    'rgba(0, 201, 0, 0.8)',
    'rgba(255, 0, 0, 0.8)',
    'rgba(0, 0, 201, 0.8)',
    'rgba(128, 128, 128, 0.8)'
  ];

  const modifiedData = {
    ...data,
    datasets: [
      {
        ...data.datasets[0],
        backgroundColor 
      }
    ]
  };

  return (
    <div style={{ width: '100%', height: '300px' }}>
      <Pie data={modifiedData} options={options} />
    </div>
  );
};

export default AnalysisPieChart;
