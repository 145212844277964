import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";

function TableSearchBar(props) {

    const {searchValue, onChange} = props;
    return <TextField
        variant="outlined"
        value={searchValue}
        onChange={(event) => onChange(event.target.value)}
        placeholder="Search…"
        InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
                <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: searchValue ? 'visible' : 'hidden' }}
                    onClick={() => onChange('')}
                >
                    <ClearIcon fontSize="small" />
                </IconButton>
            ),
        }}
    />
}

export default TableSearchBar;