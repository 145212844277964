import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Container, Paper, Button, Grid, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {REGIONS_MAP} from "../../../utils/META/cat_101";
import {ORG_TYPES_MAP} from "../../../utils/META/cat_102";
import {SECTORS_MAP} from "../../../utils/META/cat_103";
import {HIST_DURATIONS_MAP} from "../../../utils/META/cat_104";
import {SIZE_EMPLOYEE_MAP} from "../../../utils/META/cat_105";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    label: {
        textAlign: 'right',
        marginRight: theme.spacing(2),
        wordBreak: "break-word"
    },
    value: {
        textAlign: 'left',
        marginTop: theme.spacing(0.8),
        marginLeft: theme.spacing(2),
        wordBreak: "break-word"
    },
}));

const key_desc = {
    NameOrg: "Name of organization",
    NameContact: "Name of contact person",
    NameDept: "Department and position of contact person",
    Region: "Country of organization’s headquarters",
    OrgType: "Type of organization",
    OrganizationEntered:"Other type of organization",
    Sector: "Sector of organization",
    SectorEntered: "Other sector of organization",
    History: "Age of organization",
    SizeEmployee: "Size of organization",
    OrgVision: "Mission and vision of organization"
}

const key_val = {
    Region: (value) => REGIONS_MAP[value] && REGIONS_MAP[value].name ? REGIONS_MAP[value].name : value,
    OrgType: (value) => ORG_TYPES_MAP[value] && ORG_TYPES_MAP[value].label ? ORG_TYPES_MAP[value].label : value,
    OrganizationEntered: (value) => ORG_TYPES_MAP[value] && ORG_TYPES_MAP[value].label ? ORG_TYPES_MAP[value].label : value,
    Sector: (value) => SECTORS_MAP[value] && SECTORS_MAP[value].label ? SECTORS_MAP[value].label : value,
    SectorEntered: (value) => SECTORS_MAP[value]  && SECTORS_MAP[value].label ? SECTORS_MAP[value].label : value,
    History: (value) => HIST_DURATIONS_MAP[value] && HIST_DURATIONS_MAP[value].label ? HIST_DURATIONS_MAP[value].label : value,
    SizeEmployee: (value) => SIZE_EMPLOYEE_MAP[value] && SIZE_EMPLOYEE_MAP[value].label ? SIZE_EMPLOYEE_MAP[value].label : value,
}

function CompanyInformation(props) {

    const {compInfo, application_id} = props;

    const classes = useStyles();

    const history = useHistory();

    const editCompanyInfo = (e) => {
        history.push('/application/edit/company', {existingValues: compInfo, application_id});
    }

    return <Container maxWidth={false} className={classes.container} component={Paper}>
        <Grid container spacing={3}>
            <Button variant={'outlined'} style={{marginLeft: 'auto'}} onClick={editCompanyInfo}>
                Edit
            </Button>
            {Object.entries(compInfo).map(([key, value]) =>
                value && (
                <Grid item xs={12} key={key}>
                    <Grid container justify="center" alignItems="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="h6" className={classes.label}>{key_desc[key]}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" className={classes.value}>{key_val[key] ? key_val[key](value) : value}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </Container>
}

export default CompanyInformation;
