import React, {useEffect, useState, useRef} from 'react';
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Modal,
    Box, TablePagination, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {SEAL_TYPE_VERBOSE, APP_STATUS_VERBOSE, APP_STATUS} from "../../utils/constants";
import {getCertifyAppsService} from "../../services/app.service";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import {useHistory} from "react-router-dom";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
}));

const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    switch(field) {
        case "submission_time":
            return (new Date(value)).toLocaleDateString(undefined, date_options);
        case "dqseal_type":
            return SEAL_TYPE_VERBOSE[value];
        case "app_status":
            return APP_STATUS_VERBOSE[value];
        default:
            return value;
    }
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const ListCertify = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [appData, setAppData] = useState([]);

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    function setAppDataEx(data) {
        setAppData(data);
        setSearchText(searchText);
    }

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }
    const filteredRows = appData.filter(field_filter_function).filter((row) => {
        return row;
      });

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const history = useHistory();

    async function fetchCertifyApplications() {
        try {
            setLoading(true);
            const result_apps = await getCertifyAppsService();
            if (!result_apps.error) {
                const applications = result_apps.data;
                setAppDataEx(applications.filter(Boolean)); // Filter out any undefined values
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            // Handle the error
        }
    }

    useEffect(() => {
        fetchCertifyApplications().then((res) => `fetch initiated, ${res}`);
    }, [])

    const viewCertifyApplication = (e, row) => {
        history.push({pathname: '/certify/detail', state: row})
    }

    function getStatusColor(status) {
        const redStatuses = [
            APP_STATUS.ADD_ASSESS_REQ_RECEIVED,
            APP_STATUS.AUTO_ASSESS_GENERATION_COMPLETED,
            APP_STATUS.AUTO_ASSESS_GENERATION_IN_PROGRESS,
            APP_STATUS.AUTO_ASSESS_GENERATION_COMPLETED,
            APP_STATUS.PENDING_AUTO_ASSESS_GENERATION,
            APP_STATUS.PENDING_ASSESS_GENERATED_APPROVE,
            APP_STATUS.PENDING_APPROVE_MODULES,
            APP_STATUS.PENDING_PUBLISH_MODULES
        ];

        if (redStatuses.includes(status)) {
            return 'red';
        } else {
            return 'inherit';
        }
    }

    return (
        <Container maxWidth={false}>
            <Box display={'flex'} justifyContent={'flex-end'} mb={2}>
                <TextField
                    variant="outlined"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search…"
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                onClick={() => setSearchText('')}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Program</TableCell>
                            <TableCell>Submission Time</TableCell>
                            <TableCell>Powered by DQ Package</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {

                            filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) =>
                                <TableRow>
                                    <TableCell>
                                        <Typography>{'O: '}{row.owner_id.slice(-16)}</Typography>
                                        <Typography>{'A: '}{row.application_id.slice(-16)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{row.program_info ? row.program_info.program_name : "-"}</Typography>
                                        <Typography>{`(${row.company_info ? row.company_info.name_org : "-"})`}</Typography>
                                    </TableCell>
                                    <TableCell>{(new Date(row.submission_time)).toLocaleDateString(undefined, date_options)}</TableCell>
                                    <TableCell>{SEAL_TYPE_VERBOSE[row.dqseal_type]}</TableCell>
                                    <TableCell style={{ color: getStatusColor(row.app_status) }}>
                                        {APP_STATUS_VERBOSE[row.app_status]}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" onClick={(e) =>viewCertifyApplication(e, row)}>View</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default ListCertify;
