import {getAnalysisService} from "../../../services/app.service";

async function fetchAnalysis(application_id, type) {
    try {
        const result = await getAnalysisService(application_id, type);
        if(!result.error) {
            return result.data;
        }
    }
    catch (e) {
        console.error(e);
    }
    return {};
}

export default fetchAnalysis;