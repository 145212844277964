import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button} from "@material-ui/core";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";


const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '90%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'scroll'
    },
    headerContainer: {
        // display: 'flex',
        // justifyContent: "space-between"
    },
    button: {
        margin: '15px',
    }
}));

function ImportPreviewModal(props) {

    const { title, open, rows, cols, handleClose, dataJson, postAPI } = props;

    const classes = useStyles();

    const [uploadingData, setUploadingData] = useState(false);

    const dataUploadAction = async () => {

        setUploadingData(true);

        try {
            const result = await postAPI(dataJson);
            handleClose();
        } catch (e) {
            // already toasted the error
        }

        setUploadingData(false);
    }

    const renderTD = (data) => {
        if (typeof data === 'object') {
            if (Array.isArray(data)) {
                let spans = [];
                for (let item of data) {
                    spans.push(<span>{item}</span>)
                }
                return spans;
            } else {
                return data;
            }
        } else if (typeof data === 'boolean') {
            if (data === true) {
                return 'True'
            } else {
                return 'False'
            }
        } else {
            return data;
        }

    }

    return (

        <>
            <LoaderWithBackDrop loading={uploadingData} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>
                        <div className={classes.headerContainer}>
                            <h2 id="transition-modal-title">{title}</h2>
                            <div>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    onClick={ () => dataUploadAction() }
                                >Upload Data</Button>
                            </div>

                        </div>


                        <table className="ExcelTable2007">
                            <tbody>
                            <tr>
                                <th />
                                {
                                    cols.map( col =>  <th>{col.name}</th>)
                                }
                            </tr>
                            {
                                rows.map( (row, index) => <tr>
                                    <td className={'heading'}>{index+1}</td>
                                    {cols.map( (col, ci) =>  <td>{renderTD(row[ci])}</td>)}
                                </tr>)
                            }
                            </tbody>
                        </table>

                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default ImportPreviewModal;