import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PaymentsIcon from '@material-ui/icons/AttachMoney';
import AnalysisIcon from '@material-ui/icons/BarChart';
import CertifyIcon from '@material-ui/icons/CardMembership';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import List from '@material-ui/core/List';
import ExistToAppIcon from '@material-ui/icons/ExitToApp';

import { withRouter } from "react-router";
import {USER_TYPE} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";


const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


const MainList = (props) => {

    const { history, path, logoutAction } = props;
    const { authUser } = useAuth();

    return (
        <List>
            <ListItem selected={path === 'home'} button onClick={()=>history.push('/home')}>
                <ListItemIcon>
                    <DashboardIcon style={{color: 'grey'}}/>
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem selected={path === 'overview'} button onClick={()=>history.push('/overview')}>
                <ListItemIcon>
                    <VisibilityIcon style={{color: 'grey'}}/>
                </ListItemIcon>
                <ListItemText primary="Overview" />
            </ListItem>

            <ListItem selected={path === 'application__list'} button onClick={()=>history.push('/application/list')}>
                <ListItemIcon>
                    <AllInboxIcon style={{color: 'grey'}}/>
                </ListItemIcon>
                <ListItemText primary="Applications" />
            </ListItem>

            <ListItem selected={path === 'analysis__list'} button onClick={()=>history.push('/analysis/list')}>
                <ListItemIcon>
                    <AnalysisIcon style={{color: 'grey'}}/>
                </ListItemIcon>
                <ListItemText primary="Accuracy Analysis" />
            </ListItem>

            <ListItem selected={path === 'certify__list'} button onClick={()=>history.push('/certify/list')}>
                <ListItemIcon>
                    <CertifyIcon style={{color: 'grey'}}/>
                </ListItemIcon>
                <ListItemText primary="DQ Measured" />
            </ListItem>

            {authUser.user_type === USER_TYPE.SUPER_USER && (
            <ListItem selected={path === 'payment__list'} button onClick={()=>history.push('/payment/list')}>
                <ListItemIcon>
                    <PaymentsIcon style={{color: 'grey'}}/>
                </ListItemIcon>
                <ListItemText primary="Payments" />
            </ListItem>
            )}

            <ListItem button onClick={logoutAction}>
                <ListItemIcon>
                    <ExistToAppIcon style={{color: 'grey'}}/>
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>
        </List>
    );
}

export default withRouter(MainList);