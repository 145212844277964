import { useState, useEffect } from 'react';
import {getAppDetailService, getAppService} from "../../../services/app.service";
import {SEAL_TYPE_VERBOSE} from "../../../utils/constants";

export function useAppDetails(application_id, setLoaderState) {

    const [appDetails, setAppDetails] = useState({});

    const loadAppDetails = async () => {
        if(setLoaderState) setLoaderState(true)
        try {
            const result = await getAppDetailService(application_id);
            if (!result.error) {
                let program_info = result.data.program_info;
                const company_info = result.data.company_info;
                const app = await getAppService(application_id);
                if(!app.error) {
                    program_info.dqseal_type = app.data.dqseal_type.toString();
                }

                let details = {programInfo: {}, companyInfo: {}};
                if (program_info) {
                    details.programInfo = programMap(program_info);
                }
                if(company_info) {
                    details.companyInfo = companyMap(company_info);
                }
                setAppDetails(details);
            }
        } catch (e) {
            // already toasted the error
        }
        if(setLoaderState) setLoaderState(false)
    }

    useEffect( ()=> {
        if(application_id) {
            loadAppDetails().then((res) => `app details fetch initiated, ${res}`);
        }
    }, [])

    return appDetails;
}

function programMap(program_info) {
    return {
        ProgramName: program_info.program_name,
        DQSealType: program_info.dqseal_type,
        // ProgramType: program_info.program_type,
        // ProgramTypeOther: program_info.program_type_other,
        ProgramAims: program_info.program_aims,
        ResourcesType:program_info.resources_type,
        ResourcesTypeOther:program_info.resources_type_other,
        ResourcesFormat:program_info.resources_format,
        ResourcesFormatOther: program_info.resources_format_other,
        ProgramTarget: program_info.program_target,
        ProgramTargetOther: program_info.program_target_other,
        ProgramTargetMinAge: program_info.program_target_min_age,
        ProgramTargetMaxAge: program_info.program_target_max_age,
        // TargetAge: program_info.target_age,
        TargetCountry: program_info.target_country,
        Devices:program_info.devices,
        DeviceTypeOther: program_info.devices_other,
        ProgramVenue: program_info.program_venue,
        ProgramVenueOther: program_info.program_venue_other,
        // ProgramDescription: program_info.program_description,
        // ProgramDelivery: program_info.program_delivery,
        ProgramDuration: program_info.program_duration,
        OtherDurationHours: program_info.program_duration_other,
        // RefResource: program_info.ref_resource,
        StartYear: program_info.start_year,
        Status: program_info.status,
        Links: program_info.links,
        ProgramPrice: program_info.program_price,
        Success: program_info.success,
        SuccessMeasure: program_info.success_measure,
        KPITrack: program_info.kpi_track,
        // RefLink: program_info.ref_link,
        RefFramework: program_info.ref_framework,
        RefAlignment: program_info.ref_alignment,
    }
}

function companyMap(company_info) {
    return {
        NameOrg: company_info.name_org,
        NameContact: company_info.name_contact,
        NameDept: company_info.name_dept,
        Region: company_info.region,
        OrgType: company_info.org_type,
        OrganizationEntered:company_info.org_type.includes('other') ? company_info.organization_entered : '',
        Sector: company_info.sector,
        SectorEntered: company_info.sector.includes('other') ? company_info.sector_entered : '',
        History: company_info.history,
        SizeEmployee: company_info.size_employee,
        OrgVision: company_info.org_vision,
    }
}