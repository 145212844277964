import React, {useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {getAnalysisModService, getAnalysisService} from "../../../services/app.service";
import {ANALYSIS_TYPES} from "../../../utils/constants";
import TextField from "@material-ui/core/TextField";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

function Level8CoverageComp(props) {

    const {application_id, reload} = props;

    const [analysis, setAnalysis] = useState({});
    const [loading, setLoading] = useState(false);

    async function fetchAnalysis() {
        try {
            setLoading(true);
            const result = await getAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_8_COVERAGE);
            if(!result.error) {
                setAnalysis(result.data);
            }
            setLoading(false);
        }
        catch (e) {
            console.error(e);
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAnalysis().then();
    }, [reload])

    return <>
        <br/>
        <TextField id='assumption1' variant="outlined" label={'Assumption: Count'} value={analysis.assumption1 || ""} InputProps={{readOnly: true}}/>
        <br/>
        <br/>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Level 9</TableCell>
                        <TableCell>Matching Count</TableCell>
                        <TableCell>Enough</TableCell>
                        <TableCell>MB#</TableCell>
                        <TableCell>Level 8</TableCell>
                        <TableCell>Level 6</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        analysis.data && analysis.data.map((item, index) =>
                           <TableRow>
                               <TableCell>{item.level9}</TableCell>
                               <TableCell>{item.matching}</TableCell>
                               <TableCell>{item.enough ? 'Yes' : 'No'}</TableCell>
                               <TableCell>{item.mb_no}</TableCell>
                               <TableCell>{item.level8}</TableCell>
                               <TableCell>{item.level6}</TableCell>
                           </TableRow>
                        )
                    }
                </TableBody>
            </Table>
            <LoaderWithBackDrop loading={loading}/>
        </TableContainer>

    </>
}

export default Level8CoverageComp;