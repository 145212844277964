import React, {useCallback, useEffect, useMemo, useRef, useState, memo} from 'react';
import {
    Button,
    darken,
} from "@material-ui/core";
import {ANALYSIS_TYPES, APP_STATUS, LM_TYPE} from "../../../utils/constants";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
    getLearningMsgsService,
    getLevelMapsService,
    postLearningMsgsService,
    postAnalysisService,
    listModsService, postLmsCSV_Service
} from "../../../services/app.service";
import {toastError, toastInfo, toastSuccess, toastWarning} from "../../../utils/utils";
import {
    calculateLevel5Analysis,
    calculateLevel6Breadth,
    calculateLevel6Depth, calculateLevel6Enough,
    calculateLevel7Alignment,
    calculateLevel7Coverage,
    calculateLevel8Coverage,
    calculateLevel9Coverage
} from "../../../utils/coverage_calculator";
import MemoLearningMessageTable from "../components/CodeLMTable.comp";
import {downloadLearningMessagesExcel} from "../utils/learningMessagesExcel.util";

import CommentDialog from "../components/MsgCommentDialog.comp";
import LogDialog from "../components/MsgLogDialog.comp";
import {useAuth} from "../../../context/auth";
import AppLMSLogsDownload from "../utils/generateAppLMSLogs.util";
import {ExcelRenderer} from "../../../utils/excel_parser";
import ImportPreviewModal from "../components/ImportPreviewModal.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    excel_button: {
        backgroundColor: "#006400",
        color: "white",
        '&:hover': {
            backgroundColor: darken("#006400", 0.2),
        }
    },
}));

function CodificationModule(props) {

    const classes = useStyles();
    let {application_id, app_status, updateStatus, forwardStep, setLoading} = props;

    const [similarityThreshold, setSimilarityThreshold] = useState(0);
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState(undefined);
    const [learnMsgs, setLearnMsgs] = useState([]);
    const [mappings, setMappings] = useState(undefined);

    const dialogItemIndex = useRef(undefined);
    const learnMsgsRef = useRef([]);
    const allLearnMsgsRef = useRef([]);
    const mappingFileInput = useRef();

    const [cmtDialogOpen, setCmtDialogOpen] = useState(false);
    const [logDialogOpen, setLogDialogOpen] = useState(false);

    const [excelDataJson, setExcelDataJson] = useState([]);
    const [openDataPreview, setOpenDataPreview] = useState(false);
    const [previewCols, setPreviewCols] = useState([]);
    const [previewRows, setPreviewRows] = useState([]);

    const {authUser} = useAuth();

    const handleCmtDialogOpen = (index) => {
        dialogItemIndex.current = index;
        setCmtDialogOpen(true);
    }

    const handleCmtDialogClose = (index, updatedComments) => {
        if(updatedComments) {
            updateMessage(index, {comments: updatedComments}, false);
        }
        dialogItemIndex.current = undefined;
        setCmtDialogOpen(false);
    }

    const handleLogDialogOpen = (index) => {
        dialogItemIndex.current = index;
        setLogDialogOpen(true);
    }

    const handleLogDialogClose = () => {
        dialogItemIndex.current = undefined;
        setLogDialogOpen(false);
    }

    const onChangeModule = async (module_id) => {
        setSelectedModule(module_id);
        fetchLearningMessages(module_id).then(() =>
            console.log(`fetch completed -> learning messages`))
    }

    const statusGreaterThanModule = app_status > APP_STATUS.PENDING_REVIEW_MAPPING;

    async function fetchLevelMappings() {
        try {
            const result = await getLevelMapsService();
            if(!result.error) {
                setMappings(result.data);
            }
        }
        catch (e) {
            console.error(e);
        }
        return true;
    }

    async function fetchModules() {
        let module_id = undefined;
        setLoading(true);
        try {
            const result = await listModsService(application_id);
            if(!result.error) {
                const {all_mods} = result.data;
                setModules(all_mods);
                if(all_mods.length > 0) {
                    const sorted_mods = all_mods.sort((a, b) => a.order_no - b.order_no);
                    module_id = sorted_mods[0].module_id;
                    setSelectedModule(module_id);
                }
            }
        }
        catch (e) {
            console.error(e);
        }
        setLoading(false);
        return module_id;
    }

    async function fetchLearningMessages(module_id) {
        setLoading(true);
        if (allLearnMsgsRef.current.length < 1) {
            try {
                // hotfix: 21feb, prevent module_id param from sending it on api, just load all the messages, and filter on front-end.
                // this change is required, to do analysis later on application level, rather than module level.
                const result = await getLearningMsgsService(application_id);
                if(!result.error) {
                    const {messages = [], threshold} = result.data;
                    console.log('mna:messages -> ', messages);
                    allLearnMsgsRef.current = messages.filter((msg) => msg.type === LM_TYPE.LESSON || msg.type === LM_TYPE.INSTRUMENT);
                    setSimilarityThreshold(Math.round(threshold*100));
                }
            }
            catch (e) {
                console.error(e);
            }
        }

        // load messages of current active module.
        learnMsgsRef.current = allLearnMsgsRef.current
            .filter((msg) => msg.module_id === module_id && (msg.type === LM_TYPE.LESSON || msg.type === LM_TYPE.INSTRUMENT));
        updateLearningMessageState();
        // validate threshold
        // syncMessagesWithThreshold();

        setLoading(false);
    }

    const NAParams = {level_5: "N/A", level_6: "N/A", level_8: "N/A", level_9: "N/A", mb_no: "N/A", dqc_no: "N/A", dq_no: "N/A", category_4: "N/A"}

    function isRowNA(row) {
        return row.level_9 === "N/A" || row.level_8 === "N/A" || row.level_6 === "N/A" || row.level_5 === "N/A"
    }

    function syncMessagesWithThreshold() {
        const rows = learnMsgsRef.current;
        const rowsToBeNA = [];
        for(const index in rows) {
            const row = rows[index];
            const {message_id, updatedBy = "System"} = row;
            const isBelowThreshold = row.level_9_sim < similarityThreshold;
            const isAlreadyNA = isRowNA(row);
            if(isBelowThreshold && !isAlreadyNA) { //level 9 similarity is below than threshold && is not already marked as not applicable
                const updatedParams = {...NAParams, updatedBy};
                updateMessage(index, updatedParams, false, false);
                rowsToBeNA.push({message_id, ...updatedParams})
            }
        }
        updateLearningMessageState();
        if(rowsToBeNA.length > 0) {
            saveMessages(rowsToBeNA).then(() => `Messages saved as NA in bulk`)
        }
    }

    async function saveMessages(messages) {
        try {
            const result = await postLearningMsgsService(application_id, messages);
            if(!result.error && result.data.success) {
                console.log("Messages saved successfully");
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    async function submit() {
        setLoading(true);
        try {
            // hotfix: 21st feb, 2024: do calculation for all application messages, rather than single module.
            await calculateCoverages(allLearnMsgsRef.current.filter((item) => !isRowNA(item) && !item.is_deleted), mappings)
            toastSuccess('Success', 'Mapping submitted')
            updateStatus(APP_STATUS.PENDING_ANALYSIS);
        }
        catch (e) {
            console.error(e);
            toastError('Error', 'Unable to calculate analysis, errors must be corrected first')
        }
        setLoading(false);
    }

    async function calculateCoverages(messages, mappings) {
        const level9_coverage = calculateLevel9Coverage(messages, mappings);
        const level8_coverage = calculateLevel8Coverage(level9_coverage, mappings);
        const level7_coverage = calculateLevel7Coverage(level8_coverage, mappings);
        const level7_alignment = calculateLevel7Alignment(level7_coverage, level8_coverage, mappings);
        const level6_depth = calculateLevel6Depth(level7_alignment, level8_coverage, mappings);
        const level6_breadth = calculateLevel6Breadth(level6_depth, mappings);
        const level6_enough = calculateLevel6Enough(level8_coverage, mappings);
        const level5_analysis = calculateLevel5Analysis(level6_enough, mappings);
        // console.log('selected_module: ', selectedModule);
        try {
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_9_COVERAGE, level9_coverage, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_8_COVERAGE, level8_coverage, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_7_COVERAGE, level7_coverage, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_7_ALIGNMENT, level7_alignment, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_DEPTH, level6_depth, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_BREADTH, level6_breadth, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_ENOUGH, level6_enough, null);
            await postAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_5_ANALYSIS, level5_analysis, null);
        }
        catch (e) {
            console.error(e);
        }
        return true;
    }

    useEffect(() => {
        fetchLevelMappings().then(() => `fetch completed -> level mappings`)
        if(application_id !== undefined) {
            fetchModules().then((module_id) => {
                console.log(`fetch completed -> modules`)
                fetchLearningMessages(module_id).then(() =>
                    console.log(`fetch completed -> learning messages`))
            })
        }
    }, [])

    function updateMessage(index, updatedParams, save = true, updateLmState = true) {
        const currentTime = new Date(Date.now()).toISOString();
        const localParams = {updatedAt: currentTime, updatedBy: authUser.email};
        const messagesCopy = [...learnMsgsRef.current];
        const new_message = {...messagesCopy[index], ...localParams, ...updatedParams};
        messagesCopy.splice(index, 1, new_message);
        learnMsgsRef.current = messagesCopy;

        // Reflect the change in allLearnMsgsRef
        const allMsgIndex = allLearnMsgsRef.current.findIndex(msg => msg.message_id === new_message.message_id);
        if (allMsgIndex !== -1) {
            allLearnMsgsRef.current[allMsgIndex] = {...allLearnMsgsRef.current[allMsgIndex], ...localParams, ...updatedParams};
        }

        if(save) {
            const {message_id} = new_message;
            saveMessages([{message_id, ...updatedParams}]).then((r) => `Message ${message_id}[${index}] saving`);
            toastSuccess('Success', 'Message updated')
        }
        if (updateLmState) {
            updateLearningMessageState();
        }
    }

    function updateLearningMessageState() {
        setLearnMsgs(learnMsgsRef.current);
    }

    const instrument_responses_column_parser = (data) => {
        let str = data.trim();
        let output = []

        let str_arr = str.split('{{');
        if (str_arr.length > 2) {
            for (let i=1; i<str_arr.length; i++) {
                const option = str_arr[i].split('}}')[0];
                if (option.length > 1) {
                    output.push(option);
                }
            }
            if (output.length === 0) {
                return data
            }
        }

        return output;
    }

    const openMappingFileBrowser = () => {
        mappingFileInput.current.click();
    }

    const mappingFileHandler = (event) => {
        if(event.target.files.length){
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;

            setLoading(true);

            //check for file extension and pass only if it is .xlsx and display error message otherwise
            if(['xlsx', 'csv'].includes(fileName.slice(fileName.lastIndexOf('.')+1))){
                renderMappingFile(fileObj)
            }
            else{
                setLoading(false);
                toastWarning('invalid file type! please upload .xlsx/.csv file only');
            }
        }
    }

    const renderMappingFile = (fileObj) => {
        const columns = [
            {name: 'Application ID', key: 0, json_key: 'application_id'},
            {name: 'LM ID', key: 1, json_key: 'lm_id'},
            {name: 'Learning Message', key: 2, json_key: 'learning_message'},
            {name: 'Type', key: 3, json_key: 'type'},
            {name: 'Responses', key: 4, json_key: 'responses', data_parser: instrument_responses_column_parser},
            {name: 'L9 Code', key: 5, json_key: 'l9_code'},
        ]

        ExcelRenderer(fileObj, 'Sheet1', async (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                let rows = [];
                for (let i = 1; i < resp.rows.length; i++) {
                    if (resp.rows[i].length > 0 && resp.rows[i][0] !== '') {
                        let row = [];

                        for (let col of columns) {
                            let colData = '';
                            if (resp.rows[i][col.key]) {
                                if (col.data_parser) {
                                    colData = col.data_parser(resp.rows[i][col.key]);
                                } else {
                                    if (typeof resp.rows[i][col.key] === 'string') {
                                        colData = resp.rows[i][col.key].trim();
                                    } else {
                                        colData = resp.rows[i][col.key];
                                    }
                                }
                            }

                            row.push(colData);
                        }
                        if (row[0] === '') break;
                        rows.push(row);
                    }
                }
                setPreviewCols(columns);
                setPreviewRows(rows);

                // prepare data for json to post of server
                let data = [];
                for (let row of rows) {
                    let row_obj = {};
                    for (const [ci, col] of columns.entries()) {
                        row_obj[col.json_key] = row[ci]
                    }
                    data.push(row_obj);
                }

                let post_data_to_api = true;
                let wrong_l9_code_toast_time = 6000;
                let record_no = 1;
                for (let item of data) {
                    // Check application_id consistency
                    if (item.application_id !== application_id) {
                        toastError('Bad file format', 'File contains wrong application_id' +
                            '\nPlease make sure that you are uploading records for the current application,' +
                            '\nand also all the records are for the same application');
                        post_data_to_api = false;
                        break; // If application_id is wrong, we stop further processing.
                    }
                    // Validate L9 codes
                    if (!mappings['level9_mappings'][item.l9_code] && item.type !== 'NONLM') {
                        toastWarning('Wrong L9 Code', `At record: ${record_no}, found invalid l9-code: ${item.l9_code}`, wrong_l9_code_toast_time);
                        wrong_l9_code_toast_time += 1500;
                        item.l9_code = 'N/A';
                    }
                    // Validate and translate LM types
                    if (!LM_TYPE[item.type] && item.type !== 'NONLM') {
                        toastError('Wrong LM Type', `At record: ${record_no}, found invalid lm-type: ${item.type}`, wrong_l9_code_toast_time);
                        post_data_to_api = false;
                        wrong_l9_code_toast_time += 1500;
                    } else {
                        if (item.type !== 'NONLM') item.type = LM_TYPE[item.type];
                    }
                    record_no += 1;
                }

                // post this data to backend server
                if (post_data_to_api) {
                    setExcelDataJson(data);
                    setOpenDataPreview(true);
                }

                setLoading(false);
            }
        }).then(rs=>console.log('finished processing excel file ==> rs => ', rs));
    }

    const postMappingExcelAPI = async () => {
        try {
            const result = await postLmsCSV_Service(application_id, selectedModule, excelDataJson);
            if (!result.error && result.data.success) {
                console.log("posted csv successfully");
                console.log('result.data => ', result.data);


                toastSuccess('Excel/CSV Upload', 'Successfully processed the records!' +
                    '\nrefresh the page in 3, 4 seconds to see updated learning messages', 10000);
                if (result.data.mappings_stats && result.data.mappings_stats.total > 0) {
                    toastSuccess('Uploaded File'
                        , `Total records: ${result.data.mappings_stats.total}`
                        , 10000);
                }
                if (result.data.mappings_stats && result.data.mappings_stats.updated > 0) {
                    toastInfo('Uploaded File'
                        , `Updated records: ${result.data.mappings_stats.updated}`
                        , 12000);
                }
                if (result.data.mappings_stats && result.data.mappings_stats.created > 0) {
                    toastInfo('Uploaded File'
                        , `Created records: ${result.data.mappings_stats.created}`
                        , 12000);
                }
                if (result.data.mappings_stats && result.data.mappings_stats.skipped > 0) {
                    toastWarning('Uploaded File'
                        , `Skipped records: ${result.data.mappings_stats.skipped}`
                        , 15000);
                }
                if (result.data.mappings_stats && result.data.mappings_stats.failed > 0) {
                    toastError('Uploaded File'
                        , `Failed to process records: ${result.data.mappings_stats.failed}`
                        , 15000);
                }
            }
        } catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    //memos
    const memoLearnMsg = useMemo(() => learnMsgs, [learnMsgs])
    const memoModules = useMemo(() => modules, [modules])
    const memoMappings = useMemo(() => mappings, [mappings]);
    const memoUpdateCallback = useCallback(updateMessage, []);
    const memoOpenCommentsCallback = useCallback(handleCmtDialogOpen, []);
    const memoOpenLogsCallback = useCallback(handleLogDialogOpen, []);
    const memoChangeModuleCallback = useCallback(onChangeModule, []);

    return (
        <>
            {
                learnMsgs && mappings ?
                    <MemoLearningMessageTable
                        modules={memoModules}
                        messages={memoLearnMsg}
                        selectedModule={selectedModule}
                        mappings={memoMappings}
                        threshold={similarityThreshold}
                        updateMessage={memoUpdateCallback}
                        openComments={memoOpenCommentsCallback}
                        openLogs={memoOpenLogsCallback}
                        changeModule={memoChangeModuleCallback}
                    />
                :
                    null
            }
            <Box mt={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                {!statusGreaterThanModule && (
                    <Button variant='contained' color="primary" onClick={submit}>Submit</Button>
                )}
                {statusGreaterThanModule && (
                    <Button variant='contained' color="primary" onClick={submit}>Re-Calculate Analysis</Button>
                )}
                {statusGreaterThanModule && (
                    <Button variant='contained' color="secondary" onClick={() => forwardStep()}>Next: Analysis</Button>
                )}

                <AppLMSLogsDownload application_id={application_id} disable_flg={statusGreaterThanModule}/>

                <Button variant='contained' color="primary" onClick={openMappingFileBrowser}>Upload
                    Mapping</Button>
                <input type="file" hidden onChange={mappingFileHandler} ref={mappingFileInput} onClick={(event) => {
                    event.target.value = null
                }} style={{"padding": "10px"}}/>
            </Box>
            <CommentDialog
                applicationID={application_id}
                messages={learnMsgsRef.current} messageIndex={dialogItemIndex.current}
                open={cmtDialogOpen} onClose={handleCmtDialogClose}
            />
            <LogDialog
                applicationID={application_id}
                messages={learnMsgsRef.current} messageIndex={dialogItemIndex.current}
                open={logDialogOpen} onClose={handleLogDialogClose}
            />

            <ImportPreviewModal
                open={openDataPreview}
                rows={previewRows}
                cols={previewCols}
                handleClose={ ()=> setOpenDataPreview(false) }
                dataJson={excelDataJson}
                title={'preview | mapping upload'}
                postAPI={postMappingExcelAPI}
            />

        </>
    );
}

export default CodificationModule;