import React from 'react';
import Aligned from "../../../../assets/images/report/aligned.png";
import Box from "@material-ui/core/Box";
import { Typography, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import DQ1 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ1.png";
import DQ2 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ2.png";
import DQ3 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ3.png";
import DQ4 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ4.png";
import DQ5 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ5.png";
import DQ6 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ6.png";
import DQ7 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ7.png";
import DQ8 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ8.png";
import DQ9 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ9.png";
import DQ10 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ10.png";
import DQ11 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ11.png";
import DQ12 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ12.png";
import DQ13 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ13.png";
import DQ14 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ14.png";
import DQ15 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ15.png";
import DQ16 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ16.png";
import DQ17 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ17.png";
import DQ18 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ18.png";
import DQ19 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ19.png";
import DQ20 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ20.png";
import DQ21 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ21.png";
import DQ22 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ22.png";
import DQ23 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ23.png";
import DQ24 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ24.png";
import DQ25 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ25.png";
import DQ26 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ26.png";
import DQ27 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ27.png";
import DQ28 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ28.png";
import DQ29 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ29.png";
import DQ30 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ30.png";
import DQ31 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ31.png";
import DQ32 from "../../../../assets/images/report/DQCommpetenciesIcons/DQ32.png";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {PROGRAM_VENUE_MAP} from "../../../../utils/META/cat_111";
import {PROGRAM_TARGETS_MAP} from "../../../../utils/META/cat_108";

const ICONS_MAP = {
    DQ1: DQ1,
    DQ2: DQ2,
    DQ3: DQ3,
    DQ4: DQ4,
    DQ5: DQ5,
    DQ6: DQ6,
    DQ7: DQ7,
    DQ8: DQ8,
    DQ9: DQ9,
    DQ10: DQ10,
    DQ11: DQ11,
    DQ12: DQ12,
    DQ13: DQ13,
    DQ14: DQ14,
    DQ15: DQ15,
    DQ16: DQ16,
    DQ17: DQ17,
    DQ18: DQ18,
    DQ19: DQ19,
    DQ20: DQ20,
    DQ21: DQ21,
    DQ22: DQ22,
    DQ23: DQ23,
    DQ24: DQ24,
    DQ25: DQ25,
    DQ26: DQ26,
    DQ27: DQ27,
    DQ28: DQ28,
    DQ29: DQ29,
    DQ30: DQ30,
    DQ31: DQ31,
    DQ32: DQ32,
};

function OrgDetailRow(props) {
    const { title, value, values, isHeader } = props;

    if (isHeader) {
        return (
            <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px', backgroundColor: 'deepskyblue' }}>
                    {title}
                </TableCell>
            </TableRow>
        );
    } else {
        return (
            <TableRow>
                <TableCell style={{ border: '1px solid black' }}>
                    <Typography variant={'h4'} style={{ color: "deepskyblue", fontSize: "20px", fontWeight: 'bold', fontFamily: "Source Sans Pro", textAlign: "right" }}>
                        {title}
                    </Typography>
                </TableCell>
                <TableCell style={{ verticalAlign: "text-top", border: '1px solid black' }}>
                    {
                        values ?
                            <td style={{ verticalAlign: "text-top" }}>
                                <ul style={{ paddingLeft: 12 }}>
                                    {
                                        values.map((val) =>
                                            <li style={{ textAlign: "left" }}>
                                                <Typography style={{ color: "orangered", fontWeight: "bold", fontSize: "18px", fontFamily: "Source Sans Pro", textAlign: "left" }}>
                                                    {val}
                                                </Typography>
                                            </li>
                                        )
                                    }
                                </ul>
                            </td>
                            :
                            <Typography style={{ color: "black", fontSize: "18px", fontFamily: "Source Sans Pro", textAlign: "left" }}>
                                {value}
                            </Typography>
                    }
                </TableCell>
            </TableRow>
        );
    }
}

export default function Page12(props) {

    const { DQ_DEFINITIONS, pageNumber, programInfo, breadth, onlyTable = false } = props;

    function getDQ(dqc_no) {
        const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
        const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
        const num = isNaN(dq_num) ? dqc_no : dq_num;
        const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
        const color = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].color : "#444746"
        const icon = ICONS_MAP[code] || null;
        return { code, title, num, color, icon };
    }

    const aligned_dqc = breadth.data ?
        breadth.data.map((item) => {
            const { dqc_no } = item;
            return getDQ(dqc_no);
        })
        : [];

    const BadgeCard = ({ code, title, color, icon }) => (
        <div style={{
            backgroundColor: color,
            padding: '10px',
            margin: '18px 5px',
            borderRadius: '25px',
            textAlign: 'center',
            width: '23%',
            minWidth: '148px',
            height: '230px',
            color: 'white',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <h4 style={{ fontWeight: 'bold', padding: '15px 0px' }}>{code}</h4>
            <p>{title}</p>
            {icon && <img src={icon} alt={`${code} icon`} style={{ height: '48px', marginTop:"auto",marginBottom:'15px' }} />}
        </div>
    );

    return <ReportPage pageNumber={pageNumber}>
        <Box id="2.4-aligned-dq-competencies" display={'flex'} flexDirection={'column'} alignItems={'flex-start'}
             justifyContent={"flex-start"} height={"inherit"} px={10}>
            {!onlyTable &&
                <Box>
                    <Typography
                        variant={'h3'}
                        style={{
                            color: "orangered",
                            fontWeight: "lighter",
                            fontFamily: 'Raleway',
                            paddingTop: '70px',
                            fontSize: '22px'
                        }}
                    >
                        2.4 Aligned DQ Competencies
                    </Typography>
                </Box>
            }
            {aligned_dqc.length === 0 ? (
                <Typography
                    variant={'h5'}
                    style={{
                        color: "black",
                        fontFamily: 'Raleway',
                        paddingTop: '20px',
                        fontSize: '18px'
                    }}>
                    The program is not aligned with at least one DQ Competency
                </Typography>
            ) : (
                <Box display="flex" flexWrap="wrap" justifyContent="flex-start" style={{margin:'35px 0px'}}>
                    {aligned_dqc.map((dq, index) => (
                        <BadgeCard key={index} code={dq.code} title={dq.title} color={dq.color} icon={dq.icon} />
                    ))}
                </Box>
            )}
        </Box>
    </ReportPage>
}