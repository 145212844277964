import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AuthContext } from "./context/auth";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

// more feature rich npm package for excel parsing -> https://www.npmjs.com/package/xlsx

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Routes from './Routes';
import {LOCAL_STORAGE_KEYS} from "./utils/constants";

const browserHistory = createBrowserHistory();

function App(props) {

    let existingToken, existingUser;
    try {
        existingToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        existingUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_user));
    } catch (e) {
        console.log('error in fetching user from localstorage: ', e)
        localStorage.clear();
    }


    const [authToken, setAuthToken] = useState(existingToken);
    const [authUser, setAuthUser] = useState(existingUser);

    const setToken = (data) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.auth_token, JSON.stringify(data));
        setAuthToken(data);
    }

    const setUser = (data) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.auth_user, JSON.stringify(data));
        setAuthUser(data);
    }

    return (
        <AuthContext.Provider value={{
            authToken,
            setAuthToken: setToken,
            authUser,
            setAuthUser: setUser
        }}>
            <Router history={browserHistory}>
                <ThemeProvider theme={theme}>
                    <div className="app-container">
                        <ReactNotification />
                        <Routes />
                    </div>
                </ThemeProvider>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;
