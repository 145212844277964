import {writeFile, utils} from "xlsx";
import {ANALYSIS_TYPES, ANALYSIS_TYPES_VERBOSE} from "../../../utils/constants";
import createSheet from "./createSheet.util";
import fetchAnalysis from "./fetchAnalysis.util";
import {getAppService} from "../../../services/app.service";

export async function downloadLearningMessagesExcel(application_id, data) {
    const workbook = utils.book_new();
    const mappings_sheet = createMappingsSheet(data);
    const level9_sheet = await createLevel9CoverageSheet(application_id);
    const level8_sheet = await createLevel8CoverageSheet(application_id);
    const level7_cover_sheet = await createLevel7CoverageSheet(application_id);
    const level7_align_sheet = await createLevel7AlignmentSheet(application_id);
    const level6_depth_sheet = await createLevel6DepthSheet(application_id);
    const level6_bread_sheet = await createLevel6BreadthSheet(application_id);
    const level6_enough_sheet = await createLevel6EnoughSheet(application_id);
    const level5_analysis_sheet = await createLevel5AnalysisSheet(application_id);

    utils.book_append_sheet(workbook, mappings_sheet, "Mappings");
    utils.book_append_sheet(workbook, level9_sheet, ANALYSIS_TYPES_VERBOSE.LEVEL_9_COVERAGE);
    utils.book_append_sheet(workbook, level8_sheet, ANALYSIS_TYPES_VERBOSE.LEVEL_8_COVERAGE);
    utils.book_append_sheet(workbook, level7_cover_sheet, ANALYSIS_TYPES_VERBOSE.LEVEL_7_COVERAGE);
    utils.book_append_sheet(workbook, level7_align_sheet, ANALYSIS_TYPES_VERBOSE.LEVEL_7_ALIGNMENT);
    utils.book_append_sheet(workbook, level6_depth_sheet, ANALYSIS_TYPES_VERBOSE.LEVEL_6_DEPTH);
    utils.book_append_sheet(workbook, level6_bread_sheet, ANALYSIS_TYPES_VERBOSE.LEVEL_6_BREADTH);
    utils.book_append_sheet(workbook, level6_enough_sheet, ANALYSIS_TYPES_VERBOSE.LEVEL_6_ENOUGH);
    utils.book_append_sheet(workbook, level5_analysis_sheet, ANALYSIS_TYPES_VERBOSE.LEVEL_5_ANALYSIS);

    writeFile(workbook, `DQ Seal.xlsx`);
}

function createMappingsSheet(data) {
    const headers = ["Learning Message", "Level 5", "Level 6", "Level 8", "Level 9", "MB#", "DQC#", "DQ#", "Category 4"]
    const fields = ["message", "level_5", "level_6", "level_8", "level_9", "mb_no", "dqc_no", "dq_no", "category_4"]
    return createSheet(headers, fields, data);
}

async function createAnalysisSheet(application_id, type, headers, fields, assumption_headers = [], boolean_map) {
    const analysis = await fetchAnalysis(application_id, type);
    const prefix_array = [];
    const data = analysis.data;
    if(analysis.grand_total) {
        data.push(analysis.grand_total);
    }
    if(analysis.assumption1 !== undefined) {
        const header = assumption_headers[0] || "Assumption 1";
        const value = header.includes("%") ? `${analysis.assumption1}%` : analysis.assumption1;
        prefix_array.push([header, value]);
    }
    if(analysis.assumption2 !== undefined) {
        const header = assumption_headers[1] || "Assumption 2";
        const value = header.includes("%") ? `${analysis.assumption2}%` : analysis.assumption2;
        prefix_array.push([header, value]);
    }
    return createSheet(headers, fields, data, prefix_array, boolean_map);
}

async function createLevel9CoverageSheet(application_id) {
    const type = ANALYSIS_TYPES.LEVEL_9_COVERAGE;
    const headers = ["Level 9", "Count"]
    const fields = ["level9", "count"];
    return await createAnalysisSheet(application_id, type, headers, fields);
}

async function createLevel8CoverageSheet(application_id) {
    const type = ANALYSIS_TYPES.LEVEL_8_COVERAGE;
    const headers = ["Level 9", "Matching Count", "Enough", "MB#", "Level 8", "Level 6"]
    const fields = ["level9", "matching", "enough", "mb_no", "level8", "level6"];
    const assumption_headers = ["Assumption: Count"];
    return await createAnalysisSheet(application_id, type, headers, fields, assumption_headers);
}

async function createLevel7CoverageSheet(application_id) {
    const type = ANALYSIS_TYPES.LEVEL_7_COVERAGE;
    const headers = ["Level 8", "Sum of Count", "How many Level 9 within Level 8", "Level 8 Coverage", "Level 8 Covered or Not"];
    const fields = ["level8", "count", "total", "coverage", "is_covered"];
    const assumption_headers = ["Minimum Coverage %", "Minimum Count"]
    return await createAnalysisSheet(application_id, type, headers, fields, assumption_headers);
}

async function createLevel7AlignmentSheet(application_id) {
    const type = ANALYSIS_TYPES.LEVEL_7_ALIGNMENT;
    const headers = ["Level 8", "Aligned", "Level 8 Full Name", "Level 6", "Level 6 Full Name", "MB#", "DQC#"];
    const fields = ["level8", "aligned", "level8_full_name", "level6", "level6_full_name", "mb_no", "dqc_no"];
    return await createAnalysisSheet(application_id, type, headers, fields);
}

async function createLevel6DepthSheet(application_id) {
    const type = ANALYSIS_TYPES.LEVEL_6_DEPTH;
    const headers = ["MB#", "Sum of Count", "# of Level8 in Level6", "Depth % of Learning Message", "Covered or not", "Count of Message", "Enough or not", "Pass or not", "DQC#"];
    const fields = ["mb_no", "level8_count", "level8_total", "depth", "is_covered", "level9_count", "enough", "pass", "dqc_no"];
    const assumption_headers = ["Level6 Depth %", "# of Messages"]
    return await createAnalysisSheet(application_id, type, headers, fields, assumption_headers);
}

async function createLevel6BreadthSheet(application_id) {
    const type = ANALYSIS_TYPES.LEVEL_6_BREADTH;
    const headers = ["DQC#", "Count", "Total # of MB within Level 5", "Breadth %", "Aligned or not"];
    const fields = ["dqc_no", "count", "total", "breadth", "aligned"];
    const assumption_headers = ["Level 5 Coverage by (%)", "# of MB within Level 5"]
    return await createAnalysisSheet(application_id, type, headers, fields, assumption_headers);
}

async function createLevel6EnoughSheet(application_id) {
    const type = ANALYSIS_TYPES.LEVEL_6_ENOUGH;
    const headers = ["DQC#", "Count of Message", "Enough"];
    const fields = ["dqc_no", "count", "enough"];
    const assumption_headers = ["# of Messages"]
    return await createAnalysisSheet(application_id, type, headers, fields, assumption_headers);
}

async function createLevel5AnalysisSheet(application_id) {
    const type = ANALYSIS_TYPES.LEVEL_5_ANALYSIS;
    const headers = ["DQC#", "Count of Message", "Percentage", "DQC# Percentage", "Discount Percentage", "Discount Amount"];
    const fields = ["dqc_no", "count", "percent", "dqc_percent", "discount_percent", "discount_amount"];
    const assumption_headers = ["Discount rate %"]
    return await createAnalysisSheet(application_id, type, headers, fields, assumption_headers);
}


