import React, {useEffect, useState} from 'react';
import {approveCertifyAppsService, getAppService} from "../../../services/app.service";
import {Button, Container, FormControl, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {toastSuccess} from "../../../utils/utils";
import TextField from "@material-ui/core/TextField";
import {Field, Form, Formik, useFormikContext} from "formik";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {makeStyles} from "@material-ui/core/styles";
import {APP_STATUS, ASSESSMENT_REQ_TYPES_VERBOSE} from "../../../utils/constants";
import {REGIONS} from "../../../utils/META/cat_101";
import {Autocomplete} from "@material-ui/lab";
import {getOrgCallbacks} from "../../../services/certify.service";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
    section: {
        marginBottom: theme.spacing(4),
        textAlign: 'left'
    },
}));

const FormTextField = ({ label, name, required = true, ...rest}) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required={required}
        margin="normal"
        variant="outlined"
        {...rest}
    />
);

const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);

const API_FLAG = [
    {
        "value": 1,
        "label": "YES"
    },
    {
        "value": 0,
        "label": "NO"
    },
];

const ORG_TYPE = [
    {
        'label': 'PARTNER',
        'value': 1,
    },
    // {
    //     'label': 'COMPANY',
    //     'value': 2,
    // },
    // {
    //     'label': 'SCHOOL',
    //     'value': 3,
    // },
];

const FormComponent = ({disableSubmit, forwardStep}) => {
    const classes = useStyles();
    const { values } = useFormikContext();  // get current form values
    const countriesInitialValues = values.name_of_ountries
        ? values.name_of_ountries.split(', ').map(value => Object.values(REGIONS).find(option => option.value === value)).filter(Boolean)
        : [];

    const showConfigAPI = values.enable_api === 1;

    return (
        <Form>
            <div className={classes.section}>
                <FormTextField label="Type of Assessment" name="assessment_type"/>
                <FormTextField label="Number of Licenses" name="licenses_count" type="number" />
                {/*{values.online_system === 'yes' &&*/}
                {/*    <FormTextField label="Any system/platform to integrate assessment?" name="online_platform_desc" />*/}
                {/*}*/}
                {values.requirements === 'yes' &&
                    <FormTextField label="Any requirement/metric to include in assessment?" name="requirement_desc" />
                }
                <FormControl fullWidth variant="outlined" margin="normal">
                    <Autocomplete
                        multiple
                        fullWidth={true}
                        value={countriesInitialValues}
                        name="name_of_ountries"
                        options={Object.values(REGIONS)}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        disableCloseOnSelect
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth={true}
                                variant="outlined"
                                label="Which countries are you intending to roll the assessment to? (Select all that apply)"
                                InputLabelProps={ { required: true }}
                            />
                        )}
                    />
                </FormControl>

                <FormSelect label="Enable API" name="enable_api" options={API_FLAG} />

                {showConfigAPI && (
                    <>
                        <FormTextField label="Callback URL" name="callback_url" />
                        <FormTextField label="Callback Key" name="callback_key" />
                    </>
                )}

                <FormSelect label="Organization Type" name="org_type" options={ORG_TYPE} />
            </div>
            <Box mt={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={disableSubmit}>
                    Approve
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!disableSubmit}
                    onClick={() => forwardStep()}
                >
                    Next
                </Button>
            </Box>
        </Form>
    );
};

function ApproveModule(props) {
    const {application_id, app_status, app, forwardStep, updateStatus, updateApp} = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [enableApi, setEnableApi] = useState();
    const [callbackUrl, setCallbackUrl] = useState('');
    const [callbackKey, setCallbackKey] = useState('');
    const [formKey, setFormKey] = useState(Date.now());
    const [initialValues, setInitialValues] = useState({
        assessment_type: ASSESSMENT_REQ_TYPES_VERBOSE[app.assessment_req_info.assess_type],
        licenses_count: app.assessment_req_info && app.assessment_req_info.expected_users ? app.assessment_req_info.expected_users : 0,
        online_system: app.assessment_req_info && app.assessment_req_info.client_own_platform_info ? app.assessment_req_info.client_own_platform_info : 'no',
        // online_platform_desc: app.assessment_req_info && app.assessment_req_info.client_own_platform_info_desc ? app.assessment_req_info.client_own_platform_info_desc : '',
        requirements: app.assessment_req_info && app.assessment_req_info.client_requirements_info ? app.assessment_req_info.client_requirements_info : 'no',
        requirement_desc: app.assessment_req_info && app.assessment_req_info.client_requirements_info_desc ? app.assessment_req_info.client_requirements_info_desc : '',
        name_of_ountries: app.assessment_req_info && app.assessment_req_info.intended_name_of_countries ? app.assessment_req_info.intended_name_of_countries : '',
        enable_api: enableApi ? enableApi : app.assessment_req_info.client_own_platform_info === 'yes' ? 1 : 0,
        org_type: app.assessments_approve_info && app.assessments_approve_info.org_type ? app.assessments_approve_info.org_type : 1,
        callback_url: callbackUrl ? callbackUrl : app.assessment_req_info.client_own_callback_url,
        callback_key: callbackKey ? callbackKey : app.assessment_req_info.client_own_callback_key,
    });

    useEffect(() => {
        console.log('app.assessments_approve_info: ', app.assessments_approve_info);
        if (app.assessments_approve_info && app.assessments_approve_info.enable_api) {
            setEnableApi(app.assessments_approve_info.enable_api);
        } else {
            const fetchUpdatedApplicationInfo = async () => {
                try {
                    setLoading(true);
                    const result = await getAppService(application_id);
                    if (result && !result.error) {
                        setEnableApi(result.data.assessments_approve_info.enable_api);
                        updateApp(result.data);
                    } else {
                        console.log('Error fetching updated app data');
                    }
                } catch (error) {
                    console.error('Error fetching app data:', error);
                } finally {
                    setLoading(false);
                }
            };
            fetchUpdatedApplicationInfo().then(r => console.log('Updated App data fetched'));
        }
    }, []);

    useEffect(() => {
        const fetchCallbackData = async () => {
            if(app_status > APP_STATUS.ADD_ASSESS_REQ_RECEIVED) {
                try {
                    setLoading(true);
                    const result = await getOrgCallbacks(application_id);

                    if (result && !result.error) {
                        setInitialValues(prevValues => ({
                            ...prevValues,
                            enable_api: app.assessments_approve_info && app.assessments_approve_info.enable_api ? 1 : 0,
                            callback_url: result.data.callback_url || 'NA',
                            callback_key: result.data.callback_key || 'NA'
                        }));
                        setEnableApi(app.assessments_approve_info && app.assessments_approve_info.enable_api ? 1 : 0);
                        setCallbackKey(result.data.callback_key);
                        setCallbackUrl(result.data.callback_url);
                        setFormKey(Date.now());
                    } else {
                        console.log('No callback data exists or error fetching data');
                    }
                } catch (error) {
                    console.error('Error fetching callback data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchCallbackData().then(r => console.log('Callbacks data fetched'));
    }, [application_id, app]);

    async function approveAssessment(formValues) {
        try {
            setLoading(true);
            setDisableSubmit(true);

            const payload = {
                licenses_count: formValues.licenses_count,
                org_type: formValues.org_type,
                enable_api: formValues.enable_api,
                callback_url: formValues.callback_url ? formValues.callback_url : 'NA',
                callback_key: formValues.callback_key ? formValues.callback_key : 'NA'
            };

            const result = await approveCertifyAppsService(application_id, payload);
            if(!result.error && result.data.success) {
                toastSuccess('Success', 'Assessment Approved');
                updateStatus(APP_STATUS.AUTO_ASSESS_GENERATION_COMPLETED);
                setEnableApi(formValues.enable_ap);
                setCallbackKey(formValues.callback_key ? formValues.callback_key : 'NA');
                setCallbackUrl(formValues.callback_url ? formValues.callback_url : 'NA');
            }
        }
        catch (e) {
            console.error(e);
            setLoading(false);
            setDisableSubmit(false);
        }
        setLoading(false);
    }

    return <>
        {/*<Button*/}
        {/*    style={{float:'right', padding:'10px 40px'}}*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    >*/}
        {/*    Edit*/}
        {/*</Button>*/}
        <Box mt={2} align={'center'}>
            <Container className={classes.container}>
                <Formik key={formKey} onSubmit={approveAssessment} initialValues={initialValues}>
                    <FormComponent
                        disableSubmit={disableSubmit || app_status > APP_STATUS.ADD_ASSESS_REQ_RECEIVED}
                        forwardStep={forwardStep}
                    />
                </Formik>
            </Container>
            <LoaderWithBackDrop loading={loading}/>
        </Box>
    </>
}

export default ApproveModule;