import {utils} from "xlsx";

function createSheet(headers, fields, rows, prefix_array = [], boolean_map = ["Yes", "No"]) {
    const column_widths = [];
    const sheet_array = [];
    if(prefix_array.length > 0) { //include first
        sheet_array.push(...prefix_array, []);
    }
    sheet_array.push(headers, []);
    for(const row of rows) {
        const row_array = [];
        for(const field of fields) {
            let value = row[field];
            //handling booleans
            if(typeof value === "boolean") {
                if(value) value = boolean_map[0];
                else value = boolean_map[1];
            }
            //null safety
            const null_safe_value = !value && value !== 0 ? "" : value;
            row_array.push(null_safe_value);
        }
        sheet_array.push(row_array);
    }

    //column formatting
    headers.forEach(((item, index) => column_widths[index] =  Math.max(item.toString().length, 30)))
    //worksheet
    const worksheet = utils.aoa_to_sheet(sheet_array);
    worksheet["!cols"] = new Array(column_widths.length).fill(null).map((_, index) => {
        return { wch: column_widths[index], wrapText: true }
    });
    return worksheet;
}

export default createSheet;