import React from 'react';
import { Dialog, DialogTitle, DialogContent, Link, Button, Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'block',
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
    button: {
        marginRight: theme.spacing(2),
    },
    dialogContent: {
        minWidth: '450px',
        padding: '25px'
    },
}));

function PaymentVerificationModal({ open, onClose, paymentProofLink, acceptAction, rejectAction }) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Payment Verification</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Link href={paymentProofLink} target="_blank" rel="noopener noreferrer" className={classes.link}>
                    View Payment Proof
                </Link>
                <Box display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => { acceptAction() }}>
                        Approve
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => { rejectAction() }}>
                        Reject
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default PaymentVerificationModal;

