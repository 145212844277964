import React, {useEffect, useRef, useState} from "react";
import {
    Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
    Box, IconButton, Typography, TextField, CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {getAppLogsService} from "../../../services/app.service";

const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true};

function AppLogDialog(props) {

    const {open, onClose, applicationID} = props;

    const dialogRef = useRef(null);
    const [logs, setLogs] = useState(undefined);

    function handleDialogClose() {
        if(onClose){
            onClose();
        }
        setLogs(undefined);
    }

    async function load() {
        try {
            const result = await getAppLogsService(applicationID)
            if(result.data) {
                setLogs(result.data.reverse());
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    function onDialogOpen() {
        load().then(r => console.log("Logs loaded", r));
    }

    // useEffect(() => {
    //
    // }, [logs])

    return <Dialog ref={dialogRef} id={"logs-dialog"} open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'} scroll={"paper"}
                   TransitionProps={{onEntering: onDialogOpen}}>
        <DialogTitle id="form-dialog-title">
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box>History</Box>
                <IconButton onClick={() => handleDialogClose()}>
                    <CloseIcon/>
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
            {
                logs ?
                    logs.length > 0 ?
                        logs.map((item, idx) => <>
                            <Card key={`log_${idx}`} className={"log"}>
                                <CardContent style={{backgroundColor: "#e0e0e0"}}>
                                    <Typography variant={'h6'} color={"textPrimary"}>
                                        {item.user}
                                        <Typography style={{paddingLeft: item.user ? 5 : 0}} variant={'body1'} component={'span'}>
                                            ({new Date(item.time).toLocaleString(undefined, date_options)})
                                        </Typography>
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        {item.log}
                                    </Typography>
                                </CardContent>
                            </Card>
                            <br/>
                        </>)
                        : <Typography color={"secondary"} align={'center'}>No history</Typography>
                :
                    <Box textAlign={'center'}>
                        <CircularProgress/>
                    </Box>
            }
        </DialogContent>
    </Dialog>
}

export default AppLogDialog;