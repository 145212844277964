import React, {useEffect, useState} from 'react';
import {
    listAssessInstruments, listAssessTotalInstruments,
    updateAppStatusService,
    updateAssessInstruments, updateAssessModules
} from "../../../services/app.service";
import {Button, MenuItem, TableContainer} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {APP_STATUS} from "../../../utils/constants";
import {toastSuccess} from "../../../utils/utils";
import TextField from "@material-ui/core/TextField";
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    scrollableTable: {
        maxHeight: '400px',
        overflowY: 'auto',
    },
    fullWidthButton: {
        flex: 2,
    }
}));
function ReviewAssessmentsModule(props) {
    const {app, application_id, app_status, updateStatus, forwardStep, setLoading} = props;

    const classes = useStyles();

    const [appModuleData, setAPPModuleData] = useState([]);
    const [orgModuleData, setORGModuleData] = useState([]);
    const [orgInstrumentsData, setORGInstrumentsData] = useState([]);
    const [alignedDQC, setAlignedDQC] = useState([]);
    const [totalInstruments, setTotalInstrumentsData] = useState([]);

    const [selectedModule, setSelectedModule] = useState('All Modules');
    const [selectedDQC, setSelectedDQC] = useState('All DQCs');

    const [currentPage, setCurrentPage] = useState(0); // Pagination starts from page 0
    const [rowsPerPage, setRowsPerPage] = useState(25); // Default rows per page

    const [openModal, setOpenModal] = useState(false);

    const [editedRow, setEditedRow] = useState(null);  // { id: null, order_no: null }
    const [disableSubmit, setDisableSubmit] = useState(false);



    useEffect(() => {
        retrieveAssessInstruments().then(

        );
    }, [])

    async function fetchTotalInstruments() {
        try {
            const result = await listAssessTotalInstruments(application_id);
            if (!result.error && result.data) {
                console.log('listAssessTotalInstruments: ', result);
                setTotalInstrumentsData(result.data.total_instruments);
            }
        } catch (e) {
            console.error('Error fetching total instruments:', e);
        }
    }

    async function retrieveAssessInstruments() {
        setLoading(true);
        try {
            const result = await listAssessInstruments(application_id);
            console.log('listAssessInstruments: ', result);
            if(!result.error && result.data) {
                const { app_modules, org_modules, org_instruments, aligned_dqc } = result.data;

                setAPPModuleData(app_modules || [])
                setORGModuleData(org_modules || []);
                setORGInstrumentsData(org_instruments || []);
                setAlignedDQC(aligned_dqc.sort() || []);
            }
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false);

        await fetchTotalInstruments();
    }

    const onChangeModule = async (module_id) => {
        if(module_id === "All Modules") {
            setSelectedModule(module_id);
        } else {
            setSelectedModule(module_id);

            // const filteredInstruments = orgInstrumentsData.filter(item => item.module_id === module_id);
            // const alignedDQC = [...new Set(filteredInstruments.map(item => item.dqc_no))];
            //
            // setAlignedDQC(alignedDQC.sort());
        }
    }
    const onChangeDQC = async (aligned_dqc) => {
        if (aligned_dqc === "All DQCs") {
            setSelectedDQC('All DQCs');
        } else {
            setSelectedDQC(aligned_dqc);
        }
    }

    const getFilteredORGInstruments = () => {
        let filteredData = [...orgInstrumentsData];

        // Filtering based on selected values
        if (selectedModule !== 'All Modules') {
            filteredData = filteredData.filter(data => data.module_id === selectedModule);
        }
        if (selectedDQC !== 'All DQCs' && selectedDQC !== '') {
            filteredData = filteredData.filter(data => data.dqc_no === selectedDQC);
        }

        return filteredData;
    };

    const getFilteredTotalInstruments = () => {
        let filteredInstruments = totalInstruments;

        // Filter out instruments that are already in orgInstrumentsData
        let orgInstrumentCodes = orgInstrumentsData.filter(item => item.module_id === selectedModule).map(orgInst => orgInst.instrument_code);
        filteredInstruments = filteredInstruments.filter(
            instrument => !orgInstrumentCodes.includes(instrument.code)
        );

        // Filter based on selectedDQC if it is defined
        if (selectedDQC) {
            filteredInstruments = filteredInstruments.filter(instrument => instrument.dqc_no === selectedDQC);
        }

        return filteredInstruments;
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value); // Convert string to number
        setCurrentPage(0); // Reset to the first page
    };

    const handleStartEdit = (id, orderNumber) => {
        setEditedRow({ id, order_no: orderNumber });
    };

    const handleOrderChange = (value) => {
        setEditedRow(prev => ({ ...prev, order_no: value }));
    };

    const updateORGAssessInstrument = async () => {
        if (editedRow) {
            try {
                setLoading(true);

                const payload = {
                    operation: 'update',
                    instrument: {
                        id: editedRow.id,
                        order_no: editedRow.order_no
                    }
                };
                const result = await updateAssessInstruments(application_id, payload);
                if(!result.error && result.data.success) {
                    toastSuccess('Success', 'Order # Updated');
                    // Update the state locally rather than re-fetching data
                    setORGInstrumentsData(prevData => {
                        const updatedData = [...prevData];
                        const instrumentIndex = updatedData.findIndex(instrument => instrument._id === editedRow.id);
                        if (instrumentIndex !== -1) {
                            updatedData[instrumentIndex].order_no = editedRow.order_no;
                        }
                        return updatedData;
                    });
                }
                setLoading(false);
                setEditedRow(null);
            } catch (error) {
                console.error("Failed to update order number", error);
                setLoading(false);
            }
        }
    };

    async function removeORGAssessInstrument(id) {
        try {
            setLoading(true);

            const payload = {
                operation: 'remove',
                instrument: {id},
            };
            const result = await updateAssessInstruments(application_id, payload);
            if(!result.error && result.data.success) {
                toastSuccess('Success', 'Assessment Removed');
                setORGInstrumentsData(prevData =>
                    prevData.filter(instrument => instrument._id !== id)
                );
            }
            setLoading(false);
        }
        catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    async function addAssessInstrument(instrument_code) {
        try {
            setLoading(true);
            const module_id = selectedModule
            const payload = {
                operation: 'add',
                instrument: {instrument_code, module_id},
            };
            const result = await updateAssessInstruments(application_id, payload);
            if(!result.error && result.data.success) {
                toastSuccess('Success', 'Assessment Added');
                setORGInstrumentsData(result.data.new_list);
            }
            setLoading(false);
        }
        catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    const sortedAndFilteredORGInstruments = getFilteredORGInstruments().sort((a, b) => {
        const orderA = a.order_no || Number.MAX_VALUE;
        const orderB = b.order_no || Number.MAX_VALUE;
        return orderA - orderB;
    });

    const instrument_response_parser = (data) => {
        //console.log("Raw Data: ",data)
        // Check if data is undefined or not a string
        if (typeof data !== 'string' || data === undefined) {
            console.warn("Invalid data provided to instrument_response_parser:", data);
            return [];
        }
        let str = data.trim();
        let str_arr = str.split('"');

        //console.log("after splitting into",str_arr)
        let output = []

        for (let i=1; i<str_arr.length; i++) {
            const option = str_arr[i].split('"')[0];
            if (option.length > 1) {
                output.push(option);
            }
        }

        if (output.length === 0) {
            return data
        }
        //console.log("Length:",output.length);
        //console.log("List",output);
        return output;
    }

    const table_row__additional_rendering = (instruments_list) =>{
        let spans = []; // array to update element by element of argument list
        if (Array.isArray(instruments_list)) {
            for (let item of instruments_list) {
                // console.log("typeof", (typeof item))
                spans.push(<p className={'meta-data-table-array-span'}>{item}</p>);
                // console.log("ITEM:", item)
            }
        }
        else{
            spans=instruments_list;
        }
        return spans;
    }

    const createRawDataCodesDict = async () => {
        setLoading(true);
        setDisableSubmit(true);
        const promises = []; // To collect all the update promises

        for (const postTestModule of orgModuleData) {
            const improve_pair = {}; // Moved inside the loop

            if (postTestModule.pre_test_module_id) {
                const preTestModule = orgModuleData.find(mod => mod.id === postTestModule.pre_test_module_id);

                if (!preTestModule) continue;  // If corresponding preTestModule not found, skip to next iteration

                const preTestInstruments = orgInstrumentsData.filter(inst => inst.module_id === preTestModule.id);
                const postTestInstruments = orgInstrumentsData.filter(inst => inst.module_id === postTestModule.id);

                console.log('preTestInstruments: ', preTestInstruments.length);
                console.log('postTestInstruments: ', postTestInstruments.length);

                preTestInstruments.forEach(preInst => {
                    const preTotalInstrument = totalInstruments.find(
                        totalInst => totalInst.code === preInst.instrument_code
                    );

                    if (preTotalInstrument) {
                        postTestInstruments.forEach(postInst => {
                            const postTotalInstrument = totalInstruments.find(
                                totalInst => totalInst.code === postInst.instrument_code
                            );

                            if (postTotalInstrument && postTotalInstrument.raw_data_code === preTotalInstrument.raw_data_code) {
                                improve_pair[preTotalInstrument.raw_data_code] = preTotalInstrument.raw_data_code;
                            }
                        });
                    }
                });

                const moduleData = { module_code: postTestModule.module_code, improve_pair };
                promises.push(updateAssessModules(application_id, moduleData)); // Push promise into promises array
            }
        }

        const results = await Promise.all(promises); // Wait for all updates to complete

        results.forEach(result => {
            if (!result.error && result.data.success) {
                console.log('Success', 'Improve Pair Posted');
            } else {
                // Handle errors for each update here
                setDisableSubmit(false);
            }
        });
        updateStatus(APP_STATUS.PENDING_APPROVE_MODULES);
        console.log('All improve_pairs processed.');
        setLoading(false);
    }

    // Pagination slicing
    const filteredORGInstruments = sortedAndFilteredORGInstruments.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

    return <>
        <Box display="flex" mb={2} alignItems="center">

            {/* Module Dropdown */}
            {
                orgModuleData && orgModuleData.length > 0 &&
                <Box flex={0.8} mr={2}>
                    <TextField
                        id="module-select"
                        select
                        fullWidth
                        variant="outlined"
                        label="Select Module"
                        value={selectedModule}
                        onChange={(e) => onChangeModule(e.target.value)}
                    >
                        <MenuItem value="All Modules">All Modules</MenuItem>
                        {[...orgModuleData]
                            .sort((a, b) => a.order_no - b.order_no)
                            .map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.module_name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            }

            {/* DQC Dropdown */}
            {
                alignedDQC && alignedDQC.length > 0 &&
                <Box flex={0.8}>
                    <TextField
                        id="dqc-select"
                        select
                        fullWidth
                        variant="outlined"
                        label="Select Aligned DQC"
                        value={selectedDQC}
                        onChange={(e) => onChangeDQC(e.target.value)}
                    >
                        <MenuItem value="All DQCs">All DQCs</MenuItem>
                        {alignedDQC.map((dqc, index) => (
                            <MenuItem key={index} value={dqc}>
                                {dqc}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            }
            {/* Button */}
            <Box ml={2} flex={1}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={((!selectedModule || selectedModule === 'All Modules') && (!selectedDQC || selectedDQC === 'All DQCs')) || ((selectedModule || selectedModule === 'All Modules') && (selectedDQC === 'All DQCs' || !selectedDQC)) || ((!selectedModule || selectedModule === 'All Modules') && selectedDQC)}
                    onClick={() => setOpenModal(true)}
                >
                    Add Instruments
                </Button>
            </Box>
        </Box>
        { app.assessment_req_info.assess_type > 4 &&
            <Typography component="div" style={{color:'red', fontSize:'10px'}}>
                Share the custom excel file to TT to be uploaded to the portal. </Typography>
        }
        <Box mt={5}>
            <Paper elevation={3} style={{ overflowX: 'auto' }}>
                <TableContainer style={{maxWidth: "100%"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Order #</TableCell>
                            <TableCell>Program Module</TableCell>
                            <TableCell>Assessment Module</TableCell>
                            <TableCell>Instrument Question</TableCell>
                            <TableCell>Instrument Response</TableCell>
                            <TableCell>Correct Answer</TableCell>
                            <TableCell>Raw Data Code</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Is Active</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredORGInstruments.map((orgInstrument, index) => {
                                // const totalInstrumentData = totalInstruments.find(
                                //     totalInst => totalInst.code === orgInstrument.instrument_code
                                // );

                                const isEditingThisRow = editedRow && editedRow.id === orgInstrument._id;

                                const currentOrderNumber = isEditingThisRow ? (
                                    <input
                                        type="number"
                                        defaultValue={orgInstrument.order_no || ''}
                                        onChange={(e) => handleOrderChange(e.target.value)}
                                        style={{ width: '60px' }}  // adjust the width as needed
                                    />
                                ) : (orgInstrument.order_no !== null ? orgInstrument.order_no : '');


                                // Find the module name based on the module_id
                                const module = orgModuleData.find(mod => mod.id === orgInstrument.module_id);
                                const moduleName = module ? module.module_name : 'Unknown'; // default to 'Unknown' if not found
                                // Find the app module name based on the app_module_id_ref
                                const appModule = appModuleData.find(mod => mod.module_id === module.app_module_id_ref);
                                const appModuleName = appModule ? appModule.module_name : moduleName; // default to 'Unknown' if not found

                                // if (!totalInstrumentData) return null;  // or handle mismatched data as you see fit

                                return (
                                    <TableRow key={orgInstrument._id}>
                                        <TableCell>{currentOrderNumber}</TableCell>
                                        <TableCell>{appModuleName}</TableCell>
                                        <TableCell>{moduleName}</TableCell>
                                        <TableCell>{orgInstrument.instrument_question}</TableCell>
                                        <TableCell>
                                            {/*totalInstrumentData.instrument_response*/}
                                            {table_row__additional_rendering(instrument_response_parser(orgInstrument.instrument_response))}
                                        </TableCell>
                                        <TableCell>{orgInstrument.correct_answer}</TableCell>
                                        <TableCell>{orgInstrument.raw_data_code}</TableCell>
                                        <TableCell>{orgInstrument.category_11}</TableCell>
                                        <TableCell>{orgInstrument.is_active ? "Yes" : "No"}</TableCell>
                                        <TableCell>
                                            {!isEditingThisRow && (
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleStartEdit(orgInstrument._id, currentOrderNumber)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                            {isEditingThisRow && (
                                                <IconButton
                                                    color="primary"
                                                    onClick={updateORGAssessInstrument}
                                                >
                                                    <SaveIcon />
                                                </IconButton>
                                            )}
                                            {orgInstrument.is_active && (
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => removeORGAssessInstrument(orgInstrument._id)}
                                                    // disabled={app_status > APP_STATUS.AUTO_ASSESS_GENERATION_COMPLETED}
                                                >
                                                    <DeleteIcon style={{ color: 'red' }} />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
                </TableContainer>
            </Paper>
        <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={getFilteredORGInstruments().length} // Total number of items in your data
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
        {/* Submit button */}
        <Box mt={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    createRawDataCodesDict().then(r => console.log("Improved Paired Created and Submitted"));
                }}
                disabled={(disableSubmit || app_status > APP_STATUS.PENDING_ASSESS_GENERATED_APPROVE) || orgInstrumentsData.length === 0}
            >
                Submit
            </Button>
            <Button
                variant="contained"
                color="secondary"
                disabled={!disableSubmit && !(app_status > APP_STATUS.PENDING_ASSESS_GENERATED_APPROVE)}
                onClick={() => forwardStep()}
            >
                Next
            </Button>
        </Box>

        {/* Dialog with the table */}
        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="md" fullWidth>
            <DialogTitle>Total Instruments</DialogTitle>
            <DialogContent>
                <div className={classes.scrollableTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sr.#</TableCell>
                                <TableCell>Instrument Question</TableCell>
                                <TableCell>Instrument Response</TableCell>
                                <TableCell>Correct Answer</TableCell>
                                <TableCell>Raw Data Code</TableCell>
                                <TableCell>Category 11</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getFilteredTotalInstruments().map((instrument, index) => (
                                <TableRow key={instrument._id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{instrument.instrument_question}</TableCell>
                                    <TableCell>{instrument.instrument_response}</TableCell>
                                    <TableCell>{instrument.correct_answer}</TableCell>
                                    <TableCell>{instrument.raw_data_code}</TableCell>
                                    <TableCell>{instrument.category_11}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => addAssessInstrument(instrument.code)}
                                            // disabled={app_status > APP_STATUS.AUTO_ASSESS_GENERATION_COMPLETED}
                                        >
                                            Add
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>


    </>
}

export default ReviewAssessmentsModule;