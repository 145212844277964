import axios from 'axios';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {toastWarning} from "../utils/utils";
import {refreshToken} from "../services/auth.service";
import {ECC_cipher_helper} from "../utils/ecc_cipher_util";

axios.defaults.headers.post['Content-Type'] = 'application/json';
const base_uri = process.env.REACT_APP_MYDQ_API;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(promise => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });

    failedQueue = [];
};

function invokeApiWihToken(reqParams, token) {
    const {headers} = reqParams;
    const modifiedHeaders = {...headers, 'Authorization' : `Bearer ${token}`}
    const modifiedParams = {...reqParams, headers: modifiedHeaders};
    return invokeApi(modifiedParams);
}

function retryRequestWithRefresh(reqParams) {
    return new Promise((resolve, reject) => {
        refreshToken() // Make a request to refresh the token
            .then(response => {
                const {auth_token, user} = response.data;
                localStorage.setItem(LOCAL_STORAGE_KEYS.auth_token, JSON.stringify(auth_token));
                localStorage.setItem(LOCAL_STORAGE_KEYS.auth_user, JSON.stringify(user));
                processQueue(null, auth_token); // Handle queued requests
                resolve(invokeApiWihToken(reqParams, auth_token));
            })
            .catch(err => {
                processQueue(err, null); // Handle queued requests
                reject(err);
            })
            .finally(() => {
                isRefreshing = false; // Reset flag
            });
    });
}

function putRequestInQueue(reqParams) {
    return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
    })
        .then(token => invokeApiWihToken(reqParams, token)) // Retry request with new token
        .catch(err => Promise.reject(err));
}

function forceLogout() {
    localStorage.clear();
    window.location.reload();
}

function getRefreshToken() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.refresh_token));
}


export async function invokeApi(reqParams, use_e2ee=false) {

    const {
        path,
        method = 'GET',
        headers = {},
        queryParams = {},
        postData = {},
        ...rest
    } = reqParams;

    let reqObj = {
        method  : method,
        url     : base_uri + path,
        headers : headers,
        ...rest
    };
    reqObj['params'] = queryParams;

    if (method === 'POST') {
        let e2ee_payload = false;
        let e2ee_obj_str, e2ee_obj_keypair;
        if (use_e2ee) {
            const e2ee_obj_b64 = localStorage.getItem(LOCAL_STORAGE_KEYS.e2ee_krypto_pair_obj);
            if (e2ee_obj_b64) e2ee_obj_str = atob(e2ee_obj_b64);
            if (e2ee_obj_str) e2ee_obj_keypair = JSON.parse(e2ee_obj_str);
        }
        if (e2ee_obj_keypair && e2ee_obj_keypair.pub) {
            e2ee_payload = true;

            // encrypt using ECC-keys
            const publicKey = JSON.parse(atob(e2ee_obj_keypair.pub))
            const payloadString = JSON.stringify(postData);
            const payload = await ECC_cipher_helper.encryptData(payloadString, publicKey);

            reqObj['data'] = {
                payload,
                e2ee_payload
            }
        }
        else {
            reqObj['data'] = postData
        }
    }

    let results = undefined;

    try {
        results = await axios(reqObj);
        if (results.data.data && results.data.data.e2ee_data) {
            // need to decrypt the response
            let e2ee_obj_str, e2ee_obj_keypair;
            const e2ee_obj_b64 = localStorage.getItem(LOCAL_STORAGE_KEYS.e2ee_krypto_pair_obj);
            if (e2ee_obj_b64) e2ee_obj_str = atob(e2ee_obj_b64);
            if (e2ee_obj_str) e2ee_obj_keypair = JSON.parse(e2ee_obj_str);
            if (e2ee_obj_keypair && e2ee_obj_keypair.pvt) {
                const privateKey = JSON.parse(atob(e2ee_obj_keypair.pvt));
                const cipher_data = results.data.data.secure_data;
                const responseString = await ECC_cipher_helper.decryptData(cipher_data, privateKey);
                const responseData = JSON.parse(responseString);
                if (Array.isArray(responseData)) results.data.data = [...responseData];
                else results.data.data = {...responseData};
                if (!use_e2ee) console.log(`QA:MODE: path: ${path} --> E2EE data received:`, results.data.data);
            } else {
                toastWarning(`System security protocols are update! 
                Your current session is forced logged out!
                Please re-login to adopt the improved system.`);

                setTimeout(() => {
                    forceLogout();
                }, 4000);

                return false;
            }
        }
        return results.data;
    } catch (error) {
        console.log('invoke API :: Error: ', error);

        if (error.response.status === 499) throw new Error('Permission denied');

        if(error.response.status === 401) {
            forceLogout();
            throw new Error('Unauthorized');
        }

        if(error.response.status === 419) {
            if (!getRefreshToken()) {
                forceLogout();
                throw new Error('Unauthorized');
            }
            if (isRefreshing) return putRequestInQueue(reqParams);
            isRefreshing = true;
            return retryRequestWithRefresh(reqParams);
        }

        if (!(error.response && error.response.data && error.response.data.message)) {
            toastWarning("Server Response", 'NETWORK ERROR : Some Thing Went Wrong!');
            throw new Error('NETWORK ERROR : Some Thing Went Wrong!');

        } else {
            toastWarning("Server Response", error.response.data.message);
            throw new Error(error.response.data.message);
        }
    }
}
