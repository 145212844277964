import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

function AnalysisChart(props) {
    const {analysis} = props;

    const formatted_data = {
        labels: analysis.map((item) => item.title),
        datasets: [{
            label: 'Analysis',
            data: analysis.map((item) => item.percent),
            backgroundColor: analysis.map((item) => item.color),
        }]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: "bottom"
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        const {title, percent, count} = analysis[context.dataIndex]
                        return `${title}: ${percent}% (${count})`;
                    }
                }
            },
            datalabels: {
                labels: {
                    inside: {
                        color: "black",
                        font: {
                            size: 14
                        },
                        anchor: "end",
                        align: "start",
                        formatter: function(value, context) {
                            return `${analysis[context.dataIndex].count}`;
                        }
                    },
                }
            }
        }
    }

    return <Pie data={formatted_data} options={options}/>
}

export default AnalysisChart;