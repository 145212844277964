import React, {useState} from 'react';
import {DropzoneDialog} from 'material-ui-dropzone';
import LoaderBackDropWithPercentage from "../../../components/LoaderWithBackDrop/LoaderBackDropWithPercentage.comp";
import {uploadMediaFile} from "../../../services/app.service";


function UploadImageDialogue(props) {

    const { openDialogue, dialogueCloseHandler, successCB } = props;

    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    async function FileDialogueHandleSave(files) {
        setUploading(true);
        const formData = new FormData();
        formData.append('media', files[0]);
        try {
            const result = await uploadMediaFile(formData, progressEvent => {
                const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 99);
                setUploadProgress(uploadPercentage);
            })

            if(result.data) {
                const {media_path} = result.data;
                setUploadProgress(100);
                setTimeout(()=> {
                    setUploading(false);
                    setUploadProgress(0);
                    successCB(media_path);
                }, 200);
            }
        }
        catch (e) {
            console.error("Error uploading file", e);
            dialogueCloseHandler();
        }
    }

    return (
        <>
            <DropzoneDialog
                open={openDialogue}
                onSave={FileDialogueHandleSave}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                showPreviews={true}
                maxFileSize={5000000}
                filesLimit={1}
                onClose={dialogueCloseHandler}
            />
            {
                <LoaderBackDropWithPercentage
                    loading={uploading}
                    value={uploadProgress}
                />
            }
        </>
    );
}

export default UploadImageDialogue;