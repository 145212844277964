import React, { useState, useEffect } from "react";
import {useHistory, useLocation} from "react-router-dom";
import {
    Container,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Formik, Form, Field, useFormikContext} from "formik";
import {REGIONS} from "../../utils/META/cat_101";
import {ORG_TYPES} from "../../utils/META/cat_102";
import {SECTORS} from "../../utils/META/cat_103";
import {HIST_DURATIONS} from "../../utils/META/cat_104";
import {SIZE_EMPLOYEE} from "../../utils/META/cat_105";
import {toastSuccess} from "../../utils/utils";
import {updateAppCompanyService} from "../../services/app.service";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
    section: {
        marginBottom: theme.spacing(4),
    },
}));

const FormTextField = ({ label, name, required = true, ...rest}) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required={required}
        margin="normal"
        variant="outlined"
        {...rest}
    />
);

const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);

const FormComponent = ({disableSubmit}) => {

    const classes = useStyles();
    const {values} = useFormikContext(); // get current form values

    return (
        <Form>
            <div className={classes.section}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Organization Description
                </Typography>
                <FormTextField label="Name of organization" name="NameOrg" />
                <FormTextField label="Name of contact person" name="NameContact" />
                <FormTextField label="Department and position of contact person" name="NameDept"/>
                <FormSelect
                    label="Country of organization’s headquarters"
                    name="Region"
                    options={REGIONS}
                />
                <FormSelect
                    label="Type of organization"
                    name="OrgType"
                    options={ORG_TYPES}
                />
                {values.OrgType === 'other' && (
                    <FormTextField label="Please specify type of organization." name="OrganizationEntered" />
                )}
                <FormSelect
                    label="Sector of organization"
                    name="Sector"
                    options={SECTORS}
                />
                {values.Sector === 'other' && (
                    <FormTextField label="Please specify sector of organization." name="SectorEntered" />
                )}
                <FormSelect
                    label="Age of organization"
                    name="History"
                    options={HIST_DURATIONS}
                />
                <FormSelect
                    label="Size of organization"
                    name="SizeEmployee"
                    options={SIZE_EMPLOYEE}
                />
                <FormTextField
                    label="Mission and vision of organization"
                    name="OrgVision"
                />
            </div>
            <Button type="submit" variant="contained" color="primary" disabled={disableSubmit}>
                Update
            </Button>
        </Form>
    );

};

const EditApplicationCompany = (props) => {

    const history = useHistory();
    const {state = {}, search} = useLocation();
    const {existingValues, application_id} = state;

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        setDisableSubmit(true);
        try {
            const result = await updateAppCompanyService(application_id, values);
            console.log('result.data -> ', result.data);
            toastSuccess("Company Updated", "Successfully updated the company information")
            history.push('/application/detail', {application_id});
        } catch (e) {
            // already toasted the error
        }
        setDisableSubmit(false);
        setLoading(false);
    };

    return (
        <Container className={classes.container}>
            <Formik initialValues={existingValues} onSubmit={handleSubmit}>
                <FormComponent disableSubmit={disableSubmit}/>
            </Formik>
            <LoaderWithBackDrop loading={loading}/>
        </Container>
    );
};

export default EditApplicationCompany;
