import React, { useEffect, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Container,
    Typography,
    Grid,
    TextField,
    Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core';

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import {APP_STATUS} from "../../../utils/constants";
import {toastSuccess, toastWarning} from "../../../utils/utils";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {
    getOrgDetails,
    listOrgModules,
    updateOrgCallbackInfo
} from "../../../services/certify.service";
import {getAppService, updateAppStatusService} from "../../../services/app.service";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        textAlign:'center',
    },
    button: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 3, 1, 3),
        backgroundColor: theme.palette.info.main,
        fontSize: '14px',
        color: theme.palette.background.default,
        border: 'none',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.background.default,
        },
    },
    paperStyle: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height:'100%',
    },
    listItemStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    customButton: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.background.default,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
        },
    },
    customButton2: {
        backgroundColor: '#32CD32',
        color: theme.palette.background.default,
        '&:hover': {
            backgroundColor: '#32CD32',
        },
    },

}));



function ReviewPublishModules(props) {

    const { application_id, app_status, app, updateStatus, updateApp } = props;

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [orgCode, setOrgCode] = useState(false);
    const [orgInfo, setOrgInfo] = useState(undefined);
    const [orgModules, setOrgModules] = useState(undefined);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [isEditing, setIsEditing] = useState({
        callback_url: false,
        callback_key: false
    });
    const [tempValues, setTempValues] = useState({
        callback_url: orgInfo?.callback_url || '',
        callback_key: orgInfo?.callback_key || ''
    });


    useEffect(() => {
        console.log('app.assessments_approve_info: ', app.assessments_approve_info);
        if (app.assessments_approve_info && app.assessments_approve_info.org_code) {
            setOrgCode(app.assessments_approve_info.org_code);
        } else {
            const fetchUpdatedApplicationInfo = async () => {
                    try {
                        setLoading(true);
                        const result = await getAppService(application_id);
                        if (result && !result.error) {
                            setOrgCode(result.data.assessments_approve_info.org_code);
                            updateApp(result.data);
                        } else {
                            console.log('Error fetching updated app data');
                        }
                    } catch (error) {
                        console.error('Error fetching app data:', error);
                    } finally {
                        setLoading(false);
                    }
            };
            fetchUpdatedApplicationInfo().then(r => console.log('Updated App data fetched'));
        }
    }, []);

    useEffect(() => {
        if (orgCode) {
            fetchOrganizationInfo().then((res) => `org details fetched, ${res}`);
            fetchOrganizationModules().then((res) => `org modules fetched, ${res}`);
        }
    }, [orgCode]);

    const fetchOrganizationInfo =  async () => {
        try {
            setLoading(true);
            const result = await getOrgDetails(orgCode);
            console.log('result.data -> ', result.data);
            if (result.data && result.data.org_object) {
                setOrgInfo(result.data.org_object);
            }
        }
        catch (e) {
            //already toasted
        }
        setLoading(false);
    }

    const fetchOrganizationModules =  async () => {
        try {
            setLoading(true);

            const result = await listOrgModules(orgCode);
            console.log('result.data -> ', result.data);
            if (result.data && result.data.modules) {
                setOrgModules(result.data.modules);
            }
        }
        catch (e) {
            //already toasted
        }
        setLoading(false);
    }

    const goToDqAssess = () => {
        window.open(process.env.REACT_APP_MYDQ_APP_LOGIN_URL, '_blank');
    }

    const goToDqDashboard = () => {
        window.open(process.env.REACT_APP_ANALYTICS_PORTAL_LOGIN_URL, '_blank');
    }

    const handleEdit = (field) => {
        setIsEditing({
            ...isEditing,
            [field]: true
        });
        setTempValues({
            ...tempValues,
            [field]: orgInfo[field]
        });
    };

    const handleSave = async (field) => {
        console.log(`Saving ${field}:`, tempValues[field]);
        setIsEditing({
            ...isEditing,
            [field]: false
        });

        // call the backed.
        try {
            const result = await updateOrgCallbackInfo(orgCode, {[field]: tempValues[field]});
            console.log('result.data -> ', result.data);
            if (result.data && result.data.success && result.data.org_object) {
                setOrgInfo(result.data.org_object);
            }
        }
        catch (e) {
            //already toasted
        }
    };

    const handleInputChange = (field, e) => {
        setTempValues({
            ...tempValues,
            [field]: e.target.value
        });
    };

    const submitModules = async () => {
        setLoading(true);
        setDisableSubmit(true);
        try {
            const result = await updateAppStatusService(application_id, APP_STATUS.ASSESSMENTS_READY, true);
            if(!result.error && result.data.success) {
                // updateStatus(APP_STATUS.ASSESSMENTS_READY);
                toastSuccess('Success', 'Assessment Ready for Client');
            }
        }
        catch (e) {
            //already toasted
            setDisableSubmit(false);
        }
        setLoading(false);
    }

    return (
        <>
            {
                <Container>
                    <Grid container spacing={2} >
                        {/* Box 1 */}
                        <Grid item xs={6}>
                            <Paper elevation={3} className={classes.paperStyle}>
                                <Typography variant="h5">View Your Assessments</Typography>
                                <List className={classes.listItemStyle}>
                                    <ListItem>
                                        <ListItemText
                                            primary="1. You can preview your assessment in the DQ Assessment website using the following credentials:"
                                        />
                                    </ListItem>
                                    <Button
                                        onClick={goToDqAssess}
                                        variant="contained" className={classes.button}>
                                        Preview Assessment
                                    </Button>
                                    <ListItem >
                                        <Typography variant="h6" style={{textAlign: 'left'}}>
                                            Either sign in using:</Typography>
                                    </ListItem>
                                    <ul style={{textAlign: 'left'}}>
                                        <li>Username: <b>{app.assessments_approve_info ? app.assessments_approve_info.demo_dq_assess_user : undefined}</b>
                                        </li>
                                        <li style={{textAlign: 'left'}}>Password: <b>{app.assessments_approve_info ? app.assessments_approve_info.demo_dq_assess_pass : undefined}</b>
                                        </li>
                                    </ul>
                                    <ListItem>
                                        <Typography variant="h6">Or sign up with:
                                        </Typography>
                                    </ListItem>

                                    <ul style={{textAlign: 'left', marginRight: '90px'}}>
                                        {
                                            orgInfo &&
                                            <li style={{textAlign: 'left'}}>Access
                                                Code: <b>{orgInfo.access_code}</b>
                                            </li>
                                        }

                                    </ul>
                                </List>
                                <List className={classes.listItemStyle}>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <div>
                                                    2. After previewing the assessment, if there is anything you would like to be updated, please reach out to {' '}
                                                    <a href="mailto:research@dqforall.com">research@dqforall.com</a>.
                                                </div>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="3. Once the assessment is ready to be published, please click “Publish”. This step is irreversible, so please double check before proceeding."
                                        />
                                    </ListItem>
                                    <Button
                                        onClick={()=>toastWarning('Not Implemented !!')}
                                        variant="contained" className={`${classes.button} ${classes.customButton2}`}>
                                        Publish
                                    </Button>

                                </List>

                            </Paper>
                        </Grid>
                        {/* Box 2 */}
                        <Grid item xs={6}>
                            <Paper elevation={3} className={classes.paperStyle}>
                                <Typography variant="h5">Access Your Analytics Dashboard</Typography>
                                <List  className={classes.listItemStyle}>
                                    <ListItem>
                                        <ListItemText
                                            primary="1. You can access your dashboard of your aggregated users data as they take the assessments here."
                                        />
                                    </ListItem>
                                    <Button
                                        onClick={goToDqDashboard}
                                        variant="contained" className={classes.button}>
                                        Go to Dashboard
                                    </Button>
                                    <Typography variant="h6"></Typography>

                                <ul style={{textAlign: 'left'}}>
                                    <li>Username: <b>{app.assessments_approve_info ? app.assessments_approve_info.demo_dashboard_user: undefined}</b></li>
                                    <li style={{textAlign: 'left'}}>Password: <b>{app.assessments_approve_info ? app.assessments_approve_info.demo_dashboard_pass : undefined}</b></li>
                                </ul>
                                </List>
                                <List  className={classes.listItemStyle}>
                                    <ListItem>
                                        <ListItemText
                                            primary="2. Upon logging in, kindly reset your password. You will also have the option to reassign the user to a different email address if needed. "
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="3. We have also included guides on how to distribute assessment accounts to your users and a dashboard overview in the downloadable guides below."
                                        />
                                    </ListItem>

                                    <Button
                                        variant="contained" className={classes.button}
                                        href="https://storage.googleapis.com/dqlab-public/docs/DQ%20Accounts%20Distribution%20Guide.pdf" target="_blank"
                                    >
                                        Account distribution Guide
                                    </Button>
                                    <Button
                                        href="https://storage.googleapis.com/dqlab-public/docs/DQ%20Dashboard%20Guide.pdf" target="_blank"
                                        variant="contained" className={classes.button}>
                                        Dashboard Guide
                                    </Button>
                                </List>

                            </Paper>
                        </Grid>

                        {
                            app.assessments_approve_info && app.assessments_approve_info.enable_api &&
                            <>
                            <Grid xs={7} md={12} item style={{marginTop:'3%'}}>
                                <Paper elevation={3} className={classes.paperStyle}>
                                    <Typography variant="h5">API Integration</Typography>
                                    <Typography>
                                        If you are integrating via API, you can find the documentation below to help with integration of the assessments into your system. If you need further technical support, please reach out to{' '}
                                        <a href="mailto:tech@dqforall.com">tech@dqforall.com</a>{' '}.
                                    </Typography>
                                        {
                                            orgInfo &&
                                            <ul style={{marginTop:'20px'}}>
                                                <li style={{textAlign: 'left'}}>App Key: <b>{orgInfo.app_key}</b></li>
                                                <li style={{textAlign: 'left'}}>Secret Key: <b>{orgInfo.secret_key}</b></li>
                                                <br />
                                                <li style={{ textAlign: 'left' }}>
                                                    Callback URL:&nbsp;
                                                    {isEditing.callback_url ? (
                                                        <div>
                                                            <TextField
                                                                value={tempValues.callback_url}
                                                                onChange={(e) => handleInputChange('callback_url', e)}
                                                            />
                                                            <Button onClick={() => handleSave('callback_url')}>Save</Button>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <b>{orgInfo?.callback_url}</b>
                                                            <Button onClick={() => handleEdit('callback_url')}>Edit</Button>
                                                        </>
                                                    )}
                                                </li>
                                                <li style={{ textAlign: 'left' }}>
                                                    Callback Key:&nbsp;
                                                    {isEditing.callback_key ? (
                                                        <div>
                                                            <TextField
                                                                value={tempValues.callback_key}
                                                                onChange={(e) => handleInputChange('callback_key', e)}
                                                            />
                                                            <Button onClick={() => handleSave('callback_key')}>Save</Button>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <b>{orgInfo?.callback_key}</b>
                                                            <Button onClick={() => handleEdit('callback_key')}>Edit</Button>
                                                        </>
                                                    )}
                                                </li>
                                            </ul>
                                        }
                                <br/>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                    <Button
                                        style={{marginRight: '40px'}}
                                        component="a"
                                        href="https://storage.googleapis.com/mydq-live-bucket/Content/Docs/DQ_App__REST_API_Implementation_Manual_v1.0.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="contained" className={classes.button}>
                                        Download API Documentation
                                    </Button>
                                </div>
                                <div style={{ width: '100%',marginTop:'30px' }}>
                                    <TableContainer component={Paper} style={{ width: '45%', margin: 'auto' }}>
                                        <Table aria-label="simple table" padding="normal" size="medium">
                                            <TableHead style={{ marginLeft:'2%' }}>
                                                <TableRow >
                                                    <TableCell>Module Code</TableCell>
                                                    <TableCell>Module Name</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody style={{ marginLeft:'2%' }}>
                                                {
                                                    orgModules &&
                                                    orgModules.map(row => <TableRow>
                                                        <TableCell>{row.module_code}</TableCell>
                                                        <TableCell>{row.module_name}</TableCell>
                                                    </TableRow>)
                                                }


                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>


                                </Paper>
                            </Grid>
                            </>
                        }


                    </Grid>
                    <Box mt={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                        <Button
                            variant='contained'
                            color="primary"
                            onClick={() => submitModules()}
                            disabled={disableSubmit || app_status >= APP_STATUS.ASSESSMENTS_READY}
                        >Submit</Button>
                    </Box>
                    <LoaderWithBackDrop loading={loading} />
                </Container>
            }
        </>

    );
}

export default ReviewPublishModules;