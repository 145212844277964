import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";
import {asciiSortCompareFunWithKey} from "../../../../utils/utils";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        tableLayout: 'fixed',
        width: '100vw',
        "& th,td": {
            border: "1px solid grey",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
            padding: "18px 12px",
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        '& th:first-child': {
            textAlign: 'left',
        },
        "& th:nth-child(1)": {
            width: "50%"
        },
        "& th:nth-child(2),": {
            width: "25%"
        },
        "& th:nth-child(3)": {
            width: "25%"
        },
        "& td:nth-child(1)": {
            width: "50%"
        },
        "& td:nth-child(2),": {
            width: "25%"
        },
        "& td:nth-child(3)": {
            width: "25%"
        }
    },
}));

function CustomRow(props) {

    const {v1, v2, v3, is_last = false} = props;

    const c0 = {backgroundColor: is_last ? "lightgrey" : "default"}
    const c1 = {color: "black", fontWeight: is_last ? "bold" : "normal", textAlign: "left", fontFamily: 'Source Sans Pro', fontSize: '18px'}
    const c2 = {color: "black", fontWeight: is_last ? "bold" : "normal", textAlign: "center", fontFamily: 'Source Sans Pro', fontSize: '18px'}
    const c3 = {color: "black", fontWeight: "bold", textAlign: "center", fontFamily: 'Source Sans Pro', fontSize: '18px'}
    return <tr style={c0}>
        <td style={c1}>{v1}</td>
        <td style={c2}>{v2}</td>
        <td style={c3}>{v3}</td>
    </tr>
}

export default function Page9(props) {

    const {DQ_DEFINITIONS, pageNumber, overallCoverage, coverage, onlyTable = false, isLastPage} = props;
    const total_messages = overallCoverage && overallCoverage.grand_total && overallCoverage.grand_total.count ? overallCoverage.grand_total.count : 0;
    const total_dqc = overallCoverage && overallCoverage.data ? overallCoverage.data.length : 0;

    const classes = tableStyles();
    // function getDQC(dqc_no) {
    //     const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
    //     const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
    //     const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
    //     const color = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].color : "#444"
    //     return {code, title, color};
    // }
    // function getDQCName(dqc_no) {
    //     const {title, code} = getDQC(dqc_no);
    //     return code + (title ? `: ${title}` : '');
    // }

    return <ReportPage pageNumber={pageNumber}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} px={10}>
            { !onlyTable &&
                <Box>
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '70px',
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
            >
                The following table lists the distribution of the <span>{total_messages} </span> learning messages across the <span>{total_dqc} </span> DQ competencies:
            </Typography>
                </Box>
            }
            <Box paddingTop={onlyTable ? '70px' : '30px'}>
                <table className={classes.root} style={{width: "100%"}}>
                    { !onlyTable &&
                    <tr>
                        <th>DQ Competency</th>
                        <th># of Messages</th>
                        <th>%</th>
                    </tr>
                    }
                    {
                        coverage &&
                        coverage.map((item) =>
                            <CustomRow v1={item.dqc_name} v2={item.count} v3={item.percent}/>
                        )
                    }
                    {
                        isLastPage && overallCoverage && overallCoverage.grand_total &&
                        <CustomRow v1={"Total"} v2={overallCoverage.grand_total.count} v3={overallCoverage.grand_total.percent.toFixed(2)} is_last={true}/>
                    }
                </table>
            </Box>
            { isLastPage &&
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '15px',
                    fontSize: '18px',
                    textAlign: 'center'
                }}
            >
                A description of each DQ competency can be found in <a style={{ color: 'blue', textDecoration: 'underline' }} href={`#${'6.1 Appendix A: Description of DQ Competencies mentioned in Analysis I'.toLowerCase().replace(/ /g, '-')}`}>Appendix A</a>.
            </Typography>
            }
        </Box>
    </ReportPage>
}