import React, {memo, useMemo, useState} from "react";
import {
    Box, Button, Checkbox, FormControlLabel, Paper, TextField,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, MenuItem,
} from "@material-ui/core";
import LearningMessageRow from "./CodeLMRow";
import EnhancedTableCell, {getComparator} from "../../../components/EnhancedTableCell/EnhancedTableCell.comp";
import TableSearchBar from "../../../components/SearchBar/TableSearchBar.comp";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    red_checkbox: {
        '&.Mui-checked': {
            color: "darkred"
        }
    }
}))

const headers = [
    { id: "message_id", label: "ID", alignment: "center", sort: true },
    { id: "message", label: "Learning Message", alignment: "center", sort: true },
    { id: "level_5", label: "Level 5", alignment: "center", sort: true },
    { id: "level_6", label: "Level 6", alignment: "center", sort: true },
    { id: "level_8", label: "Level 8", alignment: "center", sort: true },
    { id: "level_9", label: "Level 9", alignment: "center", sort: true },
    { id: "mb_no", label: "MB#", alignment: "center", sort: true },
    { id: "dqc_no", label: "DQC#", alignment: "center", sort: true },
    { id: "dq_no", label: "DQ#", alignment: "center", sort: true },
    { id: "category_4", label: "Category 4", alignment: "center", sort: true },
]

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

const MemoLearningMessageTable = memo(LearningMessageTable);
function LearningMessageTable(props) {

    const classes = useStyles();

    const {
        modules, selectedModule, messages: rows, mappings, threshold, updateMessage, openComments, openLogs, changeModule
    } = props;

    const memoMappings = useMemo(() => mappings, [mappings]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("message_id");
    const [searchText, setSearchText] = useState('');
    const [showDeleted, setShowDeleted] = useState(false);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    const handleSearchChange = (text) => {
        setPage(0);
        setSearchText(text);
    }

    /* Search Related Func */
    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
    const search_filter_function = (row) => {
        const headers_keys = headers.map((h) => h.id);
        return headers_keys.some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }
    /* End */

    const filteredRows = rows
        .map((row, index) => ({...row, index})) //adding index
        .filter((row) => showDeleted || !row.is_deleted)
        .filter(search_filter_function) //search filter

    const slicedRows = filteredRows
        .sort(getComparator(order, orderBy)) //sorting
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination

    return <>
        <Box display={'flex'} mb={2}>
            {
                selectedModule && modules.length > 1 &&
                <Box mr={2}>
                    <TextField
                        id="module-select"
                        select
                        variant="outlined"
                        label="Select Module"
                        value={selectedModule}
                        onChange={(e) => changeModule(e.target.value)}
                    >
                        {[...modules]
                            .sort((a, b) => a.order_no - b.order_no)
                            .map((option) => (
                            <MenuItem key={option.module_id} value={option.module_id}>
                                {option.module_name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            }
            <TextField id='similarity_threshold' variant="outlined" label={'Similarity Score Threshold'} value={threshold} InputProps={{readOnly: true}}/>
            <Box ml={'auto'}>
                <TableSearchBar searchValue={searchText} onChange={handleSearchChange}/>
            </Box>
        </Box>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            headers.map((item) =>
                                <EnhancedTableCell
                                    id={item.id}
                                    alignment={item.alignment}
                                    sort={item.sort}
                                    orderBy={orderBy}
                                    order={order}
                                    onRequestSort={handleRequestSort}
                                >
                                    {item.label}
                                </EnhancedTableCell>
                            )
                        }
                        <TableCell align={'center'}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        slicedRows
                            .map((msg) =>
                                <LearningMessageRow
                                    key={msg.message_id}
                                    message_object={msg}
                                    mappings={memoMappings}
                                    threshold={threshold}
                                    openComments={openComments}
                                    updateMessage={updateMessage}
                                    openLogs={openLogs}
                                    {...msg}
                                />
                            )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </>
}

export default MemoLearningMessageTable;