import React, {useEffect, useReducer, useRef, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Card, CardContent, Step, StepIcon, StepLabel, Stepper, TableContainer, Link} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RateReviewIcon from '@material-ui/icons/RateReview';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import {APP_STATUS, APP_STATUS_VERBOSE, SEAL_TYPE_VERBOSE, USER_TYPE} from "../../utils/constants";
import {getAppService, updateAppStatusService} from "../../services/app.service";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {useAppDetails} from "../Applications/hooks/useAppDetails.hook";
import ApproveModule from "./modules/Approve.module";
import ReviewAssessmentsModule from "./modules/ReviewAssessments.module";
import ConfigModule from "./modules/ConfigModule.module";
import ReviewPublishModules from "./modules/ReviewPublishModules.module";
import FilterNoneIcon from "@material-ui/icons/FilterNone";


const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
}));

const iconStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        borderRadius: "50%",
        padding: 8,
        marginTop: -10,
        borderStyle: "solid",
        borderWidth: "medium",
        borderColor: "grey",
        zIndex: 1
    },
    active_container: {
        borderColor: "limegreen"
    },
    completed_container: {
        backgroundColor: theme.palette.primary.main,
    },
    incomplete_container: {
        backgroundColor: "grey",
    },
    icon: {
        color: "white"
    }
}))


const STEPS = [
    {
        label: 'Approve',
        icon: CheckCircleIcon,
        component: ApproveModule,
        maximum_status: APP_STATUS.ADD_ASSESS_REQ_RECEIVED
    },
    {
        label: 'Review Instruments',
        icon: RateReviewIcon,
        component: ReviewAssessmentsModule,
        maximum_status: APP_STATUS.PENDING_ASSESS_GENERATED_APPROVE
    },
    {
        label: 'Configure Module',
        icon: PermDataSettingIcon,
        component: ConfigModule,
        maximum_status: APP_STATUS.PENDING_APPROVE_MODULES
    },
    {
        label: 'Review & Publish Modules',
        icon: FilterNoneIcon,
        component: ReviewPublishModules,
        maximum_status: APP_STATUS.ASSESSMENTS_READY
    },
];

function IconComponent(props) {

    const classes = iconStyles();

    const {active, completed, icon: Icon} = props;

    const icon_container_class = active ? `${classes.active_container} ${classes.completed_container}` :
        completed ? classes.completed_container : classes.incomplete_container

    return <div className={`${classes.container} ${icon_container_class}`}>
        <Icon className={classes.icon}/>
    </div>
}

function statusToStepConversion(status) {
    for(const step_index in STEPS) {
        if(status <= STEPS[step_index].maximum_status) return parseInt(step_index);
    }
    return STEPS.length - 1;
}

function loaderReducer(state, action = false) {
    if(action) {
        return state + 1;
    } else {
        return state - 1;
    }
}

function DetailsCertify(props) {
    const classes = useStyles();
    const history = useHistory();
    const {state = {}, search} = useLocation();
    const [loading, setLoading] = useReducer(loaderReducer, 0, (initValue) => initValue);
    const [app, setApp] = useState(state);
    const {application_id = new URLSearchParams(search).get('app_id'), app_status = APP_STATUS.DRAFT, dqseal_type} = app;
    const [status, setStatus] = useState(app_status);
    const [activeStep, setActiveStep] = useState(statusToStepConversion(status));
    const [selectedStep, setSelectedStep] = useState(activeStep);
    const ActiveComponent = STEPS[selectedStep].component;

    const {programInfo = {}, companyInfo = {}} = useAppDetails(application_id, setLoading);

    useEffect(() => {
        if(application_id) {
            retrieveApplication(application_id).then();
        } else {
            returnToMainMenu();
        }
    }, [])

    function returnToMainMenu() {
        history.push('/certify/list');
    }

    const updateApp = (updatedData) => {
        setApp(prevApp => ({ ...prevApp, ...updatedData }));
    };

    function updateStepByStatus(status, navigate = false) {
        setStatus(status);
        const step = statusToStepConversion(status)
        setActiveStep(step);
        if(navigate) setSelectedStep(step);
    }

    async function retrieveApplication(application_id) {
        setLoading(true);
        try {
            console.log('application_id: ', application_id)
            const result = await getAppService(application_id);
            console.log('result: ', result)
            if(!result.error && result.data) {
                const app = result.data;
                setApp(app);
                const {app_status} =  app;
                updateStepByStatus(app_status, true);
            } else {
                returnToMainMenu();
            }
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false);
    }

    async function fetchStatus(appID, fallback) {
        let status = fallback;
        try {
            const result = await getAppService(appID);
            if(result.data) {
                status = result.data.app_status;
            }
        }
        catch (e) {
            console.error(e);
        }
        return status;
    }

    async function updateStatus(updatedStatus, navigate = false, safeUpdate = false) {
        try {
            const currentStatus = safeUpdate ? await fetchStatus(application_id, status) : status;
            if(!safeUpdate || (safeUpdate && currentStatus < updatedStatus)) {
                const result = await updateAppStatusService(application_id, updatedStatus);
                if(!result.error && result.data.success) {
                    updateStepByStatus(updatedStatus, navigate);
                }
            }
        }
        catch (e) {
            //already toasted
        }
    }

    function forwardStep() {
        const nextStep = selectedStep + 1;
        if(nextStep <= activeStep) setSelectedStep(nextStep);
    }

    const applicationProps = {application_id, app_status: status, loading, setLoading, app, updateApp};
    const title = `${(programInfo.ProgramName || "")} ${companyInfo.NameOrg ? `(${companyInfo.NameOrg})` : ""}`

    return (
        <Container maxWidth={false}>
            <Card>
                <CardContent>
                    <Typography className={classes.title} variant={'h5'}>
                        {title}
                    </Typography>
                    <Typography  color="textSecondary">
                        {application_id} | {SEAL_TYPE_VERBOSE[dqseal_type]} | {
                                        APP_STATUS_VERBOSE[status]
                                    }
                    </Typography>
                </CardContent>
            </Card>
            <br/>
            <Stepper alternativeLabel activeStep={activeStep}>
                {
                    STEPS.map((step, i) => (
                        <Step
                            key={step.label}
                            onClick={() => (i <= activeStep ? setSelectedStep(i) : false)}
                            style={{cursor: (i <= activeStep ? "pointer" : "default")}}
                        >
                            <StepLabel StepIconComponent={IconComponent} StepIconProps={{icon: step.icon, active: selectedStep === i, completed: i <= activeStep}}>{step.label}</StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
            <br/>
            {
               <ActiveComponent updateStatus={updateStatus} updateStep={updateStepByStatus} forwardStep={forwardStep} {...applicationProps}/>
            }
            <LoaderWithBackDrop loading={loading > 0}/>
        </Container>
    );
}

export default DetailsCertify;