import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, Typography, Grid, 
    TableContainer, Table, TableHead, TableRow, 
    TableCell, TableBody, TablePagination, Box, Tabs, Tab} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import EnhancedTableCell, {getComparator} from "../../components/EnhancedTableCell/EnhancedTableCell.comp";
import performAnalysis from './utils/performAnalysis.util';
import AnalysisPieChart from "./components/AnalysisPieChart.comp";
import AnalysisBarChart from "./components/AnalysisBarChart.comp";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const headers = [
    { id: "message_id", label: "Message ID", alignment: "left", sort: true },
    { id: "message", label: "Message", alignment: "left", sort: true },
    { id: "level_9", label: "Level 9", alignment: "left", sort: true },
    { id: "new_level_9", label: "New Level 9", alignment: "left", sort: true },
    { id: "level_6", label: "Level 6", alignment: "left", sort: true },
    { id: "mb_no", label: "MB#", alignment: "left", sort: true },
    { id: "dq_no", label: "DQ#", alignment: "left", sort: true },
    { id: "category_4", label: "Category 4", alignment: "left", sort: true },
]

const useStyles = makeStyles((theme)=>({
    container: {
        padding: 20
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    wide_cell: {
        minWidth: '500px'
    }
}));

function StatusCard(props) {

    const {title, description} = props;
    const classes = useStyles();

    return <Card>
        <CardContent>
            <Typography variant={"h5"} align={'center'}>
                {title}
            </Typography>
            <br/>
            <Typography className={classes.title} variant={"h4"} align={'center'}>
                {description}
            </Typography>
        </CardContent>
    </Card>
}

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    return value;
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function AnalysisDetails(props) {

    const classes = useStyles();
    const [chartType, setChartType] = useState('pie');
    const [loading, setLoading] = useState(false);

    const {state = {}} = useLocation();
    const {application_id} = state;

    const [all_msgs_data, set_all_msgs_data] = useState([]);
    const [overall_accuracy, set_overall_accuracy] = useState(0.0);
    const [codes_modified, set_codes_modified] = useState(0);
    const [correct_codes, set_correct_codes] = useState(0);
    const [message_removed, set_message_removed] = useState(0);
    const [total_messages, set_total_messages] = useState(0);
    const [new_messages, set_new_messages] = useState(0);
    const [not_applicable, set_not_applicable] = useState(0);

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [searchText, setSearchText] = useState('');
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("message_id");

    const [allMsgsData, setAllMsgsData] = useState([]);

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleTabChange = (event, newValue) => {
        setChartType(newValue);
      };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
    };

    function setAllMsgsDataEx(data) {
        setAllMsgsData(data);
        setSearchText(searchText);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

          const {
            all_msgs_data,
            overall_accuracy,
            codes_modified,
            correct_codes,
            message_removed,
            total_messages,
            new_messages,
            not_applicable
          } = await performAnalysis(application_id);
    
          set_all_msgs_data(all_msgs_data);
          set_overall_accuracy(overall_accuracy);
          set_codes_modified(codes_modified);
          set_correct_codes(correct_codes);
          set_message_removed(message_removed);
          set_total_messages(total_messages);
          set_new_messages(new_messages);
          set_not_applicable(not_applicable);
          setLoading(false);

        };
    
        fetchData();
      }, [application_id]);

      useEffect(() => {
        setAllMsgsDataEx(all_msgs_data);
      }, [all_msgs_data]);

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }

    const filteredRows = allMsgsData.filter(field_filter_function);

    const pieChartData = {
    labels: ['Codes Modified', 'Correct Codes', 'Messages Deleted', 'New Messages', 'Not Applicable'],
    datasets: [
        {
        data: [
            codes_modified || 0,
            correct_codes || 0,
            message_removed || 0,
            new_messages || 0,
            not_applicable || 0
        ],
        }
    ]
    };

    const level_9Data = filteredRows.map((message) => message.level_9);

    const countMap = level_9Data.reduce((count, level) => {
      count[level] = (count[level] || 0) + 1;
      return count;
    }, {});
    
    const labels = Object.keys(countMap);
    const data = Object.values(countMap);
    
    const barChartData = {
      labels: labels,
      datasets: [
        {
          label: 'Level 9 Count',
          data: data,
        },
      ],
    };
      
    
    const slicedRows = filteredRows
    .sort(getComparator(order, orderBy)) //sorting
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) //pagination



    return (
    <Grid className={classes.container} container spacing={5} justifyContent={'center'}>
        <Grid item xs={4}>
            <StatusCard title={'Overall Accuracy'} description={overall_accuracy ? ''+(overall_accuracy * 100).toFixed(2) + "%": '-'}/>
        </Grid>
        <Grid item xs={4}>
            <StatusCard title={'Codes Modified'} description={codes_modified ? ''+codes_modified: '-'}/>
        </Grid>
        <Grid item xs={4}>
            <StatusCard title={'Correct Codes'} description={correct_codes ? ''+correct_codes: '-'}/>
        </Grid>
        <Grid item xs={4}>
            <StatusCard title={'Messages Deleted'} description={message_removed ? ''+message_removed: '-'}/>
        </Grid>
        <Grid item xs={4}>
            <StatusCard title={'Total Messages'} description={total_messages ? ''+total_messages: '-'}/>
        </Grid>
        <Grid item xs={4}>
            <StatusCard title={'New Messages'} description={new_messages ? ''+new_messages: '-'}/>
        </Grid>
        <Grid item xs={4}>
            <StatusCard title={'Not Applicable'} description={not_applicable ? ''+not_applicable: '-'}/>
        </Grid>
        <Grid item xs={12}>
            <Tabs value={chartType} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <Tab label="Messages Count" value="pie" />
                <Tab label="Level 9 Count" value="bar" />
            </Tabs>
            {chartType === 'pie' ? <AnalysisPieChart data={pieChartData} /> : <AnalysisBarChart data={barChartData} />}
        </Grid>
        <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'flex-end'} mb={2}>
                <TextField
                    variant="outlined"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search…"
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                onClick={() => setSearchText('')}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
            <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                headers.map((item) =>
                                    <EnhancedTableCell
                                        id={item.id}
                                        alignment={item.alignment}
                                        sort={item.sort}
                                        orderBy={orderBy}
                                        order={order}
                                        onRequestSort={handleRequestSort}
                                    >
                                        {item.label}
                                    </EnhancedTableCell>
                                )
                                }
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                slicedRows
                                    .map((message) => 
                                            <TableRow key={message.id}>
                                            <TableCell>{message.message_id}</TableCell>
                                            <TableCell className={classes.wide_cell}>{message.message}</TableCell>
                                            <TableCell>{message.level_9}</TableCell>
                                            <TableCell>{message.newLevel_9}</TableCell>
                                            <TableCell>{message.level_6}</TableCell>
                                            <TableCell>{message.mb_no}</TableCell>
                                            <TableCell>{message.dq_no}</TableCell>
                                            <TableCell>{message.category_4}</TableCell>
                                            <TableCell>{message.action}</TableCell>
                                            </TableRow>
                                    )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Grid>
        <LoaderWithBackDrop loading={loading}/>
        </Grid>
    
)

}

export default AnalysisDetails;