import {
    getLearningMsgsService,
    getMLMappingsService,
  } from "../../../services/app.service";
  
  async function performAnalysis(application_id) {
    let learningMessages = [];
    let mlMappings = [];
    try {
      const result_learning_msgs = await getLearningMsgsService(application_id);
      const result_ml_maps = await getMLMappingsService(application_id);
      if (!result_learning_msgs.error && !result_ml_maps.error) {
        learningMessages = result_learning_msgs.data.messages;
        mlMappings = result_ml_maps.data[0].mapping;
      }
    } catch (e) {
      console.error(e);
    }
  
    const modifiedMessages = [];
    const unchangedMessages = [];
  
    let codes_modified = 0;
    let overall_accuracy = 0.0;
    let correct_codes = 0;
    let message_removed = 0;
    let total_messages = 0;
    let new_messages = 0;
    let not_applicable = 0;
  
    learningMessages.forEach((learningMsg) => {
        const mlMapping = mlMappings.find((mlMap) => mlMap.message_id === learningMsg.message_id);
      
        if (mlMapping) {
          const modifiedMsg = {
            ...learningMsg,
            action: "",
            level_9: mlMapping.level_9,
            newLevel_9: learningMsg.level_9,
            message: mlMapping.message,
            newMessage: learningMsg.message,
          };
      
          if (mlMapping.level_9 !== learningMsg.level_9) {
            modifiedMsg.action += "Code Modified";
            codes_modified++;
          }
      
          if (mlMapping.message !== learningMsg.message) {
            if (modifiedMsg.action) {
              modifiedMsg.action += ", Message Modified";
            } else {
              modifiedMsg.action = "Message Modified";
            }
          }
      
          if (modifiedMsg.action) {
            modifiedMessages.push(modifiedMsg);
          } else {
            unchangedMessages.push({
              ...learningMsg,
              action: "-",
            });
          }
        } else {
            if (learningMsg.level_9 === "N/A") {
                not_applicable++;
              }
          unchangedMessages.push({
            ...learningMsg,
            action: "-",
          });
        }
      });
      
      mlMappings.forEach((mlMap) => {
        const learningMsg = learningMessages.find((learningMsg) => learningMsg.message_id === mlMap.message_id);
        if (!learningMsg) {
          modifiedMessages.push({
            ...mlMap,
            action: "Message Removed",
          });
          message_removed++;
        }
      });
      
      // Check for messages in learningMessages that are not in mlMappings
      learningMessages.forEach((learningMsg) => {
        const mlMap = mlMappings.find((mlMap) => mlMap.message === learningMsg.message);
        if (!mlMap) {
          modifiedMessages.push({
            ...learningMsg,
            action: "New Message",
          });
          new_messages++;
        }
      });
      
  
    total_messages = modifiedMessages.length + unchangedMessages.length;
    overall_accuracy = (total_messages - codes_modified) / total_messages;
  
    correct_codes = total_messages - codes_modified;
    const all_msgs_data = [...modifiedMessages, ...unchangedMessages];
    return {
      all_msgs_data: all_msgs_data,
      overall_accuracy: overall_accuracy,
      codes_modified: codes_modified,
      correct_codes: correct_codes,
      message_removed: message_removed,
      total_messages: total_messages,
      new_messages: new_messages,
      not_applicable: not_applicable
    };
  }
  
  export default performAnalysis;
  