import React from 'react';
import { Chart as ChartJS, BarElement, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);
ChartJS.register(ChartDataLabels);

const AnalysisBarChart = ({ data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 14,
            weight: 'bold',
          },
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const label = data.labels[context.dataIndex];
            const value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
      datalabels: {
        formatter: (value) => {
          return value;
        },
        color: 'white',
        font: {
          size: 14,
          weight: 'bold',
        },
        textAlign: 'center',
        textStrokeColor: '#000000',
        textStrokeWidth: 2,
        anchor: 'end',
        align: 'start',
        padding: 6,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
  };

  const numValues = data.labels.length;
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  
  const backgroundColor = Array.from({ length: numValues }, () => getRandomColor());



  const modifiedData = {
    ...data,
    datasets: [
      {
        ...data.datasets[0],
        backgroundColor,
      },
    ],
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <Bar data={modifiedData} options={options} />
    </div>
  );
};

export default AnalysisBarChart;
