import React, {useEffect, useRef, useState} from "react";
import {
    Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
    Box, IconButton, Typography, TextField, CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    getMsgCommentsService,
    postMsgCommentService, updateMsgCommentService,
} from "../../../services/app.service";
import {useAuth} from "../../../context/auth";

const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true};

function MsgCommentDialog(props) {

    const {open, onClose, onSend, messages, messageIndex, applicationID} = props;

    const { authUser } = useAuth();

    const dialogRef = useRef(null);
    const addCommentRef = useRef(null);
    const editCommentRef = useRef(null);
    const [comments, setComments] = useState(undefined);
    const [disableSend, setDisableSend] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);

    function handleDialogClose() {
        if(onClose){
            onClose(messageIndex, comments);
        }
        setComments(undefined);
        setEditIndex(-1);
    }

    async function handleSend() {
        const text = addCommentRef.current.value.trim();
        if(!text) return;
        setDisableSend(true);
        await postComment(text);
        addCommentRef.current.value = "";
        setDisableSend(false);
        if(onSend) {
            onSend();
        }
    }

    async function handleEdit(index) {
        const text = editCommentRef.current.value.trim();
        if(!text) return;
        setDisableSend(true);
        await editComment(index, text);
        editCommentRef.current.value = "";
        setDisableSend(false);
        setEditIndex(-1);
    }

    async function editComment(index, text) {
        try {
            const {message_id} = messages[messageIndex];
            const comment = {comment: text, time: Date.now(), sender: authUser.email, edited: true}
            const result = await updateMsgCommentService(applicationID, message_id, index, false, comment);
            if(result.data) {
                setComments(result.data);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    async function deleteComment(index) {
        try {
            const {message_id} = messages[messageIndex];
            const result = await updateMsgCommentService(applicationID, message_id, index, true);
            if(result.data) {
                setComments(result.data);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    async function postComment(text) {
        try {
            const {message_id} = messages[messageIndex];
            const comment = {comment: text, time: Date.now(), sender: authUser.email}
            const result = await postMsgCommentService(applicationID, message_id, comment);
            if(result.data) {
                setComments(result.data);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    async function loadComments() {
        moveCommentsDialogToBottom();
        try {
            const {message_id} = messages[messageIndex];
            const result = await getMsgCommentsService(applicationID, message_id)
            if(result.data) {
                setComments(result.data);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    function onDialogOpen() {
        loadComments().then(r => console.log("Comments loaded", r));
    }

    function moveCommentsDialogToBottom() {
        const node = dialogRef.current;
        if(node) {
            const element = Array.from(node.getElementsByClassName("comment")).pop();
            if(element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    // block: 'end',
                    // inline: 'nearest'
                });
            }
        }
    }

    useEffect(() => {
        moveCommentsDialogToBottom();
    }, [comments])

    return <Dialog ref={dialogRef} id={"comments-dialog"} open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'} scroll={"paper"}
                   TransitionProps={{onEntering: onDialogOpen}}>
        <DialogTitle id="form-dialog-title">
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box>Comments</Box>
                <IconButton onClick={() => handleDialogClose()}>
                    <CloseIcon/>
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
            {
                comments ?
                    comments.length > 0 ?
                        comments.map((c, i) => <>
                            <Card key={`comment_${i}`} className={"comment"}>
                                <CardContent style={{backgroundColor: "#e0e0e0"}}>
                                    <Box display={'flex'} flexDirection={'row'}>
                                        <Typography variant={'h6'} color={"textPrimary"}>
                                            {c.sender}
                                            <Typography style={{paddingLeft: 5}} variant={'body1'} component={'span'}>
                                                ({new Date(c.time).toLocaleString(undefined, date_options)})
                                            </Typography>
                                            {
                                                c.edited &&
                                                <Typography style={{color: "grey"}} variant={'body1'} component={'span'}>
                                                    <i> Edited</i>
                                                </Typography>
                                            }
                                        </Typography>
                                        <Box ml={'auto'}>
                                            {
                                                editIndex === i ?
                                                <IconButton onClick={() => setEditIndex(-1)} size={'small'}>
                                                    <CloseIcon/>
                                                </IconButton>
                                                :
                                                c.sender === authUser.email &&
                                                <>
                                                    <IconButton onClick={() => setEditIndex(i)} size={'small'}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton onClick={() => deleteComment(i)} size={'small'} style={{paddingLeft: 10}}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                    {
                                        editIndex === i ?
                                            <Box display={'flex'} flexDirection={'row'}>
                                                <TextField
                                                    inputRef={editCommentRef}
                                                    disabled={disableSend}
                                                    id={'edit_comment'}
                                                    variant="outlined"
                                                    autoFocus
                                                    placeholder={'Your edited comment here'}
                                                    defaultValue={c.comment}
                                                    fullWidth
                                                    multiline
                                                />
                                                <IconButton onClick={() => handleEdit(i)}>
                                                    <SendIcon/>
                                                </IconButton>
                                            </Box>
                                            :
                                            <Typography variant={"body1"} style={{ whiteSpace: 'pre-wrap' }}>
                                                {c.comment}
                                            </Typography>
                                    }
                                </CardContent>
                            </Card>
                            <br/>
                        </>)
                        : <Typography color={"secondary"} align={'center'}>No comments available</Typography>
                :
                    <Box textAlign={'center'}>
                        <CircularProgress/>
                    </Box>
            }
        </DialogContent>
        <DialogActions>
            {
                comments &&
                <>
                    <TextField
                        inputRef={addCommentRef}
                        disabled={disableSend}
                        id={'add_comment'}
                        variant="outlined"
                        autoFocus
                        placeholder={'Your comment here'}
                        fullWidth
                        multiline
                    />
                    <IconButton onClick={() => handleSend()}>
                        <SendIcon />
                    </IconButton>
                </>
            }
        </DialogActions>
    </Dialog>
}

export default MsgCommentDialog;