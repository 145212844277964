import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";
import {CURRENT_VERSION} from "../../utils/constants";

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.dqinstitute.org/">
                DQ LAB Ptd Ltd
            </Link>{' '}
            {new Date().getFullYear()}
            {`. (v-${CURRENT_VERSION})`}
        </Typography>
    );
}

export default Copyright;