import React from 'react';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {Button, Grid, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {PROGRAM_TYPES_MAP} from "../../../utils/META/cat_106";
import {PROGRAM_TARGETS_MAP} from "../../../utils/META/cat_108";
import {TARGET_AGE_MAP} from "../../../utils/META/cat_109";
import {PROGRAM_VENUE_MAP} from "../../../utils/META/cat_111";
import {PROGRAM_DURATION_MAP} from "../../../utils/META/cat_112";
import {PROGRAM_STATUS_MAP} from "../../../utils/META/cat_121";
import {SEAL_TYPES, SEAL_TYPES_MAP} from "../../../utils/META/cat_seal_types";
import {REGIONS_MAP} from "../../../utils/META/cat_101";
import {RESOURCES_FORMAT_MAP} from "../../../utils/META/cat_115";
import {RESOURCES_TYPE_MAP} from "../../../utils/META/cat_114";
import {DEVICES_MAP} from "../../../utils/META/cat_116";
import {PROGRAM_COST_MAP} from "../../../utils/META/cat_113";
import isURL from 'validator/lib/isURL';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    label: {
        textAlign: 'right',
        marginRight: theme.spacing(2),
        wordBreak: "break-word"
    },
    value: {
        textAlign: 'left',
        marginTop: theme.spacing(0.8),
        marginLeft: theme.spacing(2),
        wordBreak: "break-word"
    },
    link: {
        textDecoration: 'underline',
        // margin: '0 0 4px 0',
    }
}));

const key_desc = {
    ProgramName: "Title of program",
    DQSealType: "Powered by DQ Package",
    // ProgramType: "Type of Program",
    // ProgramTypeOther: "More details About Type",
    ProgramAims: "Objectives of program",
    ResourcesType: "Type of resources",
    ResourcesTypeOther:"Other type of resources",
    ResourcesFormat:"Format of resources",
    ResourcesFormatOther:"Other format of resources",
    ProgramTarget: "Target audience",
    ProgramTargetOther:'Other type of target audience',
    ProgramTargetMinAge:'Program target minimum age',
    ProgramTargetMaxAge:'Program target maximum age',
    // TargetAge: "Target Age group",
    TargetCountry: "Target countries",
    Devices:"Device(s) needed by target audience",
    DeviceTypeOther:"Other type of device(s)",
    // ProgramDescription: "Description of program",
    // ProgramDelivery: "Delivery of program",
    ProgramVenue: "Venue(s) where program is conducted",
    ProgramVenueOther:"Other type of venue",
    ProgramDuration: "Duration to complete the program",
    OtherDurationHours:"More than 20hrs duration",
    // RefResource: "Resources required",
    StartYear: "Year of program launch",
    Status: "Current status of program",
    Links: "Link to program",
    ProgramPrice: "Cost of program",
    Success: "Definition of success for program",
    SuccessMeasure: "Measurement of success for program",
    KPITrack: "Process to meet the program’s objectives",
    // RefLink: "Weblink that best describes your program",
    RefFramework: "Is your program already aligned with any existing framework?",
    RefAlignment: "Framework your program is aligned with"
}

const key_val = {
    DQSealType: (value)=> SEAL_TYPES_MAP[value] && SEAL_TYPES_MAP[value].label ? SEAL_TYPES_MAP[value].label : value,
    // ProgramType: (value) => PROGRAM_TYPES_MAP[value] && PROGRAM_TYPES_MAP[value].label ? PROGRAM_TYPES_MAP[value].label : value,
    ResourcesType: (value) => {
        return value ? value.split(', ').map(val => RESOURCES_TYPE_MAP[val]?.label || val).join(', ') : '';
    },
    ResourcesFormat: (value) => {
        return value ? value.split(', ').map(val => RESOURCES_FORMAT_MAP[val]?.label || val).join(', ') : '';
    },
    Devices: (value) => {
        return value ? value.split(', ').map(val => DEVICES_MAP[val]?.label || val).join(', ') : '';
    },
    ProgramTarget: (value) => {
        return value ? value.split(', ').map(val => PROGRAM_TARGETS_MAP[val]?.label || val).join(', ') : '';
    },
    TargetCountry: (value) => {
        return value ? value.split(', ').map(val => REGIONS_MAP[val]?.name || val).join(', ') : '';
    },
    ProgramVenue: (value) => {
        return value ? value.split(', ').map(val => PROGRAM_VENUE_MAP[val]?.label || val).join(', ') : '';
    },
    ProgramDuration: (value) => {
        return value ? value.split(', ').map(val => PROGRAM_DURATION_MAP[val]?.label || val).join(', ') : '';
    },
    Status: (value) => PROGRAM_STATUS_MAP[value] && PROGRAM_STATUS_MAP[value].label ? PROGRAM_STATUS_MAP[value].label : value,
    ProgramPrice: (value) => PROGRAM_COST_MAP[value] && PROGRAM_COST_MAP[value].label ? PROGRAM_COST_MAP[value].label : value,
    RefFramework: (value) => {
        return value === 'yes' ? 'Yes' : 'No';
    },
}

function ProgramInformation(props) {

    const {progInfo, application_id} = props;

    const classes = useStyles();

    const history = useHistory();

    const editProgramInfo = (e) => {
        history.push('/application/edit/program', {existingValues: progInfo, application_id});
    }

    return <Container maxWidth={false} className={classes.container} component={Paper}>
        <Grid container spacing={3}>
            <Button variant={'outlined'} style={{marginLeft: 'auto'}} onClick={editProgramInfo}>
                Edit
            </Button>
            {Object.entries(progInfo).map(([key, value]) =>
                value && (
                <Grid item xs={12} key={key}>
                    <Grid container justify="center" alignItems="flex-start">
                        <Grid item xs={6}>
                            <Typography variant="h6" className={classes.label}>{key_desc[key]}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {key === 'Links' && isURL(value) ? (
                                <a href={value} target="_blank" className={classes.link} >
                                    <Typography variant="body1" className={classes.value}>
                                        {value}
                                    </Typography>
                                </a>
                            ) : (
                            <Typography variant="body1" className={classes.value}>{key_val[key] ? key_val[key](value) : value}</Typography>)}
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </Container>
}

export default ProgramInformation;
