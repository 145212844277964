import React, {useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {getAnalysisModService, getAnalysisService} from "../../../services/app.service";
import {ANALYSIS_TYPES} from "../../../utils/constants";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

function Level9CoverageComp(props) {

    const {application_id, reload} = props;
    const [analysis, setAnalysis] = useState({});
    const [loading, setLoading] = useState(false);

    async function fetchAnalysis() {
        try {
            setLoading(true);
            const result = await getAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_9_COVERAGE);
            if(!result.error) {
                setAnalysis(result.data);
            }
            setLoading(false);
        }
        catch (e) {
            console.error(e);
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAnalysis().then();
    }, [reload])

    return <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Level 9</TableCell>
                        <TableCell>Count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        analysis.data && analysis.data.map((item) =>
                           <TableRow>
                               <TableCell>{item.level9}</TableCell>
                               <TableCell>{item.count}</TableCell>
                           </TableRow>
                        )
                    }
                    {
                        analysis.grand_total &&
                            <TableRow>
                                <TableCell style={{fontWeight: "bold"}}>{analysis.grand_total.level9}</TableCell>
                                <TableCell style={{fontWeight: "bold"}}>{analysis.grand_total.count}</TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table>
            <LoaderWithBackDrop loading={loading}/>
        </TableContainer>
    </>
}

export default Level9CoverageComp;