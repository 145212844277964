import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {APP_STATUS} from "../../utils/constants";
import {getOverviewDataService} from "../../services/app.service";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme)=>({
    container: {
        padding: 20
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "bold"
    }
}));

function StatusCard(props) {

    const {title, description} = props;
    const classes = useStyles();

    return <Card>
        <CardContent>
            <Typography variant={"h5"} align={'center'}>
                {title}
            </Typography>
            <br/>
            <Typography className={classes.title} variant={"h4"} align={'center'}>
                {description}
            </Typography>
        </CardContent>
    </Card>
}

function Overview(props) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [statsData, setStatsData] = useState({
        pending_payment: 0,
        payment_verification_required: 0,
        halted: 0,
        pending_review: 0,
        in_progress: 0,
        completed: 0,
    });

    async function fetchOverviewData() {
        setLoading(true);
        try {
            const result = await getOverviewDataService();
            console.log('result: ', result);
            if(!result.error) {
                let pending_payment = 0;
                let payment_verification_required = 0;
                let halted = 0;
                let pending_review = 0;
                let in_progress = 0;
                let completed = 0;
                if (result.data.status_map) {
                    for (let item of result.data.status_map) {
                        switch (item._id) {
                            case APP_STATUS.DRAFT:
                            case APP_STATUS.PENDING_PAYMENT:
                                pending_payment += item.countC;
                                break;
                            case APP_STATUS.PENDING_APPROVAL:
                                payment_verification_required += item.countC;
                                break;
                            case APP_STATUS.IN_PROGRESS_COLLECT_DATA:
                                in_progress += item.countC;
                                break;
                            case APP_STATUS.HALTED_COLLECT_DATA:
                                halted += item.countC;
                                break;
                            case APP_STATUS.IN_PROGRESS_PROCESS_DATA:
                                in_progress += item.countC;
                                break;
                            case APP_STATUS.HALTED_PROCESS_DATA:
                                halted += item.countC;
                                break;
                            case APP_STATUS.PENDING_REVIEW_MAPPING:
                            case APP_STATUS.PENDING_ANALYSIS:
                            case APP_STATUS.PENDING_REVIEW_REPORT:
                                pending_review += item.countC;
                                break;
                            case APP_STATUS.HALTED_REPORT:
                                halted += item.countC;
                                break;
                            case APP_STATUS.COMPLETED:
                                completed += item.countC;
                                break;
                        }
                    }

                }
                setStatsData({
                    pending_payment,
                    payment_verification_required,
                    halted,
                    pending_review,
                    in_progress,
                    completed,
                })
            }
        }
        catch (e) {
            // already toasted the error
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchOverviewData().then((res) => `fetch initiated, ${res}`);
    }, [])


    return <>
        <Grid className={classes.container} container spacing={5} justifyContent={'center'}>
            <Grid item xs={6}>
                <StatusCard title={'Pending Payment'} description={statsData.pending_payment ? ''+statsData.pending_payment: '-'}/>
            </Grid>
            <Grid item xs={6}>
                <StatusCard title={'Payment Verification Needed'} description={statsData.payment_verification_required ? ''+statsData.payment_verification_required: '-'}/>
            </Grid>
            <Grid item xs={6}>
                <StatusCard title={'Pending Review'} description={statsData.pending_review ? ''+statsData.pending_review: '-'}/>
            </Grid>
            <Grid item xs={6}>
                <StatusCard title={'In-Progress'} description={statsData.in_progress ? ''+statsData.in_progress: '-'}/>
            </Grid>
            <Grid item xs={6}>
                <StatusCard title={'Failed'} description={statsData.halted ? ''+statsData.halted: '-'}/>
            </Grid>
            <Grid item xs={6}>
                <StatusCard title={'Completed'} description={statsData.completed ? ''+statsData.completed: '-'}/>
            </Grid>
        </Grid>
        <LoaderWithBackDrop loading={loading}/>
    </>


}

export default Overview;