import React, {useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {getAnalysisModService, getAnalysisService} from "../../../services/app.service";
import {ANALYSIS_TYPES} from "../../../utils/constants";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

function Level6DepthComp(props) {

    const {application_id, reload} = props;

    const [analysis, setAnalysis] = useState({});
    const [loading, setLoading] = useState(false);

    async function fetchAnalysis() {
        try {
            setLoading(true);
            const result = await getAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_DEPTH);
            if(!result.error) {
                setAnalysis(result.data);
                console.log(result.data);
            }
            setLoading(false);
        }
        catch (e) {
            console.error(e);
            // already toasted the error
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAnalysis().then();
    }, [reload])

    return <>
        <br/>
            <Grid container spacing={2}>
                <Grid item>
                    <TextField id='assumption1' variant="outlined" label={'Level6 Depth %'} value={analysis.assumption1 ? `${analysis.assumption1}%` : ""} InputProps={{readOnly: true}}/>
                </Grid>
                <Grid item>
                    <TextField id='assumption2' variant="outlined" label={'# of Messages'} value={analysis.assumption2 || ""} InputProps={{readOnly: true}}/>
                </Grid>
            </Grid>
        <br/>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>MB#</TableCell>
                        <TableCell>Sum of Count</TableCell>
                        <TableCell># of Level8 in Level6</TableCell>
                        <TableCell>Depth % of Learning Message</TableCell>
                        <TableCell>Covered or not</TableCell>
                        <TableCell>Count of Message</TableCell>
                        <TableCell>Enough or not</TableCell>
                        <TableCell>Pass or not</TableCell>
                        <TableCell>DQC#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        analysis.data && analysis.data.map((item) =>
                           <TableRow>
                               <TableCell>{item.mb_no}</TableCell>
                               <TableCell>{item.level8_count}</TableCell>
                               <TableCell>{item.level8_total}</TableCell>
                               <TableCell>{item.depth}%</TableCell>
                               <TableCell>{item.is_covered ? 'Yes' : 'No'}</TableCell>
                               <TableCell>{item.level9_count}</TableCell>
                               <TableCell>{item.enough ? 'Yes' : 'No'}</TableCell>
                               <TableCell>{item.pass}</TableCell>
                               <TableCell>{item.dqc_no}</TableCell>
                           </TableRow>
                        )
                    }
                    {/*{*/}
                    {/*    analysis.grand_total &&*/}
                    {/*        <TableRow>*/}
                    {/*            <TableCell style={{fontWeight: "bold"}}>{analysis.grand_total.level8}</TableCell>*/}
                    {/*            <TableCell style={{fontWeight: "bold"}}>{analysis.grand_total.count}</TableCell>*/}
                    {/*            <TableCell style={{fontWeight: "bold"}}>{analysis.grand_total.total}</TableCell>*/}
                    {/*            <TableCell style={{fontWeight: "bold"}}>{analysis.grand_total.coverage}%</TableCell>*/}
                    {/*            <TableCell style={{fontWeight: "bold"}}>{analysis.grand_total.is_covered ? 'Yes' : 'No'}</TableCell>*/}
                    {/*        </TableRow>*/}
                    {/*}*/}
                </TableBody>
            </Table>
            <LoaderWithBackDrop loading={loading}/>
        </TableContainer>
    </>
}

export default Level6DepthComp;