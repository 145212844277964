import { useEffect } from 'react';
import {getAnalysisService} from "../../../services/app.service";
import {ANALYSIS_TYPES} from "../../../utils/constants";
import {asciiSortCompareFunWithKey} from "../../../utils/utils";

export function useBreadth(application_id, setAnalysis, setLoaderState) {

    const loadAnalysis = async () => {
        if(setLoaderState) setLoaderState(true);
        try {
            const result = await getAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_BREADTH);
            if(!result.error) {
                const result2 = await getAnalysisService(application_id, ANALYSIS_TYPES.LEVEL_6_ENOUGH);
                const enoughMap = result2.data.data.reduce((acc, cur) => {
                    acc[cur.dqc_no] = cur.enough;
                    return acc;
                }, {});

                const finalMatched = result.data.data.map(item => {
                    const isEnoughAndAligned = item.aligned === 1 && enoughMap[item.dqc_no] === true;
                    item.final_aligned = isEnoughAndAligned ? 1 : 0;
                    return item;
                });

                const analysis = {
                    data: finalMatched
                };
                analysis.data.sort(asciiSortCompareFunWithKey('dqc_no'))
                setAnalysis(analysis);
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
        if(setLoaderState) setLoaderState(false);
    }

    useEffect( ()=> {
        loadAnalysis().then((res) => `level 6 depth fetch initiated, ${res}`);
    }, [])
}