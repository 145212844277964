import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "../../context/auth";
import {ACL_ENABLE_ENDPOINTS, URL_PERMS_MAP, USER_ACCESS_LEVEL} from "../../utils/constants";
import {toastError} from "../../utils/utils";


const PrivateRouteWithLayout = props => {
    const { layout: Layout, component: Component, ...rest } = props;
    const { authToken, authUser } = useAuth();

    useEffect(()=> {
        if (!authUser) {
            toastError("Forbidden", "you are not authorized to access this page, \nPlease login using valid credentials!")
        }

        if (authUser && props.location.pathname !== '/auth/confirm/email') {
            if (!authUser.is_email_verified) {
                toastError("Email Not Verified", "you are not a legitimate user in our system, please verify your email address first")
            }
            if (!authUser.is_active) {
                toastError("User not active", "this user is not active yet, you need permission from developers, request access at dev@dqlab.net")
            }
        }

    }, [authUser]);

    const isPermissible = (pathname) => {
        if (authUser && authUser.permissions) {
            if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
                const perms = authUser.permissions;
                const path_parts = pathname.split('/');
                if (path_parts.length > 2) {
                    const effective_path = [path_parts[1], path_parts[2]].join('/');
                    if (ACL_ENABLE_ENDPOINTS.includes(effective_path)) {
                        const url_perm_key = URL_PERMS_MAP[effective_path];
                        return !!perms[url_perm_key];
                    }
                }


            }
        }
        return true
    }

    return (
        <Route
            {...rest}
            render={matchProps => (
                authToken && authUser ? (
                    authUser.is_email_verified || matchProps.location.pathname === '/auth/confirm/email' ? (
                            isPermissible(matchProps.location.pathname) ? <Layout>
                                <Component {...matchProps} />
                            </Layout> : <Redirect
                                to={{ pathname: "/", referer: matchProps.location}}
                            />
                        )
                     : <Redirect
                            to={{ pathname: "/auth/confirm/email", referer: matchProps.location}}
                        />
                ) : (
                    <Redirect
                        to={{ pathname: "/login", referer: matchProps.location}}
                    />
                )
            )}
        />
    );

};

export default PrivateRouteWithLayout;
