import React, { useState, useEffect } from 'react';
import { Grid, Tabs, Tab } from "@material-ui/core";
import performAnalysis from '../utils/performAnalysis.util';
import AnalysisPieChart from "./AnalysisPieChart.comp";
import AnalysisBarChart from "./AnalysisBarChart.comp";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const AllAnalysisChartsData = ({ applications }) => {
  const [loading, setLoading] = useState(false);

  const [applicationData, setApplicationData] = useState([]);
  const [chartType, setChartType] = useState('pie');

  const handleTabChange = (event, newValue) => {
    setChartType(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      // Assuming we have an applications array with application_id for each application
      const applicationDetails = await Promise.all(
        applications.map(async (application) => {
          setLoading(true)

          const analysisData = await performAnalysis(application.application_id);
          const { 
            all_msgs_data,
            overall_accuracy,
            codes_modified,
            correct_codes,
            message_removed,
            total_messages,
            new_messages, 
            not_applicable} = analysisData;
            const level_9Data = all_msgs_data.map((message) => message.level_9);
            setLoading(false);
          return { level_9Data, codes_modified, correct_codes, message_removed, new_messages, not_applicable };
        })
      );

      setApplicationData(applicationDetails);

    };

    fetchData();
  }, [applications]);

  const sumValues = (key) => {
    return applicationData.reduce((sum, data) => sum + data[key], 0);
  };

  const totalCodesModified = sumValues('codes_modified');
  const totalCorrectCodes = sumValues('correct_codes');
  const totalMessageRemoved = sumValues('message_removed');
  const totalNewMessages = sumValues('new_messages');
  const totalNotApplicable = sumValues('not_applicable');

  const pieChartData = {
    labels: ['Codes Modified', 'Correct Codes', 'Messages Deleted', 'New Messages', 'Not Applicable'],
    datasets: [
      {
        data: [
            totalCodesModified || 0,
            totalCorrectCodes || 0,
            totalMessageRemoved || 0,
            totalNewMessages || 0,
            totalNotApplicable || 0
        ],
      }
    ]
  };

  const level_9Data = applicationData.flatMap((data) => data.level_9Data);

  const countMap = level_9Data.reduce((count, level) => {
    count[level] = (count[level] || 0) + 1;
    return count;
  }, {});

  const labels = Object.keys(countMap);
  const data = Object.values(countMap);
  
  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Level 9 Count',
        data: data,
      },
    ],
  };

  return (
    <Grid item xs={12}>
    <Tabs value={chartType} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <Tab label="Messages Count" value="pie" />
        <Tab label="Level 9 Count" value="bar" />
    </Tabs>
    {chartType === 'pie' ? <AnalysisPieChart data={pieChartData} /> : <AnalysisBarChart data={barChartData} />}
    <LoaderWithBackDrop loading={loading}/>
    </Grid>
  );
};

export default AllAnalysisChartsData;
