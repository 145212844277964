import React, {useEffect, useState, memo} from "react";
import {
    extractPossibleLevel5,
    extractPossibleLevel6,
    extractPossibleLevel8,
    extractPossibleLevel9,
    Level5FilterMapping,
    Level6BackwardMapping,
    Level6FilterMapping,
    Level8BackwardMapping,
    Level8FilterMapping, Level9BackwardMapping, Level9ForwardMapping
} from "../../../utils/level_mapping_helper";
import {
    Badge, TableCell, TableRow,
    Tooltip, TextField, IconButton,
    Typography, Box, Chip
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SearchableDropdown from "./SearchableDropdown.comp";
import ChatIcon from '@material-ui/icons/Chat';
import HistoryIcon from '@material-ui/icons/History';
import {LM_TYPE} from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    clickable_text: {
        borderBottom: "1px dotted #000",
        cursor: "pointer"
    },
    message_tf: {
        minWidth: '500px'
    },
    read_only_tf: {
        minWidth: '60px'
    },
    modified_row: {
        borderLeft: '5px solid orange',
    },
    deleted_row: {
        borderLeft: '5px solid red',
        backgroundColor: '#ffcccc'
    }
}))

const TooltipContent = (naming) => naming ? <Typography style={{color: "white"}}>{naming.full_name}<hr style={{backgroundColor: "white"}}/><span>{naming.definition}</span></Typography> : "";

const MemoLearningMessageRow = memo(LearningMessageRow);
export default MemoLearningMessageRow;
function LearningMessageRow(props) {

    const classes = useStyles();

    const {message_object: message, index, mappings, updateMessage, openComments, openLogs, is_deleted} = props;

    const [availableLevel5, setAvailableLevel5] = useState([]);
    const [availableLevel6, setAvailableLevel6] = useState([]);
    const [availableLevel8, setAvailableLevel8] = useState([]);
    const [availableLevel9, setAvailableLevel9] = useState([]);

    const [selectedLevel5, setSelectedLevel5] = useState(message.level_5);
    const [selectedLevel6, setSelectedLevel6] = useState(message.level_6);
    const [selectedLevel8, setSelectedLevel8] = useState(message.level_8);
    const [selectedLevel9, setSelectedLevel9] = useState(message.level_9);
    const [selectedMB, setSelectedMB] = useState(message.mb_no);
    const [selectedDQC, setSelectedDQC] = useState(message.dqc_no);
    const [selectedDQ, setSelectedDQ] = useState(message.dq_no);
    const [selectedCat4, setSelectedCat4] = useState(message.category_4);

    const [firstTime, setFirstTime] = useState(true);

    const isLevelNA = selectedLevel5 === "N/A" || selectedLevel6 === "N/A" || selectedLevel8 === "N/A" || selectedLevel9 === "N/A"
    const isModified = message.level_9_sim === 100;

    const {level5_namings, level6_namings, level8_namings, level9_namings} = mappings;
    const level5_tooltip = level5_namings[selectedLevel5] && !isLevelNA ? TooltipContent(level5_namings[selectedLevel5]) : "";
    const level6_tooltip = level6_namings[selectedLevel6] && !isLevelNA ? TooltipContent(level6_namings[selectedLevel6]) : "";
    const level8_tooltip = level8_namings[selectedLevel8] && !isLevelNA ? TooltipContent(level8_namings[selectedLevel8]) : "";
    const level9_tooltip = level9_namings[selectedLevel9] && !isLevelNA ? TooltipContent(level9_namings[selectedLevel9]) : "";

    useEffect(() => {

        const possible_level5 = extractPossibleLevel5(mappings);
        const possible_level6 = extractPossibleLevel6(mappings);
        const possible_level8 = extractPossibleLevel8(mappings);
        const possible_level9 = extractPossibleLevel9(mappings);

        setAvailableLevel5(possible_level5);
        if(!isLevelNA && possible_level5.includes(message.level_5)) {
            const {level6, level8, level9} = Level5FilterMapping(mappings, message.level_5); //extract specific levels w.r.t level 5
            setAvailableLevel6(level6); //set specific possible level 6
            if(level6.includes(message.level_6)) {
                const {level8, level9} = Level6FilterMapping(mappings, message.level_6); //extract specific levels w.r.t level 6
                setAvailableLevel8(level8); //set specific possible level 8
                if(level8.includes(message.level_8)) {
                    const {level9} = Level8FilterMapping(mappings, message.level_8);  //extract specific levels w.r.t level 8
                    setAvailableLevel9(level9); //set specific possible level 9
                } else {
                    setAvailableLevel9(level9);
                }
            } else {
                setAvailableLevel8(level8);
                setAvailableLevel9(level9);
            }
        }
        else {
            setAvailableLevel6(possible_level6);
            setAvailableLevel8(possible_level8);
            setAvailableLevel9(possible_level9);
        }
    }, [])

    function onChangeToNA() {
        updateMessage(index, {
            level_5: "N/A",
            level_6: "N/A",
            level_8: "N/A",
            level_9: "N/A",
            mb_no: "N/A",
            dqc_no: "N/A",
            dq_no: "N/A",
            category_4: "N/A",
        });
        setSelectedLevel9("N/A");
        setSelectedLevel8("N/A");
        setSelectedLevel6("N/A");
        setSelectedLevel5("N/A");
        setSelectedMB("N/A");
        setSelectedDQC("N/A");
        setSelectedDQ("N/A");
        setSelectedCat4("N/A");
        updateOptionsWithAll();
    }

    function updateOptionsWithAll() {
        setAvailableLevel5(extractPossibleLevel5(mappings));
        setAvailableLevel6(extractPossibleLevel6(mappings));
        setAvailableLevel8(extractPossibleLevel8(mappings));
        setAvailableLevel9(extractPossibleLevel9(mappings));
    }

    function updateOptionsWithLevel5(level5) {
        if(level5 !== null && level5 !== "N/A") {
            const {level6, level8, level9} = Level5FilterMapping(mappings, level5);
            setAvailableLevel6(level6);
            setAvailableLevel8(level8);
            setAvailableLevel9(level9);
        }
    }

    function updateOptionsWithLevel6(level6) {
        if(level6 !== null && level6 !== "N/A") {
            const {level8, level9} = Level6FilterMapping(mappings, level6);
            setAvailableLevel8(level8);
            setAvailableLevel9(level9);
        }
    }

    function updateOptionsWithLevel8(level8) {
        if(level8 !== null && level8 !== "N/A") {
            const {level9} = Level8FilterMapping(mappings, level8);
            setAvailableLevel9(level9);
        }
    }

    function onChangeLevel5(level5) {
        if(level5 !== null) {
            if(level5 === "N/A") return onChangeToNA();
            //updating options
            updateOptionsWithLevel5(level5);
            updateMessage(index, {level_5: level5, level_9_sim: 100})
        }
        else {
            updateOptionsWithAll();
        }
    }

    function onChangeLevel6(level6) {
        if(level6 !== null) {
            if(level6 === "N/A") return onChangeToNA();
            //updating options
            updateOptionsWithLevel6(level6)
            //updating previous selection
            const {level5 = null} = Level6BackwardMapping(mappings, level6);
            setSelectedLevel5(level5);
            updateMessage(index, {level_5: level5, level_6: level6, level_9_sim: 100})
        }
        else {
            updateOptionsWithLevel5(selectedLevel5);
        }
    }

    function onChangeLevel8(level8) {
        if(level8 !== null) {
            if(level8 === "N/A") return onChangeToNA();
            //updating options
            updateOptionsWithLevel8(level8)
            //updating previous selection
            const {level5 = null, level6 = null} = Level8BackwardMapping(mappings, level8);
            setSelectedLevel6(level6);
            setSelectedLevel5(level5);
            updateMessage(index, {level_5: level5, level_6: level6, level_8: level8, level_9_sim: 100})
        }
        else {
            updateOptionsWithLevel6(selectedLevel6);
        }
    }

    function onChangeLevel9(level9) {
        if(level9 !== null) {
            if(level9 === "N/A") return onChangeToNA();
            //updating previous selection
            const result = Level9BackwardMapping(mappings, level9);
            const {level5 = null, level6 = null, level8 = null} = result;
            setSelectedLevel8(level8);
            setSelectedLevel6(level6);
            setSelectedLevel5(level5);
            //updating forward selection
            const {mb_no = "", dqc_no = "", dq_no = "", category_4 = ""} = Level9ForwardMapping(mappings, level9);
            setSelectedMB(mb_no);
            setSelectedDQC(dqc_no);
            setSelectedDQ(dq_no);
            setSelectedCat4(category_4);
            //updating message
            updateMessage(index, {
                level_5: level5,
                level_6: level6,
                level_8: level8,
                level_9: level9,
                mb_no: mb_no,
                dqc_no: dqc_no,
                dq_no: dq_no,
                category_4: category_4,
                level_9_sim: 100,
            });
        }
        else {
            updateOptionsWithLevel8(selectedLevel8);
        }
    }

    // function changeLevel9RelatedFields(level9) {
    //     const {mb_no = "", dqc_no = "", dq_no = "", category_4 = ""} = Level9ForwardMapping(mappings, level9);
    //     setSelectedMB(mb_no);
    //     setSelectedDQC(dqc_no);
    //     setSelectedDQ(dq_no);
    //     setSelectedCat4(category_4);
    // }

    return <TableRow className={is_deleted ? classes.deleted_row : isModified ? classes.modified_row : undefined}>
        <TableCell>
            {message.message_id}
        </TableCell>
        <TableCell className={classes.message_tf}>
            {
                <span>
                {
                    message.message.split('\n').map((text, text_idx) =>
                        <>
                            {text_idx !== 0 && <br/>}
                            <span>{text}</span>
                        </>)
                }
                </span>
            }
            {
                message.type === LM_TYPE.INSTRUMENT ?
                    <>
                        <br/>
                        <br/>
                        {
                            message.responses.map((resp) =>
                                <Chip
                                    style={{marginRight: 5, marginTop: 5}}
                                    size="small"
                                    label={resp}
                                    clickable={false}
                                />
                            )
                        }
                    </>
                    : null
            }
        </TableCell>
        <TableCell>
            <SearchableDropdown
                id='level5'
                selected={selectedLevel5}
                setSelected={setSelectedLevel5}
                defaultValue={message.level_5}
                options={["N/A", ...availableLevel5]}
                formatOption={(option) => option in level5_namings ? `${option} (${level5_namings[option].full_name})` : option}
                onChange={onChangeLevel5}
                tooltip={level5_tooltip}
                optionsTooltips={Object.fromEntries(["N/A", ...availableLevel5].map((o) => [o, TooltipContent(level5_namings[o])]))}
            />
        </TableCell>
        <TableCell>
            <SearchableDropdown
                id='level6'
                selected={selectedLevel6}
                setSelected={setSelectedLevel6}
                defaultValue={message.level_6}
                options={["N/A", ...availableLevel6]}
                formatOption={(option) => option in level6_namings ? `${option} (${level6_namings[option].full_name})` : option}
                onChange={onChangeLevel6}
                tooltip={level6_tooltip}
                optionsTooltips={Object.fromEntries(["N/A", ...availableLevel6].map((o) => [o, TooltipContent(level6_namings[o])]))}
            />
        </TableCell>
        <TableCell>
            <SearchableDropdown
                id='level8'
                selected={selectedLevel8}
                setSelected={setSelectedLevel8}
                defaultValue={message.level_8}
                options={["N/A", ...availableLevel8]}
                formatOption={(option) => option in level8_namings ? `${option} (${level8_namings[option].full_name})` : option}
                onChange={onChangeLevel8}
                tooltip={level8_tooltip}
                optionsTooltips={Object.fromEntries(["N/A", ...availableLevel8].map((o) => [o, TooltipContent(level8_namings[o])]))}
            />
        </TableCell>
        <TableCell>
            <SearchableDropdown
                id='level9'
                selected={selectedLevel9}
                setSelected={setSelectedLevel9}
                defaultValue={message.level_9}
                options={["N/A", ...availableLevel9]}
                formatOption={(option) => option in level9_namings ? `${option} (${level9_namings[option].full_name})` : option}
                onChange={onChangeLevel9}
                tooltip={level9_tooltip}
                optionsTooltips={Object.fromEntries(["N/A", ...availableLevel9].map((o) => [o, TooltipContent(level9_namings[o])]))}
            />
        </TableCell>
        <TableCell>
            <TextField className={classes.read_only_tf} id='mb_no' value={selectedMB} variant="outlined" size="small" InputProps={{readOnly: true}}/>
        </TableCell>
        <TableCell>
            <TextField className={classes.read_only_tf} id='dqc_no' value={selectedDQC} variant="outlined" size="small" InputProps={{readOnly: true}}/>
        </TableCell>
        <TableCell>
            <TextField className={classes.read_only_tf} id='dq_no' value={selectedDQ} variant="outlined" size="small" InputProps={{readOnly: true}}/>
        </TableCell>
        <TableCell>
            <TextField className={classes.read_only_tf} id='category_4' value={selectedCat4} variant="outlined" size="small" InputProps={{readOnly: true}}/>
        </TableCell>
        <TableCell>
            <Box display={'flex'} flexDirection={'row'}>
                <Tooltip title={'Comments'}>
                    <IconButton onClick={() => openComments(index)}>
                        <Badge color="primary" badgeContent={message.comments.length}>
                            <ChatIcon color={"secondary"}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'History'}>
                    <IconButton onClick={() => openLogs(index)}>
                        <HistoryIcon color={"secondary"}/>
                    </IconButton>
                </Tooltip>
            </Box>
        </TableCell>
    </TableRow>
}