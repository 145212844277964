import React, { useState, useEffect } from "react";
import {useHistory, useLocation} from "react-router-dom";
import {
    Container,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Formik, Form, Field, useFormikContext} from "formik";
import {PROGRAM_TYPES} from "../../utils/META/cat_106";
import {PROGRAM_TARGETS} from "../../utils/META/cat_108";
import {TARGET_AGE} from "../../utils/META/cat_109";
import {PROGRAM_VENUE} from "../../utils/META/cat_111";
import {PROGRAM_DURATION} from "../../utils/META/cat_112";
import {PROGRAM_STATUS} from "../../utils/META/cat_121";
import {toastSuccess} from "../../utils/utils";
import {updateAppProgramService} from "../../services/app.service";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {SEAL_TYPES} from "../../utils/META/cat_seal_types";
import {Autocomplete} from "@material-ui/lab";
import {REGIONS} from "../../utils/META/cat_101";
import {PROGRAM_COST} from "../../utils/META/cat_113";
import {RESOURCES_FORMAT} from "../../utils/META/cat_115";
import {RESOURCES_TYPE} from "../../utils/META/cat_114";
import {DEVICES} from "../../utils/META/cat_116";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
    section: {
        marginBottom: theme.spacing(4),
    },
}));

const FormTextField = ({ label, name, required = true, ...rest}) => (
    <Field
        as={TextField}
        label={label}
        name={name}
        fullWidth
        required={required}
        margin="normal"
        variant="outlined"
        {...rest}
    />
);

const FormSelect = ({ label, name, options }) => (
    <FormControl fullWidth variant="outlined" margin="normal" required>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Field as={Select} label={label} name={name}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Field>
    </FormControl>
);

const FormComponent = ({disableSubmit, setFieldValue}) => {

    const classes = useStyles();
    const {values} = useFormikContext(); // get current form values
    const targetAudienceOptions = Object.values(PROGRAM_TARGETS);
    const resourcesTypeOptions = Object.values(RESOURCES_TYPE);
    const resourcesFormatOptions = Object.values(RESOURCES_FORMAT);
    const countriesOptions = Object.values(REGIONS);
    const devicesOptions = Object.values(DEVICES);
    const programVenueOptions = Object.values(PROGRAM_VENUE);
    const [durationError, setDurationError] = useState(false);
    function parseInitialValues(initialValue, options) {
        return initialValue
            ? initialValue.split(', ').map(value => options.find(option => option.value === value)).filter(Boolean)
            : [];
    }

    const targetAudienceInitialValues = parseInitialValues(values.ProgramTarget, targetAudienceOptions);
    const resourcesTypeInitialValues = parseInitialValues(values.ResourcesType, resourcesTypeOptions);
    const resourcesFormatInitialValues = parseInitialValues(values.ResourcesFormat, resourcesFormatOptions);
    const countriesInitialValues = parseInitialValues(values.TargetCountry, countriesOptions);
    const devicesInitialValues = parseInitialValues(values.Devices, devicesOptions);
    const venueInitialValues = parseInitialValues(values.ProgramVenue, programVenueOptions);

    const handleAutocompleteChange = (fieldName) => (event, value) => {
        const stringValue = value.map(item => item.value).join(', ');
        setFieldValue(fieldName, stringValue);
    };

    const handleValueCheck = (value) => {
        if (value && parseInt(value) > 20) {
            setDurationError(false);
        } else {
            setDurationError(true);
        }
    };
    return (
        <Form>
            <div className={classes.section}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Program Description
                </Typography>
                <FormTextField label="Title of program" name="ProgramName" />
                <FormSelect
                    label="Powered by DQ Package"
                    name="DQSealType"
                    options={SEAL_TYPES}
                />
                {/*<FormSelect*/}
                {/*    label="Type of program"*/}
                {/*    name="ProgramType"*/}
                {/*    options={PROGRAM_TYPES}*/}
                {/*/>*/}
                {/*{values.ProgramType === 'Others' && (*/}
                {/*    <FormTextField*/}
                {/*        label="Please specify your program type."*/}
                {/*        name="ProgramTypeOther"*/}
                {/*    />*/}
                {/*)}*/}
                <FormTextField label="Objectives of program" name="ProgramAims" />
                <Box mt={2} mb={2}>
                    <Autocomplete
                        sx={{ marginTop: 0}}
                        multiple
                        fullWidth={true}
                        name="ResourcesType"
                        options={Object.values(RESOURCES_TYPE)}
                        value={resourcesTypeInitialValues}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        onChange={handleAutocompleteChange("ResourcesType")}
                        disableCloseOnSelect
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth={true}
                                variant="outlined"
                                label="Type of resources your program has (Select all that apply)"
                                InputLabelProps={ { required: true }}
                            />
                        )}
                    />
                    {values.ResourcesType && (values.ResourcesType.includes('Others') || (values.ResourcesType.length > 1 && values.ResourcesType.includes('Others'))) ? (
                        <FormTextField
                            style={{marginTop:'25px'}}
                            label="Please specify your type of resources."
                            name="ResourcesTypeOther"
                        />
                    ) : null}
                </Box>
                <Box mt={3} mb={2}>
                    <Autocomplete
                        sx={{ marginTop: 2,}}
                        multiple
                        fullWidth={true}
                        name="ResourcesFormat"
                        options={Object.values(RESOURCES_FORMAT)}
                        value={resourcesFormatInitialValues}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        onChange={handleAutocompleteChange("ResourcesFormat")}
                        disableCloseOnSelect
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth={true}
                                variant="outlined"
                                label="Format of resources your program has (Select all that apply)"
                                InputLabelProps={ { required: true }}
                            />
                        )}
                    />
                    {values.ResourcesFormat && (values.ResourcesFormat.includes('Others') || (values.ResourcesFormat.length > 1 && values.ResourcesFormat.includes('Others'))) ? (
                        <FormTextField
                            style={{marginTop:'25px'}}
                            label="Please specify your format of resources."
                            name="ResourcesFormatOther"
                        />
                    ) : null}
                </Box>
                <Box mt={3} mb={2}>
                <Autocomplete
                    // sx={{ marginTop: 2, marginBottom: 2 }}
                    multiple
                    fullWidth={true}
                    name="ProgramTarget"
                    options={Object.values(PROGRAM_TARGETS)}
                    value={targetAudienceInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={handleAutocompleteChange("ProgramTarget")}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Target audience of program (Select all that apply)"
                            InputLabelProps={ { required: true }}
                        />
                    )}
                />
                    {
                        (values.ProgramTarget && (values.ProgramTarget.includes('Teens') || values.ProgramTarget.includes('Youth') || values.ProgramTarget.includes('EarlyYears') || values.ProgramTarget.includes('Children'))) ? (
                            <>
                                <FormTextField
                                    style={{marginTop:'25px'}}
                                    label="Minimum Age"
                                    type="number"
                                    name="ProgramTargetMinAge"
                                />
                                <FormTextField
                                    label="Maximum Age"
                                    type="number"
                                    name="ProgramTargetMaxAge"
                                />
                            </>
                        ) : null}
                    {values.ProgramTarget && (values.ProgramTarget.includes('Others') || (values.ProgramTarget.length > 1 && values.ProgramTarget.includes('Others'))) ? (
                        <FormTextField
                            style={{marginTop:'25px'}}
                            label="Please specify target audience."
                            name="ProgramTargetOther"
                        />
                    ) : null}
                </Box>
                {/*{*/}
                {/*    values.ProgramTarget === 'Students' && (*/}
                {/*        <FormSelect*/}
                {/*            label="Age group of students"*/}
                {/*            name="TargetAge"*/}
                {/*            options={TARGET_AGE}*/}
                {/*        />*/}
                {/*    )*/}
                {/*}*/}
                <Box mt={3} mb={2}>
                <Autocomplete
                    multiple
                    fullWidth={true}
                    name="TargetCountry"
                    options={Object.values(REGIONS)}
                    value={countriesInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={handleAutocompleteChange("TargetCountry")}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Target countries of program (Select all that apply)"
                            InputLabelProps={ { required: true }}
                            // placeholder="Multiple Autocomplete"
                        />
                    )}
                />
                </Box>
                <Box mt={3} mb={2}>
                <Autocomplete
                    sx={{ marginTop: 4}}
                    multiple
                    fullWidth={true}
                    name="Devices"
                    options={Object.values(DEVICES)}
                    value={devicesInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={(event, newValue) => {
                        // If "Any device" is selected, clear the selection of other options
                        if (newValue.some(option => option.value === "AnyDevice")) {
                            newValue = newValue.filter(option => option.value === "AnyDevice");
                        }
                        handleAutocompleteChange("Devices")(event, newValue);
                    }}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Device(s) needed by your target audience to use the resources (Select all that apply)"
                            InputLabelProps={ { required: true }}
                            // placeholder="Multiple Autocomplete"
                        />
                    )}
                />

                {values.Devices && (values.Devices.includes('Others') || (values.Devices.length > 1 && values.Devices.includes('Others'))) ? (
                    <FormTextField
                        style={{marginTop:'25px'}}
                        label="Please specify device(s) needed."
                        name="DeviceTypeOther"
                    />
                ) : null}
                </Box>
                {/*<FormTextField*/}
                {/*    label="Description of program"*/}
                {/*    name="ProgramDescription"*/}
                {/*    multiline={true}*/}
                {/*    helperText={<Box display={'flex'} flexDirection={'row'} justifyContent={'end'}>*/}
                {/*        {`${values.ProgramDescription ? values.ProgramDescription.match(/\w+/g).length : 0}/500`}*/}
                {/*    </Box>}*/}
                {/*/>*/}
                {/*<FormTextField label="Please elaborate how the program is delivered" name="ProgramDelivery" />*/}
                <Box mt={3} mb={2}>
                <Autocomplete
                    multiple
                    fullWidth={true}
                    name="ProgramVenue"
                    options={Object.values(PROGRAM_VENUE)}
                    value={venueInitialValues}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={handleAutocompleteChange("ProgramVenue")}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth={true}
                            variant="outlined"
                            label="Venue(s) where program is conducted (Select all that apply)"
                            InputLabelProps={ { required: true }}
                            // placeholder="Multiple Autocomplete"
                        />
                    )}
                />
                    {values.ProgramVenue && (values.ProgramVenue.includes('Others') || (values.ProgramVenue.length > 1 && values.ProgramVenue.includes('Others'))) ? (
                        <FormTextField
                            style={{marginTop:'25px'}}
                            label="Please specify venue."
                            name="ProgramVenueOther"
                        />
                    ) : null}
                </Box>
                <Box mt={3} mb={2}>
                    <FormSelect
                        label="Duration to complete the program"
                        name="ProgramDuration"
                        options={PROGRAM_DURATION}
                    />
                    {values.ProgramDuration === 'morethan20hours' && (
                        <FormTextField
                            label="Please specify program duration (in hours)."
                            type="number"
                            name="OtherDurationHours"
                            onBlur={(e) => handleValueCheck(e.target.value)}
                            error={durationError}
                            helperText={durationError && `Number must be greater than 20`}
                        />
                    )}
                </Box>

                {/*<FormTextField*/}
                {/*    label="Duration of program (in hours)"*/}
                {/*    name="ProgramDuration"*/}
                {/*    type="number"*/}
                {/*/>*/}
                {/*<FormTextField*/}
                {/*    label="Resources required for program to be carried out"*/}
                {/*    name="RefResource"*/}
                {/*/>*/}
                <FormTextField label="Year of program launch" name="StartYear" type="number"/>
                <FormSelect
                    label="Current status of program?"
                    name="Status"
                    options={PROGRAM_STATUS}
                />
                <FormTextField label="Link to program" name="Links" />
                <FormSelect
                    label="Cost of program"
                    name="ProgramPrice"
                    options={PROGRAM_COST}
                />
            </div>
            <div className={classes.section}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Program Impact
                </Typography>
                <FormTextField
                    label="Definition of success for program"
                    name="Success"
                />
                <FormTextField
                    label="Measurement of success for program (e.g. reach target, social impact target)"
                    name="SuccessMeasure"
                />
                <FormTextField
                    label="Process to meet the program’s objectives"
                    name="KPITrack"
                />
                {/*<FormTextField*/}
                {/*    label="If you have a weblink that best describes your program, please provide:"*/}
                {/*    name="RefLink"*/}
                {/*    required={false}*/}
                {/*/>*/}
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="RefFramework">Is your program already aligned with existing framework?</InputLabel>
                    <Field
                        as={Select}
                        id="RefFramework"
                        value={values.RefFramework}
                        label="Is your program already aligned with existing framework?"
                        name="RefFramework"
                    >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </Field>
                </FormControl>
                {values.RefFramework === 'yes' && (
                    <FormTextField
                        label="Which framework(s) does your program align with?"
                        name="RefAlignment"
                        required={false}
                    />
                )}
            </div>
            <Button type="submit" variant="contained" color="primary" disabled={disableSubmit}>
                Update
            </Button>
        </Form>
    );

};

const EditProgramApplication = (props) => {

    const history = useHistory();
    const {state = {}, search} = useLocation();
    const {existingValues, application_id} = state;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        setDisableSubmit(true);
        try {
            const result = await updateAppProgramService(application_id, values);
            console.log('result.data -> ', result.data);
            toastSuccess("Program Updated", "Successfully updated the program information")
            history.push('/application/detail', {application_id});
        } catch (e) {
            // already toasted the error
        }
        setDisableSubmit(false);
        setLoading(false);
    };

    return (
        <Container className={classes.container}>
            <Formik initialValues={existingValues} onSubmit={handleSubmit}>
                {({ setFieldValue }) => (
                    <FormComponent disableSubmit={disableSubmit} setFieldValue={setFieldValue}/>
                )}
            </Formik>
            <LoaderWithBackDrop loading={loading}/>
        </Container>
    );
};

export default EditProgramApplication;
