import React, {useEffect, useState} from 'react';
import {TabContainer} from "../../../components";
import {getAppService, updateAppAmountService} from "../../../services/app.service";
import ProgramInformation from "../components/ProgInfo.comp";
import CompanyInformation from "../components/CompInfo.comp";
import {Button, InputAdornment} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {APP_STATUS} from "../../../utils/constants";
import {toastSuccess} from "../../../utils/utils";
import TextField from "@material-ui/core/TextField";

function ApplicationModule(props) {
    const {application_id, app_status, updateStatus, forwardStep, programInfo = {}, companyInfo = {}, setLoading} = props;

    const [paymentAmount, setPaymentAmount] = useState("");

    const statusGreaterThanModule = app_status > APP_STATUS.DRAFT;
    const statusGreaterThanPendingPayment = app_status > APP_STATUS.PENDING_PAYMENT;

    function handleAmountChange(event) {
        setPaymentAmount(event.target.value);
    }

    async function requestPayment() {
        try {
            setLoading(true);
            const result = await updateAppAmountService(application_id, parseFloat(paymentAmount));
            if(!result.error && result.data.success) {
                updateStatus(APP_STATUS.PENDING_PAYMENT);
                toastSuccess('Success', 'Payment Requested');
            }
            setLoading(false);
        }
        catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    const alphabetsRegex = /^\d+(\.\d{1,2})?$/;
    const isAmountValid = alphabetsRegex.test(paymentAmount) && parseFloat(paymentAmount) >= 1;

    useEffect(() => {
        retrieveApplication().then();
    }, [])

    async function retrieveApplication() {
        setLoading(true);
        try {
            const result = await getAppService(application_id);
            if(!result.error && result.data) {
                const app = result.data;
                const {amount} = app;
                setPaymentAmount(amount.toString());
            }
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false);
    }

    return <>
        <TabContainer>
        <CompanyInformation title={'Organization Information'} compInfo={companyInfo} application_id={application_id}/>
        <ProgramInformation title={'Program Information'} progInfo={programInfo} application_id={application_id}/>
        </TabContainer>
        <br/>
        {
            !(process.env.REACT_APP_SEAL_NO_PAY === '1') &&
            <>
                <Box mt={2} align={'center'}>
                    <TextField id='assumption1' variant="outlined" label={'Payment Amount'} value={paymentAmount}
                               onChange={handleAmountChange}
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                   readOnly: statusGreaterThanModule
                               }}
                               error={!isAmountValid}
                               helperText={!isAmountValid ? "Amount Invalid (Minimum is 1)" : ""}
                    />
                </Box>
                <Box mt={2} align={'center'} display={'flex'} justifyContent={"center"} gridColumnGap={10}>
                    <Button variant='contained' color="primary" disabled={statusGreaterThanModule || !isAmountValid} onClick={() => requestPayment()}>Request Payment</Button>
                    {/*<Button variant='contained' color="secondary" disabled={!statusGreaterThanPendingPayment} onClick={() => forwardStep()}>Next</Button>*/}
                </Box>
            </>
        }
    </>
}

export default ApplicationModule;