import React, {useEffect} from "react";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Tooltip} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    dropdown: {
        minWidth: '150px'
    }
}))

export default function SearchableDropdown(props) {

    const classes = useStyles();

    const {defaultValue, onChange, selected, setSelected, options, formatOption, tooltip, optionsTooltips, ...rest} = props;

    useEffect(() => {
        //clear selected when options change
        if(!options.includes(selected)) {
            if(selected !== defaultValue) {
                setSelected(null);
            }
        }
    }, [options])

    return <Autocomplete
        className={classes.dropdown}
        fullWidth={true}
        value={selected}
        onChange={(event, newValue) => {
            setSelected(newValue);
            if(onChange) onChange(newValue);
        }}
        options={options}
        renderOption={(option) => <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'} gridColumnGap={5}>
            {
                optionsTooltips && optionsTooltips[option] &&
                <Tooltip title={optionsTooltips[option]} placement={"top"}>
                    <InfoIcon fontSize={'small'}/>
                </Tooltip>
            }
            {formatOption ? formatOption(option) : option}
        </Box>}
        autoComplete
        includeInputInList
        renderInput={(params) =>
            <Tooltip enterDelay={500} leaveDelay={0} placement={"bottom"} title={tooltip || ""}>
                <TextField
                    fullWidth={true}
                    {...params}
                    variant="outlined" size="small"
                    error={selected === null || !options.includes(selected)}
                    helperText={!selected ? 'Must be selected' : !options.includes(selected) ? 'Invalid selection' : undefined}
                />
            </Tooltip>
        }
        {...rest}
    />
}