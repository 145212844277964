import React, {useEffect, useReducer, useRef, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Card, CardContent, Step, StepIcon, StepLabel, Stepper, TableContainer, Link} from "@material-ui/core";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import EditIcon from '@material-ui/icons/Edit';
import CodeIcon from '@material-ui/icons/Code';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PrintIcon from '@material-ui/icons/Print';
import ApplicationModule from "./modules/Application.module";
import CollectionModule from "./modules/Collection.module";
import CleaningModule from "./modules/Cleaning.module";
import CodificationModule from "./modules/Codification.module";
import AnalysisModule from "./modules/Analysis.module";
import ReportModule from "./modules/Report.module";
import {APP_STATUS, APP_STATUS_VERBOSE, SEAL_TYPE_VERBOSE, USER_TYPE} from "../../utils/constants";
import {getAppService, updateAppStatusService} from "../../services/app.service";
import PaymentVerificationModal from './components/PaymentVerificationModal';
import {useAuth} from "../../context/auth";
import {useAppDetails} from "./hooks/useAppDetails.hook";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";


const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
}));

const iconStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        borderRadius: "50%",
        padding: 8,
        marginTop: -10,
        borderStyle: "solid",
        borderWidth: "medium",
        borderColor: "grey",
        zIndex: 1
    },
    active_container: {
        borderColor: "limegreen"
    },
    completed_container: {
        backgroundColor: theme.palette.primary.main,
    },
    incomplete_container: {
        backgroundColor: "grey",
    },
    icon: {
        color: "white"
    }
}))


const STEPS = [
    {
        label: 'Application',
        icon: InsertDriveFileIcon,
        component: ApplicationModule,
        maximum_status: APP_STATUS.PENDING_APPROVAL
    },
    {
        label: 'Collection',
        icon: FilterNoneIcon,
        component: CollectionModule,
        maximum_status: APP_STATUS.HALTED_PROCESS_DATA
    },
    {
        label: 'Cleaning',
        icon: EditIcon,
        component: CleaningModule,
        maximum_status: APP_STATUS.IN_PROGRESS_LEVEL_MAPPING,
    },
    {
        label: 'Codification',
        icon: CodeIcon,
        component: CodificationModule,
        maximum_status: APP_STATUS.PENDING_REVIEW_MAPPING,
    },
    {
        label: 'Analysis',
        icon: HourglassEmptyIcon,
        component: AnalysisModule,
        maximum_status: APP_STATUS.PENDING_ANALYSIS
    },
    {
        label: 'Report',
        icon: PrintIcon,
        component: ReportModule,
        maximum_status: APP_STATUS.HALTED_REPORT
    }
];

function IconComponent(props) {

    const classes = iconStyles();

    const {active, completed, icon: Icon} = props;

    const icon_container_class = active ? `${classes.active_container} ${classes.completed_container}` :
        completed ? classes.completed_container : classes.incomplete_container

    return <div className={`${classes.container} ${icon_container_class}`}>
        <Icon className={classes.icon}/>
    </div>
}

function statusToStepConversion(status) {
    for(const step_index in STEPS) {
        if(status <= STEPS[step_index].maximum_status) return parseInt(step_index);
    }
    return STEPS.length - 1;
}

function loaderReducer(state, action = false) {
    if(action) {
        return state + 1;
    } else {
        return state - 1;
    }
}

function Details(props) {
    const classes = useStyles();
    const history = useHistory();
    const {state = {}, search} = useLocation();
    const [loading, setLoading] = useReducer(loaderReducer, 0, (initValue) => initValue);
    const [app, setApp] = useState(state);
    const {application_id = new URLSearchParams(search).get('app_id'), app_status = APP_STATUS.DRAFT, dqseal_type} = app;
    const [status, setStatus] = useState(app_status);
    const [openProofModal, setOpenProofModal] = useState(false);
    const [activeStep, setActiveStep] = useState(statusToStepConversion(status));
    const [selectedStep, setSelectedStep] = useState(activeStep);
    const ActiveComponent = STEPS[selectedStep].component;
    const paymentProofLink = useRef(undefined);

    const { authUser } = useAuth();
    const {programInfo = {}, companyInfo = {}} = useAppDetails(application_id, setLoading);

    useEffect(() => {
        if(application_id) {
            retrieveApplication(application_id).then();
        } else {
            returnToMainMenu();
        }
    }, [])

    function returnToMainMenu() {
        history.push('/application/list');
    }

    const updateApp = (updatedData) => {
        setApp(prevApp => ({ ...prevApp, ...updatedData }));
    };

    function updateStepByStatus(status, navigate = false) {
        setStatus(status);
        const step = statusToStepConversion(status)
        setActiveStep(step);
        if(navigate) setSelectedStep(step);
    }

    const handleProofModalOpen = () => {
        setOpenProofModal(true);
    }

    const handleProofModalClose = () => setOpenProofModal(false);

    async function retrieveApplication(application_id) {
        setLoading(true);
        try {
            console.log('application_id: ', application_id)
            const result = await getAppService(application_id);
            console.log('result: ', result)
            if(!result.error && result.data) {
                const app = result.data;
                setApp(app);
                const {app_status} =  app;
                updateStepByStatus(app_status, true);
                paymentProofLink.current = app.payment_proof_link;
            } else {
                returnToMainMenu();
            }
        }
        catch (e) {
            console.error(e)
        }
        setLoading(false);
    }

    async function fetchStatus(appID, fallback) {
        let status = fallback;
        try {
            const result = await getAppService(appID);
            if(result.data) {
                status = result.data.app_status;
            }
        }
        catch (e) {
            console.error(e);
        }
        return status;
    }

    async function updateStatus(updatedStatus, navigate = false, safeUpdate = false) {
        try {
            const currentStatus = safeUpdate ? await fetchStatus(application_id, status) : status;
            if(!safeUpdate || (safeUpdate && currentStatus < updatedStatus)) {
                const result = await updateAppStatusService(application_id, updatedStatus);
                if(!result.error && result.data.success) {
                    updateStepByStatus(updatedStatus, navigate);
                }
            }
        }
        catch (e) {
            //already toasted
        }
    }

    function forwardStep() {
        const nextStep = selectedStep + 1;
        if(nextStep <= activeStep) setSelectedStep(nextStep);
    }

    const applicationProps = {app, application_id, app_status: status, programInfo, companyInfo, loading, setLoading, updateApp};
    const title = `${(programInfo.ProgramName || "")} ${companyInfo.NameOrg ? `(${companyInfo.NameOrg})` : ""}`

    return (
        <Container maxWidth={false}>
            <Card>
                <CardContent>
                    <Typography className={classes.title} variant={'h5'}>
                        {title}
                    </Typography>
                    <Typography  color="textSecondary">
                        {application_id} | {SEAL_TYPE_VERBOSE[dqseal_type]} | {
                                        (status === APP_STATUS.PENDING_APPROVAL && authUser.user_type === USER_TYPE.SUPER_USER) ?
                                            <Link component="button" variant="body2" onClick={()=>handleProofModalOpen()}
                                                  className={classes.link}>
                                                { APP_STATUS_VERBOSE[status]}
                                            </Link>
                                            : APP_STATUS_VERBOSE[status]
                                    }
                    </Typography>
                </CardContent>
            </Card>
            <br/>
            <Stepper alternativeLabel activeStep={activeStep}>
                {
                    STEPS.map((step, i) => (
                        <Step
                            key={step.label}
                            onClick={() => (i <= activeStep ? setSelectedStep(i) : false)}
                            style={{cursor: (i <= activeStep ? "pointer" : "default")}}
                        >
                            <StepLabel StepIconComponent={IconComponent} StepIconProps={{icon: step.icon, active: selectedStep === i, completed: i <= activeStep}}>{step.label}</StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
            <br/>
            {
               <ActiveComponent updateStatus={updateStatus} updateStep={updateStepByStatus} forwardStep={forwardStep} {...applicationProps}/>
            }
            <PaymentVerificationModal
                open={openProofModal && paymentProofLink.current}
                onClose={handleProofModalClose}
                paymentProofLink={paymentProofLink.current}
                acceptAction={()=>{
                    updateStatus(APP_STATUS.IN_PROGRESS_COLLECT_DATA, false, true);
                    handleProofModalClose();
                }}
                rejectAction={()=>{
                    updateStatus(APP_STATUS.REJECTED);
                    handleProofModalClose();
                }}
            />
            <LoaderWithBackDrop loading={loading > 0}/>
        </Container>
    );
}

export default Details;