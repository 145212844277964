import React, {useEffect, useState, useRef} from 'react';
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Modal,
    Box, TablePagination, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {SEAL_TYPE_VERBOSE, APP_STATUS_VERBOSE, APP_STATUS, USER_TYPE} from "../../utils/constants";
import {getAppsService, getAppService, updateAppStatusService, getAppDetailService} from "../../services/app.service";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PaymentVerificationModal from '../Applications/components/PaymentVerificationModal';
import {useAuth} from "../../context/auth";
import {useHistory} from "react-router-dom";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
}));

const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    switch(field) {
        case "submission_time":
            return (new Date(value)).toLocaleDateString(undefined, date_options);
        case "dqseal_type":
            return SEAL_TYPE_VERBOSE[value];
        case "app_status":
            return APP_STATUS_VERBOSE[value];
        default:
            return value;
    }
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const ListPayments = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [appData, setAppData] = useState([]);
    const applicationId = useRef(undefined);
    const [openProofModal, setOpenProofModal] = useState(false);
    const paymentProofLink = useRef(undefined);
    const { authUser } = useAuth();

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    function setAppDataEx(data) {
        setAppData(data);
        setSearchText(searchText);
    }

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }
    const filteredRows = appData.filter(field_filter_function).filter((row) => {
        const paymentProofLink = row['payment_proof_link'];
        return paymentProofLink !== null && paymentProofLink !== undefined;
      });

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleProofModalOpen = (row) => {
        paymentProofLink.current = row.payment_proof_link;
        applicationId.current = row.application_id;
        setOpenProofModal(true);
    }
    const history = useHistory();

    const handleProofModalClose = () => setOpenProofModal(false);

    const updateAppData = (appId, newStatus) => {
        const newData = appData.map(item => item.application_id === appId ? { ...item, app_status: newStatus } : item)
        setAppDataEx(newData);
    };

    async function fetchApplications() {
        try {
            setLoading(true);
            const result_apps = await getAppsService();
            if (!result_apps.error) {
                const applications = result_apps.data;
                setAppDataEx(applications.filter(Boolean)); // Filter out any undefined values
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            // Handle the error
        }
    }

    useEffect(() => {
        fetchApplications().then((res) => `fetch initiated, ${res}`);
    }, [])

    async function fetchStatus(appID, fallback) {
        let status = fallback;
        try {
            const result = await getAppService(appID);
            if(result.data) {
                status = result.data.app_status;
            }
        }
        catch (e) {
            console.error(e);
        }
        return status;
    }

    async function updateStatus(status, safeUpdate = false) {
        try {
            if (applicationId.current) {
                let {status: currentStatus} = appData.find((app) => app.application_id === applicationId.current);
                if(safeUpdate) currentStatus = await fetchStatus(applicationId.current, currentStatus);
                if(!safeUpdate || (safeUpdate && currentStatus < status)) {
                    const result = await updateAppStatusService(applicationId.current, status);
                    if(!result.error && result.data.success) {
                        updateAppData(applicationId.current, status);
                    }
                }
            }
        }
        catch (e) {
            //already toasted
        }
    }

    const viewApplication = (e, row) => {
        history.push({pathname: '/application/detail', state: row})
    }

    return (
        <Container maxWidth={false}>
            <Box display={'flex'} justifyContent={'flex-end'} mb={2}>
                <TextField
                    variant="outlined"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search…"
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                onClick={() => setSearchText('')}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Program</TableCell>
                            <TableCell>Submission Time</TableCell>
                            <TableCell>Powered by DQ Package</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Payment Receipt</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {

                            filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) =>
                                <TableRow>
                                    <TableCell>
                                        <Typography>{'O: '}{row.owner_id.slice(-16)}</Typography>
                                        <Typography>{'A: '}{row.application_id.slice(-16)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{row.program_info ? row.program_info.program_name : "-"}</Typography>
                                        <Typography>{`(${row.company_info ? row.company_info.name_org : "-"})`}</Typography>
                                    </TableCell>
                                    <TableCell>{(new Date(row.submission_time)).toLocaleDateString(undefined, date_options)}</TableCell>
                                    <TableCell>{SEAL_TYPE_VERBOSE[row.dqseal_type]}</TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>
                                        {APP_STATUS_VERBOSE[row.app_status] === 'Rejected' ? 'Rejected' :
                                        APP_STATUS_VERBOSE[row.app_status] === 'Pending Approval' ? 'Awaiting Approval' :
                                        'Approved'}
                                    </TableCell>
                                    <TableCell>
                                    {
                                        (row.app_status === APP_STATUS.PENDING_APPROVAL && authUser.user_type === USER_TYPE.SUPER_USER) ?
                                            <Button variant="contained" onClick={() => handleProofModalOpen(row)}>View</Button>
                                            : <Button variant="contained" onClick={() => window.open(row.payment_proof_link, '_blank')}>View</Button>
                                    }
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" onClick={(e) =>viewApplication(e, row)}>View</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <PaymentVerificationModal
                open={openProofModal && paymentProofLink.current}
                onClose={handleProofModalClose}
                paymentProofLink={paymentProofLink.current}
                acceptAction={()=>{
                    updateStatus(APP_STATUS.IN_PROGRESS_COLLECT_DATA, true);
                    handleProofModalClose();
                }}
                rejectAction={()=>{
                    updateStatus(APP_STATUS.REJECTED);
                    handleProofModalClose();
                }}
            />

            <LoaderWithBackDrop loading={loading} />
        </Container>
    );
};

export default ListPayments;
