export function extractPossibleLevel5(mappings) {
    const {level5_mappings = {}} = mappings;
    return Object.keys(level5_mappings);
}

export function extractPossibleLevel6(mappings) {
    const {level6_mappings = {}} = mappings;
    return Object.keys(level6_mappings);
}

export function extractPossibleLevel8(mappings) {
    const {level8_mappings = {}} = mappings;
    return Object.keys(level8_mappings);
}

export function extractPossibleLevel9(mappings) {
    const {level8_mappings = {}} = mappings;
    const flatted = [].concat(...Object.values(level8_mappings));
    const set = new Set(flatted)
    return [...set];
}

export function Level5FilterMapping(mappings, level5) {
    const {level5_mappings, level6_mappings, level8_mappings} = mappings;
    const filtered_level6 = Object.keys(level5_mappings[level5]);
    const filtered_level8 = [].concat(...filtered_level6.map((level6) => Object.keys(level6_mappings[level6])));
    const filtered_level9 = [].concat(...filtered_level8.map((level8) => level8_mappings[level8]))
    return {level6: filtered_level6, level8: filtered_level8, level9: filtered_level9};
}

export function Level6FilterMapping(mappings, level6) {
    const {level6_mappings, level8_mappings} = mappings;
    const filtered_level8 = Object.keys(level6_mappings[level6]);
    const filtered_level9 = [].concat(...filtered_level8.map((level8) => level8_mappings[level8]))
    return {level8: filtered_level8, level9: filtered_level9};
}

export function Level8FilterMapping(mappings, level8) {
    const {level8_mappings} = mappings;
    const filtered_level9 = level8_mappings[level8]
    return {level9: filtered_level9};
}

export function Level6BackwardMapping(mappings, level6) {
    const result = {}
    const {level5_mappings} = mappings;
    for(const level5 of Object.keys(level5_mappings)) {
        if(level5_mappings[level5][level6]) {
            result.level5 = level5;
            break;
        }
    }
    return result;
}

export function Level8BackwardMapping(mappings, level8) {
    const result = {}
    const {level6_mappings} = mappings;
    //finding level6
    for(const level6 of Object.keys(level6_mappings)) {
        if(level6_mappings[level6][level8]) {
            result.level6 = level6;
            break;
        }
    }
    //finding level5
    const {level5} = Level6BackwardMapping(mappings, result.level6);
    result.level5 = level5;
    return result;
}

export function Level9BackwardMapping(mappings, level9) {
    const result = {}
    const {level8_mappings} = mappings;
    //finding level8
    for(const level8 of Object.keys(level8_mappings)) {
        if(level8_mappings[level8].includes(level9)) {
            result.level8 = level8;
            break;
        }
    }
    //finding level6
    const {level6} = Level8BackwardMapping(mappings, result.level8);
    result.level6 = level6;
    //finding level5
    const {level5} = Level6BackwardMapping(mappings, result.level6);
    result.level5 = level5;
    return result;
}

export function Level9ForwardMapping(mappings, level9) {
    const {level9_mappings = {}} = mappings;
    return level9_mappings[level9] ? level9_mappings[level9] : {};
}

