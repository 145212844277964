import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import ReportPage from "./PageContainer.report.comp";
import {makeStyles} from "@material-ui/core/styles";
import {asciiSortCompareFunWithKey, getMapFromObjectListByKey} from "../../../../utils/utils";
// import {DQ_DEFINITIONS} from "../../../../utils/constants";

const tableStyles = makeStyles((theme) => ({
    root: {
        borderCollapse: "collapse",
        tableLayout: 'fixed',
        width: '100vw',
        "& th,td": {
            border: "1px solid black",
            padding: "5px 10px",
            fontSize: "18px"
        },
        "& th": {
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
            textAlign: "center",
            backgroundColor: "deepskyblue",
            color: "white",
        },
        '& th:first-child, & td:first-child': {
            textAlign: 'left',
        },
        "& th:nth-child(1), & td:nth-child(1)": {
            width: "50%"
        },
        "& th:nth-child(2), & td:nth-child(2)": {
            width: "15%"
        },
        "& th:nth-child(3), & td:nth-child(3)": {
            width: "15%"
        },
        "& th:nth-child(4), & td:nth-child(4)": {
            width: "20%"
        },
        // "& th:nth-child(5)": {
        //     width: "15%"
        // }
    },
}));

// const badges = [
//     {code: 'DQ1', title: 'Digital Citizen Identity', mb: 1, count: 6, aligned: false},
//     {code: 'DQ2', title: 'Balanced Digital Use', mb: 1, count: 9, aligned: false},
//     {code: 'DQ4', title: 'Personal Cyber Security Management', mb: 2, count: 15, aligned: true},
//     {code: 'DQ5', title: 'Digital Empathy', mb: 2, count: 9, aligned: true},
//     {code: 'DQ7', title: 'Media and Information Literacy', mb: 1, count: 21, aligned: true},
//     {code: 'DQ8', title: 'Privacy Management', mb: 1, count: 7, aligned: false},
//     {code: 'DQ25', title: 'Digital User Identity', mb: 1, count: 22, aligned: true}
// ]

function BadgeRow(props) {
    const {badge} = props;

    const badge_style = {fontWeight: "normal", fontFamily: 'Source Sans Pro', fontSize: '18px',}
    const aligned_badge_style = {backgroundColor: "#dcf404", fontWeight: "normal", color: "black", fontFamily: 'Source Sans Pro', fontSize: '18px',}
    const center_text_style = {textAlign: "center", fontFamily: 'Source Sans Pro', fontSize: '18px',}

    const style_to_use = badge.aligned ? aligned_badge_style : badge_style;
    return <>
        <tr>
            <td style={style_to_use}>{badge.title}</td>
            <td style={{...style_to_use, ...center_text_style}}>{badge.mb}</td>
            <td style={{...style_to_use, ...center_text_style}}>{badge.count}</td>
            <td style={{...style_to_use, ...center_text_style}}>
                {badge.aligned !== undefined && badge.aligned !== null && badge.aligned !== '' ? (badge.aligned ? "Yes" : "No") : ""}
            </td>
        </tr>
    </>
}

export default function Page11(props) {

    const {DQ_DEFINITIONS, pageNumber, breadth, coverageMap, onlyTable = false, isLastPage} = props;

    // function getDQ(dqc_no) {
    //     const dq_num = parseInt(dqc_no.replace("DQC", ""), 10);
    //     const code = isNaN(dq_num) ? dqc_no : `DQ${dq_num}`
    //     const title = DQ_DEFINITIONS[code] ? DQ_DEFINITIONS[code].title : ""
    //     return {code, title};
    // }

    const classes = tableStyles();

    return <ReportPage pageNumber={pageNumber}>
        <Box id="2.3-analysis-iii:-breadth-of-learning-messages-meeting-dq-competency-standards" display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} height={"inherit"} width={"100%"} px={10}>
            { !onlyTable &&
                <Box>
            <Typography
                variant={'h4'}
                style={{
                    color: "orangered",
                    fontWeight: "lighter",
                    fontFamily: 'Raleway',
                    paddingTop: '70px',
                    fontSize: '22px'
                }}
            >
                2.3	Analysis III: Breadth of learning messages meeting DQ Competency Standards
            </Typography>
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '30px',
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
            >
                This section analyses the program’s alignment with the breadth of learning messages meeting DQ Competency Standard.
                The breadth of learning messages refers to the extent of content coverage of the DQ GSMs within each DQ Competency.
                This analysis evaluates the overall breadth of topic coverage with your program, in addition to the GSMs earned from
                the previous depth analysis. This helps you identify the digital competency that best aligns with your program’s learning
                objectives, enabling your organization to position the program more effectively within the DQ framework. This strategic
                positioning empowers your program to capitalize on the universally recognized structure and allows you to systematically
                promote your program.
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center" paddingTop={'30px'} >
                <Box style={{
                    backgroundColor: '#2396f3',
                    borderRadius: '25px',
                    padding: '16px 32px',
                    maxWidth: '95%',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                    <Typography variant="caption" style={{
                        color: 'white',
                        fontFamily: 'Source Sans Pro',
                        fontWeight: 'bold',
                        fontSize: '18px'
                    }}>
                        Breadth of learning messages refers to the extent of content coverage of the DQ GSMs within each DQ Competency.
                    </Typography>
                </Box>
            </Box>
            <Typography
                style={{
                    color: "black",
                    fontWeight: "lighter",
                    fontFamily: 'Source Sans Pro',
                    paddingTop: '30px',
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
            >
                The table below demonstrates the alignment of identified DQ competencies. The program content has sufficient
                depth and breadth to be aligned to the DQ competencies highlighted in green. The remaining DQ competencies
                not highlighted indicate areas covered in your program but did not have sufficient breadth for your program
                to be aligned to these competencies.
            </Typography>
                </Box>
            }
                <Box alignSelf={onlyTable ? "flex-start" : "inherit"} paddingTop={onlyTable ? "70px" : "30px"}>
                    <Box width="100%">
                        <table className={classes.root} style={{width: "100%"}}>
                            { !onlyTable &&
                            <tr>
                                <th>DQ Competency</th>
                                <th># of Aligned GSMs</th>
                                <th># of Learning Messages</th>
                                <th>Aligned (to DQ Competency)</th>
                            </tr>
                            }
                            {
                                breadth &&
                                breadth.map((b) => <BadgeRow badge={{
                                    title: b.title,
                                    mb: b.mb,
                                    count: b.count,
                                    aligned: b.aligned
                                }}/>)
                            }
                        </table>
                    </Box>
                    {isLastPage &&
                    <Typography
                        style={{
                            color: "black",
                            fontWeight: "lighter",
                            fontFamily: 'Source Sans Pro',
                            paddingTop: '15px',
                            fontSize: '18px',
                            textAlign: 'left'
                        }}
                    >
                        A description of each DQ Competency can be found in <a style={{ color: 'blue', textDecoration: 'underline' }} href={`#${'6.1 Appendix A: Description of DQ Competencies mentioned in Analysis I'.toLowerCase().replace(/ /g, '-')}`}>Appendix A</a>.
                    </Typography>
                    }
                </Box>
        </Box>
    </ReportPage>
}